import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import DocumentForm from './forms/DocumentForm';
import Loading from './Loading';

class EditDocument extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const { match } = this.props;
		const GET_DOCUMENT = gql(queries.getDocument);
		const UPDATE_DOCUMENT = gql(mutations.updateDocument);

		return (
			<Query query={GET_DOCUMENT} variables={{ id: match.params.documentId }} fetchPolicy="no-cache">
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<Mutation mutation={UPDATE_DOCUMENT}>
							{updateDocument => (
								<DocumentForm submit={updateDocument} document={data.getDocument} />
							)}
						</Mutation>
					);
				}}
			</Query>
		);
	}
}

export default EditDocument;

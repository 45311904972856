import React from 'react';
import ReactDOM from 'react-dom';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { Auth } from 'aws-amplify';
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider } from 'react-apollo';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';


import App from './src/App';
import awsmobile from './src/aws-exports';

const client = new AWSAppSyncClient({
	url: awsmobile.aws_appsync_graphqlEndpoint,
	region: awsmobile.aws_appsync_region,
	auth: {
		type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
		// apiKey: awsmobile.aws_appsync_apiKey,
		jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
	},
	disableOffline: true,
	cacheOptions: {
		fetchPolicy: 'no-cache',
	},
	complexObjectsCredentials: () => Auth.currentCredentials(),
});


ReactDOM.render(
	<ApolloProvider client={client}>
		<Rehydrated>
			<App />
		</Rehydrated>
	</ApolloProvider>,
	document.getElementById('root'),
);

module.hot.accept();

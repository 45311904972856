/* eslint max-len: 0 */
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import {
	Add,
	Edit,
} from '@material-ui/icons';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';

import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import Layout from './layout/Layout';
import Loading from './Loading';


class ListResources extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			resourceTypeId: '',
			resources: [],
		};

		this.onDragEnd = this.onDragEnd.bind(this);
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleExpire = this.handleExpire.bind(this);
	}

	componentDidMount() {
		const resourceTypeId = window.localStorage.getItem('resourceTypeId');

		if (resourceTypeId) {
			this.setState({
				resourceTypeId,
			});
		}
	}

	handleTypeChange = (e) => {
		const resourceTypeId = e.target.value;
		this.setState({
			resourceTypeId,
		});

		if (resourceTypeId) {
			window.localStorage.setItem('resourceTypeId', resourceTypeId);
		} else {
			window.localStorage.removeItem('resourceTypeId');
		}
	};

	onDragEnd = (result) => {
		// dropped outside the list
		if (
			!result.destination
      || result.destination.index === result.source.index
		) {
			return;
		}

		// no movement
		if (result.destination.index === result.source.index) {
			return;
		}

		this.setState(prevState => (
			{
				resources: this.reorder(prevState.resources, result.source.index, result.destination.index),
			}
		), () => {
			const { resources } = this.state;

			const promises = [];
			resources.forEach((resource, index) => {
				const input = {
					id: resource.id,
					order: index,
				};

				promises.push(this.props.client.mutate({
					mutation: gql(mutations.updateResource),
					variables: {
						input,
					},
				}));
			});

			Promise.all(promises);
		});
	};

	reorder = (resources, startIndex, endIndex) => {
		const result = Array.from(resources);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	handleExpire = (id) => {
		this.props.client.mutate({
			mutation: gql(`mutation expireResource($input: UpdateResourceInput!) {
				updateResource(input: $input) {
					id
				}
			}`),
			variables: {
				input: {
					id,
					runUntil: moment().format('YYYY-MM-DD'),
				},
			},
		}).then(() => {
			this.refetch();
		});
	}

	render() {
		const GET_RESOURCE_TYPES = gql(queries.metadataByType);
		const GET_RESOURCES = gql(queries.resourcesByType);

		return (
			<Layout title="Resources">
				<Query query={GET_RESOURCE_TYPES} fetchPolicy="no-cache" variables={{ limit: 100, type: 'RESOURCE_TYPE', filter: { active: { eq: true } } }}>
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);

						return (
							<Fragment>
								<div className="card mb-3">
									<div className="card-body">
										<label htmlFor="resourceType">Resource Type</label>
										<select name="resourceTypeId" id="resourceType" value={this.state.resourceTypeId} className="form-control" onChange={this.handleTypeChange}>
											<option value="">Select a Resource Type</option>
											{data.metadataByType.items.map(option => (
												<option key={option.id} value={option.id}>
													{option.description}
												</option>
											))}
										</select>
									</div>
								</div>
								{this.state.resourceTypeId !== '' && (
									<div className="card mb-3">
										<div className="card-body">
											<div className="mb-4 row">
												<div className="col-md-3">
													<div className="btn-group" role="group" aria-label="Basic example">
														<Link to={`resources/new?type=${this.state.resourceTypeId}`} className="btn btn-circle btn-primary mr-1">
															<Add />
														</Link>
													</div>
												</div>
											</div>
											<Query
												query={GET_RESOURCES}
												fetchPolicy="no-cache"
												variables={{ limit: 100, resourceResourceTypeId: this.state.resourceTypeId }}
												onCompleted={res => this.setState({ resources: res.resourcesByType.items })}
											>
												{({ loading: resLoading, error: resError, refetch }) => {
													if (resError) return (<h3>Error!</h3>);
													if (resLoading) return (<Loading />);

													this.refetch = refetch;
													const { resources } = this.state;
													const today = moment();
													const activeResources = resources.filter(r => !r.runUntil || moment(r.runUntil).isAfter(today));
													const expiredResources = resources.filter(r => r.runUntil && moment(r.runUntil).isBefore(today));

													return (
														<Fragment>
															{resources.length === 0 && (
																<p>
																	There are no resources to display. Please add a resource or select
																	a new resource category from the dropdown above.
																</p>
															)}
															{resources.length > 0 && (
																<Tabs variant="pills" defaultActiveKey="active" id="uncontrolled-tab-example">
																	<Tab eventKey="active" title="Active">
																		<DragDropContext onDragEnd={this.onDragEnd}>
																			<Fragment>
																				<table className="table align-top" style={{ tableLayout: 'fixed' }}>
																					<thead>
																						<tr>
																							<th>Title</th>
																							<th>URL</th>
																							<th className="text-center">Image</th>
																							<th>Start Date</th>
																							<th>End Date</th>
																							<th />
																						</tr>
																					</thead>
																					<Droppable droppableId="table">
																						{droppableProvided => (
																							<tbody
																								ref={(ref) => {
																									this.tableRef = ref;
																									droppableProvided.innerRef(ref);
																								}}
																								{...droppableProvided.droppableProps}
																							>
																								{activeResources.map((resource, index) => (
																									<Draggable
																										draggableId={resource.id}
																										index={index}
																										key={resource.id}
																									>
																										{(
																											provided,
																											snapshot,
																										) => (
																											<TableRow
																												onExpire={this.handleExpire}
																												provided={provided}
																												snapshot={snapshot}
																												resource={resource}
																											/>
																										)}
																									</Draggable>
																								))}
																								{droppableProvided.placeholder}
																							</tbody>
																						)}
																					</Droppable>
																				</table>
																			</Fragment>
																		</DragDropContext>
																	</Tab>
																	<Tab eventKey="expired" title="Expired">
																		<table className="table align-top">
																			<thead>
																				<tr>
																					<th>Title</th>
																					<th>URL</th>
																					<th className="text-center">Image</th>
																					<th>Start Date</th>
																					<th>End Date</th>
																					<th />
																				</tr>
																			</thead>
																			<tbody>
																				{expiredResources.map(resource => (
																					<tr key={resource.id}>
																						<td>
																							<strong>{resource.title}</strong>
																							<div>
																								{resource.description.length > 100 ? `${resource.description.substr(0, 100)}...` : resource.description}
																							</div>
																						</td>
																						<td>{resource.url}</td>
																						<td className="text-center">
																							{resource.image && (
																								<img alt={`${resource.title}`} style={{ maxWidth: '150px' }} src={resource.image} />
																							)}
																							{!resource.image && (
																								<span>- No Image -</span>
																							)}
																						</td>
																						<td>{resource.runFrom ? moment(resource.runFrom).format('MM/DD/YYYY') : '-'}</td>
																						<td>{resource.runUntil ? moment(resource.runUntil).format('MM/DD/YYYY') : '-'}</td>
																						<td className="text-right">
																							<Link to={`/resources/${resource.id}`} className="btn btn-light">
																								<Edit />
																							</Link>
																						</td>
																					</tr>
																				))}
																			</tbody>
																		</table>
																	</Tab>
																</Tabs>
															)}
														</Fragment>
													);
												}}
											</Query>
										</div>
									</div>
								)}
							</Fragment>
						);
					}}
				</Query>
			</Layout>
		);
	}
}

class TableRow extends Component {
	static propTypes = {
		resource: PropTypes.shape().isRequired,
		provided: PropTypes.shape().isRequired,
		onExpire: PropTypes.func.isRequired,
	};

	render() {
		const { resource, provided } = this.props;
		const {
			id,
			title,
			url,
			description,
			runFrom,
			image,
			runUntil,
		} = resource;

		return (
			<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				<td>
					<strong>{title}</strong>
					<div>
						{description.length > 100 ? `${description.substr(0, 100)}...` : description}
					</div>
				</td>
				<td>{url}</td>
				<td className="text-center">
					{image && (
						<img alt={`${title}`} style={{ maxWidth: '150px' }} src={image} />
					)}
					{!image && (
						<span>- No Image -</span>
					)}
				</td>
				<td>{runFrom ? moment(runFrom).format('MM/DD/YYYY') : '-'}</td>
				<td>{runUntil ? moment(runUntil).format('MM/DD/YYYY') : '-'}</td>
				<td className="text-right">
					<button type="button" className="btn btn-light mr-1" onClick={() => this.props.onExpire(resource.id)}>
						Expire Now
					</button>
					<Link to={`/resources/${id}`} className="btn btn-light">
						<Edit />
					</Link>
				</td>
			</tr>
		);
	}
}

export default withApollo(ListResources);

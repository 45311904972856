import React from 'react';
import PropTypes from 'prop-types';
import { Bounce } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import styled from 'styled-components';

const Loading = props => (
	<Container>
		<Bounce color="#d1d1d1" size={props.size} />
	</Container>
);

Loading.propTypes = {
	size: PropTypes.number,
};

Loading.defaultProps = {
	size: 50,
};

const Container = styled.div`
	margin-top: 125px;
	text-align: center;
`;

export default Loading;

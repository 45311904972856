/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adjustTotalCheckIns = /* GraphQL */ `
  mutation AdjustTotalCheckIns($userId: ID!, $amount: Int!) {
    adjustTotalCheckIns(userId: $userId, amount: $amount) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const adjustTotalTouchbases = /* GraphQL */ `
  mutation AdjustTotalTouchbases($userId: ID!, $amount: Int!) {
    adjustTotalTouchbases(userId: $userId, amount: $amount) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const adjustTotalSurveys = /* GraphQL */ `
  mutation AdjustTotalSurveys($userId: ID!, $amount: Int!) {
    adjustTotalSurveys(userId: $userId, amount: $amount) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const createCheckIn = /* GraphQL */ `
  mutation CreateCheckIn($input: CreateCheckInInput!) {
    createCheckIn(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      answers {
        items {
          id
          answer
        }
        nextToken
      }
      comments
      shared
      moodScale
      owner
      checkInDate
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckIn = /* GraphQL */ `
  mutation UpdateCheckIn($input: UpdateCheckInInput!) {
    updateCheckIn(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      answers {
        items {
          id
          answer
        }
        nextToken
      }
      comments
      shared
      moodScale
      owner
      checkInDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckIn = /* GraphQL */ `
  mutation DeleteCheckIn($input: DeleteCheckInInput!) {
    deleteCheckIn(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      answers {
        items {
          id
          answer
        }
        nextToken
      }
      comments
      shared
      moodScale
      owner
      checkInDate
      createdAt
      updatedAt
    }
  }
`;
export const createCheckInAnswer = /* GraphQL */ `
  mutation CreateCheckInAnswer($input: CreateCheckInAnswerInput!) {
    createCheckInAnswer(input: $input) {
      id
      answer
      checkInQuestion {
        id
        text
        options
        active
        order
        checkInAnswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      checkIn {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        answers {
          nextToken
        }
        comments
        shared
        moodScale
        owner
        checkInDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCheckInAnswer = /* GraphQL */ `
  mutation UpdateCheckInAnswer($input: UpdateCheckInAnswerInput!) {
    updateCheckInAnswer(input: $input) {
      id
      answer
      checkInQuestion {
        id
        text
        options
        active
        order
        checkInAnswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      checkIn {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        answers {
          nextToken
        }
        comments
        shared
        moodScale
        owner
        checkInDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCheckInAnswer = /* GraphQL */ `
  mutation DeleteCheckInAnswer($input: DeleteCheckInAnswerInput!) {
    deleteCheckInAnswer(input: $input) {
      id
      answer
      checkInQuestion {
        id
        text
        options
        active
        order
        checkInAnswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      checkIn {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        answers {
          nextToken
        }
        comments
        shared
        moodScale
        owner
        checkInDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCheckInQuestion = /* GraphQL */ `
  mutation CreateCheckInQuestion($input: CreateCheckInQuestionInput!) {
    createCheckInQuestion(input: $input) {
      id
      text
      options
      active
      order
      checkInAnswers {
        items {
          id
          answer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCheckInQuestion = /* GraphQL */ `
  mutation UpdateCheckInQuestion($input: UpdateCheckInQuestionInput!) {
    updateCheckInQuestion(input: $input) {
      id
      text
      options
      active
      order
      checkInAnswers {
        items {
          id
          answer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCheckInQuestion = /* GraphQL */ `
  mutation DeleteCheckInQuestion($input: DeleteCheckInQuestionInput!) {
    deleteCheckInQuestion(input: $input) {
      id
      text
      options
      active
      order
      checkInAnswers {
        items {
          id
          answer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      id
      cognitoUserId
      email
      role
      firstName
      phoneNumber
      lastName
      active
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      cognitoUserId
      email
      role
      firstName
      phoneNumber
      lastName
      active
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee($input: DeleteEmployeeInput!) {
    deleteEmployee(input: $input) {
      id
      cognitoUserId
      email
      role
      firstName
      phoneNumber
      lastName
      active
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const createMetadata = /* GraphQL */ `
  mutation CreateMetadata($input: CreateMetadataInput!) {
    createMetadata(input: $input) {
      id
      type
      description
      data
      subData
      markdown
      active
      required
      manualEntry
    }
  }
`;
export const updateMetadata = /* GraphQL */ `
  mutation UpdateMetadata($input: UpdateMetadataInput!) {
    updateMetadata(input: $input) {
      id
      type
      description
      data
      subData
      markdown
      active
      required
      manualEntry
    }
  }
`;
export const deleteMetadata = /* GraphQL */ `
  mutation DeleteMetadata($input: DeleteMetadataInput!) {
    deleteMetadata(input: $input) {
      id
      type
      description
      data
      subData
      markdown
      active
      required
      manualEntry
    }
  }
`;
export const createTestResult = /* GraphQL */ `
  mutation CreateTestResult($input: CreateTestResultInput!) {
    createTestResult(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      range
      viralLoad
      testDate
      comments
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateTestResult = /* GraphQL */ `
  mutation UpdateTestResult($input: UpdateTestResultInput!) {
    updateTestResult(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      range
      viralLoad
      testDate
      comments
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestResult = /* GraphQL */ `
  mutation DeleteTestResult($input: DeleteTestResultInput!) {
    deleteTestResult(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      range
      viralLoad
      testDate
      comments
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createTouch = /* GraphQL */ `
  mutation CreateTouch($input: CreateTouchInput!) {
    createTouch(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      touchType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchTouchTypeId
      touchDate
      comments
      owner
      incentivized
      createdAt
      updatedAt
    }
  }
`;
export const updateTouch = /* GraphQL */ `
  mutation UpdateTouch($input: UpdateTouchInput!) {
    updateTouch(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      touchType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchTouchTypeId
      touchDate
      comments
      owner
      incentivized
      createdAt
      updatedAt
    }
  }
`;
export const deleteTouch = /* GraphQL */ `
  mutation DeleteTouch($input: DeleteTouchInput!) {
    deleteTouch(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      touchType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchTouchTypeId
      touchDate
      comments
      owner
      incentivized
      createdAt
      updatedAt
    }
  }
`;
export const createIncentive = /* GraphQL */ `
  mutation CreateIncentive($input: CreateIncentiveInput!) {
    createIncentive(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      givenDate
      value
      comments
      document {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        name
        documentType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        comments
        file {
          bucket
          region
          key
          fileName
        }
        createdAt
        updatedAt
      }
      totalCheckIns
      totalTouchbases
      totalSurveys
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateIncentive = /* GraphQL */ `
  mutation UpdateIncentive($input: UpdateIncentiveInput!) {
    updateIncentive(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      givenDate
      value
      comments
      document {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        name
        documentType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        comments
        file {
          bucket
          region
          key
          fileName
        }
        createdAt
        updatedAt
      }
      totalCheckIns
      totalTouchbases
      totalSurveys
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncentive = /* GraphQL */ `
  mutation DeleteIncentive($input: DeleteIncentiveInput!) {
    deleteIncentive(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      givenDate
      value
      comments
      document {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        name
        documentType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        comments
        file {
          bucket
          region
          key
          fileName
        }
        createdAt
        updatedAt
      }
      totalCheckIns
      totalTouchbases
      totalSurveys
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource($input: CreateResourceInput!) {
    createResource(input: $input) {
      id
      resourceType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      resourceResourceTypeId
      title
      url
      image
      description
      order
      runFrom
      runUntil
      isEvent
      eventStartDate
      eventEndDate
      createdAt
      updatedAt
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      id
      resourceType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      resourceResourceTypeId
      title
      url
      image
      description
      order
      runFrom
      runUntil
      isEvent
      eventStartDate
      eventEndDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource($input: DeleteResourceInput!) {
    deleteResource(input: $input) {
      id
      resourceType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      resourceResourceTypeId
      title
      url
      image
      description
      order
      runFrom
      runUntil
      isEvent
      eventStartDate
      eventEndDate
      createdAt
      updatedAt
    }
  }
`;
export const createHelpRequest = /* GraphQL */ `
  mutation CreateHelpRequest($input: CreateHelpRequestInput!) {
    createHelpRequest(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      closed
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      requestComments
      internalComments
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateHelpRequest = /* GraphQL */ `
  mutation UpdateHelpRequest($input: UpdateHelpRequestInput!) {
    updateHelpRequest(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      closed
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      requestComments
      internalComments
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelpRequest = /* GraphQL */ `
  mutation DeleteHelpRequest($input: DeleteHelpRequestInput!) {
    deleteHelpRequest(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      closed
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      requestComments
      internalComments
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      name
      documentType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      comments
      file {
        bucket
        region
        key
        fileName
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      name
      documentType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      comments
      file {
        bucket
        region
        key
        fileName
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      name
      documentType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      comments
      file {
        bucket
        region
        key
        fileName
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMedication = /* GraphQL */ `
  mutation CreateMedication($input: CreateMedicationInput!) {
    createMedication(input: $input) {
      id
      name
      dosage
      shared
      active
      comments
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      reminders {
        items {
          id
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication($input: UpdateMedicationInput!) {
    updateMedication(input: $input) {
      id
      name
      dosage
      shared
      active
      comments
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      reminders {
        items {
          id
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedication = /* GraphQL */ `
  mutation DeleteMedication($input: DeleteMedicationInput!) {
    deleteMedication(input: $input) {
      id
      name
      dosage
      shared
      active
      comments
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      reminders {
        items {
          id
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createMedicationReminder = /* GraphQL */ `
  mutation CreateMedicationReminder($input: CreateMedicationReminderInput!) {
    createMedicationReminder(input: $input) {
      id
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateMedicationReminder = /* GraphQL */ `
  mutation UpdateMedicationReminder($input: UpdateMedicationReminderInput!) {
    updateMedicationReminder(input: $input) {
      id
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteMedicationReminder = /* GraphQL */ `
  mutation DeleteMedicationReminder($input: DeleteMedicationReminderInput!) {
    deleteMedicationReminder(input: $input) {
      id
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      date
      name
      owner
      reminderBuffer
      createdAt
      updatedAt
    }
  }
`;
export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      date
      name
      owner
      reminderBuffer
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppointment = /* GraphQL */ `
  mutation DeleteAppointment($input: DeleteAppointmentInput!) {
    deleteAppointment(input: $input) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      date
      name
      owner
      reminderBuffer
      createdAt
      updatedAt
    }
  }
`;
export const createReminder = /* GraphQL */ `
  mutation CreateReminder($input: CreateReminderInput!) {
    createReminder(input: $input) {
      id
      active
      time
      message
      notificationSound
      medications {
        items {
          id
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateReminder = /* GraphQL */ `
  mutation UpdateReminder($input: UpdateReminderInput!) {
    updateReminder(input: $input) {
      id
      active
      time
      message
      notificationSound
      medications {
        items {
          id
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteReminder = /* GraphQL */ `
  mutation DeleteReminder($input: DeleteReminderInput!) {
    deleteReminder(input: $input) {
      id
      active
      time
      message
      notificationSound
      medications {
        items {
          id
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createMedicationInstance = /* GraphQL */ `
  mutation CreateMedicationInstance($input: CreateMedicationInstanceInput!) {
    createMedicationInstance(input: $input) {
      id
      medicationId
      reminderId
      userId
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateMedicationInstance = /* GraphQL */ `
  mutation UpdateMedicationInstance($input: UpdateMedicationInstanceInput!) {
    updateMedicationInstance(input: $input) {
      id
      medicationId
      reminderId
      userId
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteMedicationInstance = /* GraphQL */ `
  mutation DeleteMedicationInstance($input: DeleteMedicationInstanceInput!) {
    deleteMedicationInstance(input: $input) {
      id
      medicationId
      reminderId
      userId
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;

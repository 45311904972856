import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import queryString from 'query-string';

import MetadataForm from './forms/MetadataForm';
import * as mutations from '../graphql/mutations';
import Layout from './layout/Layout';
import metadataTypes from '../constants/metadataTypes';

class NewMetadata extends Component {
	static propTypes = {
		location: PropTypes.shape().isRequired,
	};

	render() {
		const ADD_METADATA = gql(mutations.createMetadata);
		const qs = queryString.parse(this.props.location.search);
		const title = metadataTypes[qs.type.toUpperCase()].singular;

		return (
			<Layout title={`New ${title}`}>
				<Mutation mutation={ADD_METADATA}>
					{createMetadata => (
						<MetadataForm submit={createMetadata} metadata={{ type: qs.type.toUpperCase() }} />
					)}
				</Mutation>
			</Layout>
		);
	}
}

export default NewMetadata;

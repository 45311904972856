import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import DocumentForm from './forms/DocumentForm';
import * as mutations from '../graphql/mutations';

class NewDocument extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const ADD_DOCUMENT = gql(mutations.createDocument);

		return (
			<Mutation mutation={ADD_DOCUMENT}>
				{createDocument => (
					<DocumentForm
						submit={createDocument}
						document={{ documentUserId: this.props.match.params.id }}
					/>
				)}
			</Mutation>
		);
	}
}

export default NewDocument;

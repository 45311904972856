import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import Layout from './layout/Layout';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import HelpRequestForm from './forms/HelpRequestForm';
import Loading from './Loading';

class EditHelpRequest extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const { match } = this.props;
		const GET_HELP_REQUEST = gql(queries.getHelpRequest);
		const UPDATE_HELP_REQUEST = gql(mutations.updateHelpRequest);

		return (
			<Layout title="Edit Help Request">
				<Query query={GET_HELP_REQUEST} variables={{ id: match.params.id }} fetchPolicy="no-cache">
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);

						return (
							<Mutation mutation={UPDATE_HELP_REQUEST}>
								{updateHelpRequest => (
									<HelpRequestForm submit={updateHelpRequest} helpRequest={data.getHelpRequest} />
								)}
							</Mutation>
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default EditHelpRequest;

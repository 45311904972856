import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Storage } from 'aws-amplify';
import {
	Add, SaveAlt, Edit, AttachFile,
} from '@material-ui/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as queries from '../graphql-custom/queries';
import Loading from './Loading';


class ListTestResults extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.getTestResultDocumentation = this.getTestResultDocumentation.bind(this);
	}

	getTestResultDocumentation = key => (
		Storage.get(key.replace('public/', ''), {
			level: 'public',
		}).then((data) => {
			window.open(data, '_blank');
		})
	);

	render() {
		const GET_TEST_RESULTS = gql(queries.listUserTestResults);

		const columns = [
			{
				dataField: 'entryDate',
				text: 'Entry Date',
			},
			{
				dataField: 'testDate',
				text: 'Test Date',
			},
			{
				dataField: 'range',
				text: 'Range',
			},
			{
				dataField: 'viralLoad',
				text: 'Viral Load (copies/mL)',
			},
			{
				dataField: 'df1',
				text: '',
				dummyField: true,
				align: 'right',
				formatter: (cell, row) => (
					<Fragment>
						<Dropdown className="d-inline mr-1">
							<Dropdown.Toggle variant="light" id={`docs-${row.id}`}>
								<AttachFile />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{row.documents.items.map(document => (
									<Dropdown.Item onClick={() => { this.getTestResultDocumentation(document.file.key) }} key={document.id} as="button">{document.file.fileName}</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
						<Link to={`${this.props.match.url}/${row.id}`}>
							<button type="button" className="btn btn-light">
								<Edit />
							</button>
						</Link>
					</Fragment>
				),
			},
		];

		return (
			<Query fetchPolicy="no-cache" query={GET_TEST_RESULTS} variables={{ id: this.props.match.params.id }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);


					const testResults = data.user.testResults.items.map(r => ({
						id: r.id,
						entryDate: moment(r.createdAt).format('MM/DD/YYYY'),
						testDate: moment(r.testDate).format('MM/DD/YYYY'),
						range: r.range,
						viralLoad: r.viralLoad,
						documents: r.documents,
					}));

					return (
						<div className="card mb-3">
							<div className="card-body">
								<div className="btn-group mb-3" role="group" aria-label="Basic example">
									<Link className="btn btn-primary mr-1" to="testResults/new">
										<Add />
									</Link>
									<CSVLink data={testResults} filename="testResults.csv" className="btn btn-info" target="_blank">
										<SaveAlt />
									</CSVLink>
								</div>
								<BootstrapTable
									keyField="id"
									bordered={false}
									data={testResults}
									noDataIndication="There are no test results to display."
									columns={columns}
									pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
								/>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default ListTestResults;

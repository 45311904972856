export const updateTouchFrequency = `mutation updateTouchFrequency($input: UpdateUserInput!) {
	updateUser(input: $input) {
		id
	}
}`;

export const archiveUser = `mutation archiveUser($user: UpdateUserInput!, $touch: CreateTouchInput!) {
	updateUser(input: $user) {
		id
	}
	createTouch(input: $touch) {
		id
	}
}`;

export const adjustTotalTouchbases = `mutation adjustTouchbases($userId: ID!, $amount: Int!) {
	adjustTotalTouchbases(userId: $userId, amount: $amount) {
		id
		totalTouchbases
	}
}`;

export const updateUser = `mutation updateUser($input: UpdateUserInput!) {
	updateUser(input: $input) {
		id
	}
}`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import { Delete, SaveAlt } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Query, withApollo, compose } from 'react-apollo';
import CurrencyFormat from 'react-currency-format';
import * as uuid from 'uuid/v1';

import Errors from './Errors';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import FileInput from './FileInput';
import Loading from '../Loading';
import { metadataValues } from '../../constants/metadataTypes';


class IncentiveForm extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
		history: PropTypes.shape().isRequired,
		incentive: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			givenDate,
			value,
			owner,
			document,
			comments,
			type,
		} = this.props.incentive;

		this.state = {
			id: id || '',
			owner,
			givenDate,
			value: value || 0,
			file: {
				id: uuid.default(),
			},
			formattedValue: value ? value.toFixed(2) : '0.00',
			comments: comments || '',
			incentiveTypeId: type
				? type.id
				: '',
			submitted: false,
			errors: [],
			removedReceipt: false,
			existingDocument: document,
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.validate = this.validate.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAmountChange = this.handleAmountChange.bind(this);
	}

	getDocument = key => (
		Storage.get(key.replace('public/', ''), { level: 'public' }).then((data) => {
			window.open(data, '_blank');
		})
	);

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteIncentive),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push(`/users/${this.props.match.params.id}/incentives`);
		});
	}

	handleAmountChange(values) {
		const { formattedValue, value } = values;
		this.setState({
			formattedValue,
			value,
		});
	}

	handleInputChange(event) {
		const {
			target: {
				checked,
				value,
				name,
				type,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate(e) {
		e.preventDefault();

		const valid = this.form.current.reportValidity();
		const userId = this.props.match.params.id;

		if (valid) {
			const {
				id,
				givenDate,
				value,
				comments,
				file,
				owner,
				existingDocument,
				incentiveTypeId,
				removedReceipt,
			} = this.state;

			const incentive = {
				id,
				givenDate: moment(givenDate).format('YYYY-MM-DD'),
				value,
				comments: comments || null,
				incentiveUserId: userId,
				incentiveTypeId,
			};

			let newReceipt;
			let deleteReceipt;

			if (file.fileName) {
				newReceipt = {
					name: file.fileName,
					documentDocumentTypeId: metadataValues.receiptDocumentTypeId,
					file,
					documentUserId: userId,
				};
			}

			if (removedReceipt) {
				deleteReceipt = existingDocument;
			}

			if (!id) {
				incentive.owner = owner;
			}

			this.setState({
				submitted: true,
			});

			this.props.submit({ incentive, newReceipt, deleteReceipt }).then((res) => {
				if (res) {
					this.props.history.push(`/users/${userId}/incentives`);
				}
				this.setState({
					submitted: false,
				});
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	handleFileChange(f) {
		const { id, ...file } = f;
		this.setState({
			file,
		});
	}

	removeReceipt() {
		this.setState({
			removedReceipt: true,
		});
	}

	render() {
		const GET_INCENTIVE_TYPES = gql(queries.metadataByType);

		return (
			<Query query={GET_INCENTIVE_TYPES} fetchPolicy="no-cache" variables={{ type: 'INCENTIVE_TYPE', filter: { active: { eq: true } } }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
							<Errors errors={this.state.errors} />
							<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
							{this.props.incentive && (
								<div className="card mb-3">
									<div className="card-body">
										<div className="row">
											<div className="form-group col-md-4">
												<label htmlFor="incentiveTypeId">Incentive Type</label>
												<select required name="incentiveTypeId" id="incentiveTypeId" type="text" value={this.state.incentiveTypeId} className="form-control" placeholder="Select an option" onChange={this.handleInputChange}>
													<option value="">Select an option</option>
													{data.metadataByType.items.map(option => (
														<option key={option.id} value={option.id}>
															{option.description}
														</option>
													))}
												</select>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-4">
												<label htmlFor="value">Dollar Amount</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<CurrencyFormat
														className="form-control"
														value={this.state.formattedValue}
														thousandSeparator={true}
														decimalScale={2}
														onValueChange={this.handleAmountChange}
													/>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-4">
												<label htmlFor="givenDate">Date Given</label>
												<input name="givenDate" id="givenDate" type="date" value={this.state.givenDate} className="form-control" placeholder="MM/DD/YYYY" onChange={this.handleInputChange} />
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												{this.state.existingDocument && !this.state.removedReceipt && (
													<div>
														<span>Uploaded Receipt</span>
														<div className="mt-2">
															<span>{this.state.existingDocument.file.fileName}</span>
															<button type="button" className="btn btn-sm btn-outline-primary ml-2 mr-1" onClick={() => this.getDocument(this.state.existingDocument.file.key)}>
																<SaveAlt className="align-middle" />
															</button>
															<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => this.removeReceipt()}>
																<Delete />
															</button>
														</div>
													</div>
												)}
												{(!this.state.existingDocument || this.state.removedReceipt) && (
													<FileInput document={this.state.file} showRemove={false} onFileChange={this.handleFileChange} label="Upload Receipt" />
												)}
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-8">
												<label htmlFor="comments">Notes/Comments</label>
												<textarea name="comments" id="comments" value={this.state.comments} className="form-control" rows="3" placeholder="Enter comments" onChange={this.handleInputChange} />
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col-md-6">
									<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
										{this.state.submitted && (
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
										)}
										{this.state.submitted ? 'Loading...' : 'Submit'}
									</button>
									<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
										Cancel
									</button>
								</div>
								<div className="col-md-6 text-right">
									{this.state.id && (
										<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
											Delete
										</button>
									)}
								</div>
							</div>
						</form>
					);
				}}
			</Query>
		);
	}
}

export default compose(
	withRouter,
	withApollo,
)(IncentiveForm);

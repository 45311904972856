import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import IncentiveForm from './forms/IncentiveForm';
import * as mutations from '../graphql/mutations';
import { listUserIncentives } from '../graphql-custom/queries';
import Errors from './forms/Errors';

class NewIncentive extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
		owner: PropTypes.string.isRequired,
		match: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);

		this.state = {
			errors: [],
		};

		this.submit = this.submit.bind(this);
		this.errors = React.createRef();
	}

	submit = ({ incentive, newReceipt }) => {
		const { client } = this.props;
		const GET_INCENTIVES = gql(listUserIncentives);

		// First get the user's total milestones
		return client.query({
			query: GET_INCENTIVES,
			variables: {
				id: this.props.match.params.id,
			},
		}).then(({ data: { user } }) => {
			const checkInCount = user.checkIns.items.length;
			const touchesCount = user.touches.items.filter(t => t.incentivized).length;
			const surveyCount = user.totalSurveys;

			// Add the incentive receipt.
			return client.mutate({
				mutation: gql(mutations.createDocument),
				variables: {
					input: newReceipt,
				},
			}).then(res => (
				// Create the incentive and stamp the milestones
				client.mutate({
					mutation: gql(mutations.createIncentive),
					variables: {
						input: {
							totalSurveys: surveyCount || 0,
							totalCheckIns: checkInCount || 0,
							totalTouchbases: touchesCount || 0,
							incentiveDocumentId: res.data.createDocument.id,
							...incentive,
						},
					},
				})
			));
		});
	};

	render() {
		const { owner } = this.props;

		const incentive = {
			owner,
		};

		return (
			<Fragment>
				<Errors errors={this.state.errors} />
				<IncentiveForm submit={this.submit} incentive={incentive} />
			</Fragment>
		);
	}
}

export default withApollo(NewIncentive);

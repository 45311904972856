// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://idibdfmagjbnpghdmctxutsllq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "icanrest",
            "endpoint": "https://x3n6z65b0g.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:69120fb6-b602-4598-8867-d8d000c3e687",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VW6EK9BRk",
    "aws_user_pools_web_client_id": "2j0gtn46dhj1h4sf19p64jas05",
    "oauth": {},
    "aws_user_files_s3_bucket": "icanstorage-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "icanhosting-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://icanhosting-dev.s3-website-us-east-1.amazonaws.com",
    "aws_mobile_analytics_app_id": "47b43b3180544526bb3ac628096f3abe",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;

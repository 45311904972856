import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { compose, withApollo } from 'react-apollo';
import * as uuid from 'uuid/v1';
import { Delete, SaveAlt, Add } from '@material-ui/icons';
import gql from 'graphql-tag';
import { Storage } from 'aws-amplify';

import Errors from './Errors';
import FileInput from './FileInput';
import * as mutations from '../../graphql/mutations';

class TestResultForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		match: PropTypes.shape().isRequired,
		testResult: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			testDate,
			range,
			viralLoad,
			owner,
			comments,
			documents,
		} = this.props.testResult;

		this.state = {
			id: id || '',
			testDate: testDate || '',
			range: range || '',
			viralLoad: viralLoad || '',
			documents: documents ? documents.items : [],
			owner,
			comments: comments || '',
			submitted: false,
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleAddDocument = this.handleAddDocument.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileRemove = this.handleFileRemove.bind(this);
		this.handleFileDelete = this.handleFileDelete.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.validate = this.validate.bind(this);
	}

	getTestResultDocumentation = key => (
		Storage.get(key.replace('public/', ''), {
			level: 'public',
		}).then((data) => {
			window.open(data, '_blank');
		})
	);


	handleFileChange(file) {
		this.setState((prevState) => {
			// Clone the previous collection of documents
			const documents = prevState.documents.slice(0);

			// Find the index of the modified document and update it
			const index = documents.map(d => d.id).indexOf(file.id);
			documents[index] = file;

			// Update state with modified collection of documents
			return {
				documents,
			};
		});
	}

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteTestResult),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push(`/users/${this.props.match.params.id}/testResults`);
		});
	}

	handleFileRemove(id) {
		this.setState((prevState) => {
			// Clone and filter out the file for removal.
			const documents = prevState.documents.slice(0)
				.filter(d => d.id !== id);

			return {
				documents,
			};
		});
	}

	handleFileDelete(id) {
		this.setState((prevState) => {
			const index = prevState.documents.map(d => d.id).indexOf(id);
			const documents = prevState.documents.slice(0);
			documents[index].deleted = true;
			return {
				documents,
			};
		});
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				value,
				type,
				checked,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	handleAddDocument() {
		this.setState(prevState => ({
			documents: [
				...prevState.documents,
				{ id: uuid.default() },
			],
		}));
	}

	validate(e) {
		e.preventDefault();
		const { match } = this.props;
		const userId = match.params.id;
		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				testDate,
				viralLoad,
				comments,
				documents,
				range,
				owner,
			} = this.state;

			const testResult = {
				id,
				testResultUserId: userId,
				testDate,
				comments: comments || null,
				range,
				documents,
			};

			if (!id) {
				testResult.owner = owner;
			}

			if (range === 'Greater than 20 copies/mL') {
				testResult.viralLoad = viralLoad;
			}

			this.setState({
				submitted: true,
			});

			this.props.submit(testResult).then(() => {
				this.props.history.push(`/users/${userId}/testResults`);
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		const newDocuments = this.state.documents.filter(d => !d.file);
		const currentDocuments = this.state.documents.filter(d => d.file);
		const ranges = [
			'Greater than 20 copies/mL',
			'Less than 20 copies/mL',
			'Undetectable',
		];

		return (
			<form data-testid="test-result-form" className="mx-2" ref={this.form} onSubmit={e => this.validate(e)}>
				<div className="card mb-3">
					<div className="card-body">
						<Errors errors={this.state.errors} />
						<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
						{this.props.testResult && (
							<Fragment>
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="testDate">Test Date</label>
										<input required name="testDate" id="testDate" type="date" value={this.state.testDate} className="form-control" placeholder="Enter test date" onChange={this.handleInputChange} />
									</div>
									<div className="form-group col-md-4">
										<label htmlFor="range">Range</label>
										<select required name="range" id="range" value={this.state.range} className="form-control" onChange={this.handleInputChange}>
											<option value="">Select an option</option>
											{ranges.map(option => (
												<option key={option} value={option}>
													{option}
												</option>
											))}
										</select>
									</div>
									{this.state.range === 'Greater than 20 copies/mL' && (
										<div className="form-group col-md-4">
											<label htmlFor="viralLoad">Viral Load</label>
											<input name="viralLoad" id="viralLoad" type="number" value={this.state.viralLoad} className="form-control" placeholder="Enter copies/mL" onChange={this.handleInputChange} required />
										</div>
									)}
								</div>
								<div className="row">
									<div className="form-group col-md-8">
										<label htmlFor="comments">Notes/Comments</label>
										<textarea className="form-control" id="comments" name="comments" rows="3" value={this.state.comments} onChange={this.handleInputChange} />
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<fieldset className="form-group">
											<legend className="col-form-legend">
												Documentation
											</legend>
											<table className="table table-sm">
												<tbody>
													{currentDocuments.filter(document => !document.deleted).map(document => (
														<tr key={document.id}>
															<td>
																<span className="align-middle">{document.name}</span>
															</td>
															<td>
																<button type="button" onClick={() => this.getTestResultDocumentation(document.file.key)} className="btn btn-sm btn-outline-primary mr-1">
																	<SaveAlt className="align-middle" />
																</button>
																{currentDocuments.length > 1 && (
																	<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => this.handleFileDelete(document.id)}>
																		<Delete />
																	</button>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</fieldset>
									</div>
								</div>
								{newDocuments.map((document, index) => (
									<div key={document.id}>
										<FileInput document={document} onRemove={this.handleFileRemove} showRemove={currentDocuments.length > 0 || index > 0} onFileChange={this.handleFileChange} label="Test Documentation" />
									</div>
								))}
								<button type="button" onClick={this.handleAddDocument} className="btn btn-outline-secondary btn-sm">
									<Add /> Documentation
								</button>
							</Fragment>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
							{this.state.submitted && (
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
							)}
							{this.state.submitted ? 'Loading...' : 'Submit'}
						</button>
						<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
							Cancel
						</button>
					</div>
					<div className="col-md-6 text-right">
						{this.state.id && (
							<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
								Delete
							</button>
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default compose(
	withRouter,
	withApollo,
)(TestResultForm);

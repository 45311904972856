import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { withApollo, compose } from 'react-apollo';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gql from 'graphql-tag';

import Errors from './Errors';
import * as mutations from '../../graphql/mutations';

class CheckInQuestionForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		checkInQuestion: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			active,
			text,
			options,
		} = this.props.checkInQuestion;

		this.state = {
			id: id || '',
			active: active === undefined ? true : active,
			text: text || '',
			options: options || [],
			option: '',
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleAddOption = this.handleAddOption.bind(this);
		this.handleDeleteOption = this.handleDeleteOption.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.validate = this.validate.bind(this);
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination || result.destination.index === result.source.index) {
			return;
		}

		// no movement
		if (result.destination.index === result.source.index) {
			return;
		}

		this.setState(prevState => ({
			options: this.reorder(prevState.options, result.source.index, result.destination.index),
		}));
	};

	reorder = (options, startIndex, endIndex) => {
		const result = Array.from(options);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	validate(e) {
		e.preventDefault();
		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				active,
				text,
				options,
			} = this.state;

			const checkInQuestion = {
				id,
				active,
				text,
				options,
			};

			this.setState({
				submitted: true,
			});

			this.props.submit(checkInQuestion).then(() => {
				this.props.history.push('/admin/checkInQuestions');
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteCheckInQuestion),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push('/admin/checkInQuestions');
		});
	}

	handleAddOption() {
		const {
			options,
			option,
		} = this.state;

		this.setState({
			options: [...options, option],
			option: '',
		});
	}

	handleDeleteOption(index) {
		const { options } = this.state;
		this.setState({
			options: [...options.slice(0, index), ...options.slice(index + 1)],
		});
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				type,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	render() {
		return (
			<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
				<Errors errors={this.state.errors} />
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				{this.props.checkInQuestion && (
					<Fragment>
						<div className="card mb-3">
							<div className="card-body">
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="text">Question</label>
										<input name="text" id="text" type="text" value={this.state.text} className="form-control" placeholder="Question text..." onChange={this.handleInputChange} />
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-4">
										<div className="form-check">
											<input type="checkbox" name="active" checked={this.state.active} className="form-check-input" id="active" onChange={this.handleInputChange} />
											<label className="form-check-label" htmlFor="active">Active</label>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="text">Add Option</label>
										<div className="input-group">
											<input name="option" id="option" type="text" value={this.state.option} className="form-control" placeholder="Option..." onChange={this.handleInputChange} />
											<div className="input-group-append">
												<button type="button" className="btn btn-sm btn-info" onClick={this.handleAddOption}>Add Option</button>
											</div>
										</div>
									</div>
								</div>
								<div>
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Fragment>
											<table className="table align-top" style={{ tableLayout: 'fixed' }}>
												<thead>
													<tr>
														<th>Option</th>
														<th />
													</tr>
												</thead>
												<Droppable droppableId="table">
													{droppableProvided => (
														<tbody
															ref={(ref) => {
																this.tableRef = ref;
																droppableProvided.innerRef(ref);
															}}
															{...droppableProvided.droppableProps}
														>
															{this.state.options.map((option, index) => (
																<Draggable
																	draggableId={`${option}`}
																	index={index}
																	key={`${option}`}
																>
																	{(
																		provided,
																		snapshot,
																	) => (
																		<TableRow
																			provided={provided}
																			snapshot={snapshot}
																			option={option}
																			index={index}
																			onDelete={this.handleDeleteOption}
																		/>
																	)}
																</Draggable>
															))}
															{droppableProvided.placeholder}
														</tbody>
													)}
												</Droppable>
											</table>
										</Fragment>
									</DragDropContext>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
									{this.state.submitted && (
										<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
									)}
									{this.state.submitted ? 'Loading...' : 'Submit'}
								</button>
								<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
											Cancel
								</button>
							</div>
							<div className="col-md-6 text-right">
								{this.state.id && (
									<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
											Delete
									</button>
								)}
							</div>
						</div>
					</Fragment>
				)}
			</form>
		);
	}
}

class TableRow extends Component {
	static propTypes = {
		option: PropTypes.string.isRequired,
		index: PropTypes.number.isRequired,
		provided: PropTypes.shape().isRequired,
		onDelete: PropTypes.func.isRequired,
	};

	render() {
		const {
			option,
			index,
			provided,
			onDelete,
		} = this.props;

		return (
			<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				<td>{option}</td>
				<td className="text-right">
					<button className="btn btn-sm" type="button" onClick={() => { onDelete(index) }}>Delete</button>
				</td>
			</tr>
		);
	}
}

export default compose(
	withRouter,
	withApollo,
)(CheckInQuestionForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { compose, withApollo, Query } from 'react-apollo';
import { API } from 'aws-amplify';
import gql from 'graphql-tag';

import Errors from './Errors';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Loading from '../Loading';

class ResourceForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		resource: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			title,
			url,
			description,
			resourceResourceTypeId,
			image,
			runFrom,
			runUntil,
		} = this.props.resource;

		this.state = {
			id: id || '',
			title: title || '',
			url: url || '',
			description: description || '',
			resourceResourceTypeId: resourceResourceTypeId || '',
			image: image || '',
			runFrom: runFrom || '',
			runUntil: runUntil || '',
			submitted: false,
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.fetchWebsite = this.fetchWebsite.bind(this);
		this.validate = this.validate.bind(this);
	}

	fetchWebsite() {
		return API.get('icanrest', '/resources/meta', {
			queryStringParameters: {
				url: this.state.url,
			},
		}).then((response) => {
			if (response) {
				const meta = {};

				if (response.description) {
					const span = document.createElement('span');
					span.innerHTML = response.description;
					meta.description = span.textContent || span.innerText;
					meta.image = response.image;
				}
				if (response.title) {
					meta.title = response.title;
				}

				this.setState(meta);
			}
		});
	}

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteResource),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push('/resources');
		});
	}

	handleInputChange(event) {
		const {
			target: {
				type,
				name,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate(e) {
		e.preventDefault();

		const valid = this.form.current.reportValidity();
		if (valid) {
			const {
				id,
				title,
				description,
				url,
				image,
				resourceResourceTypeId,
				runFrom,
				runUntil,
			} = this.state;

			const resource = {
				id,
				title,
				description,
				url: url || null,
				runFrom: runFrom || null,
				runUntil: runUntil || null,
				image: image || null,
				resourceResourceTypeId,
				order: 1,
			};


			this.setState({
				submitted: true,
			});

			this.props.submit({ variables: { input: resource } }).then(() => {
				this.props.history.push('/resources');
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		const GET_RESOURCE_TYPES = gql(queries.metadataByType);

		return (
			<Query query={GET_RESOURCE_TYPES} variables={{ type: 'RESOURCE_TYPE', filter: { active: { eq: true } } }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
							<Errors errors={this.state.errors} />
							<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
							{this.props.resource && (
								<div className="card mb-3">
									<div className="card-body">
										<div className="row">
											<div className="col-md-7">
												<div className="row">
													<div className="form-group col-md-6">
														<label htmlFor="resourceType">Resource Type</label>
														<select required name="resourceResourceTypeId" id="resourceType" value={this.state.resourceResourceTypeId} className="form-control" onChange={this.handleInputChange}>
															<option value="">Select an option</option>
															{data.metadataByType.items.map(option => (
																<option key={option.id} value={option.id}>
																	{option.description}
																</option>
															))}
														</select>
													</div>
												</div>
												<div className="row">
													<div className="form-group col-md-12">
														<label htmlFor="url">Website</label>
														<div className="input-group">
															<input name="url" id="url" type="text" value={this.state.url} className="form-control" placeholder="Enter URL" onChange={this.handleInputChange} maxLength="500" />
															<div className="input-group-append">
																<button className="btn btn-outline-secondary" onClick={this.fetchWebsite} type="button">Fetch</button>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="form-group col-md-12">
														<label htmlFor="title">Title</label>
														<input name="title" id="title" type="text" value={this.state.title} className="form-control" placeholder="Enter value" required onChange={this.handleInputChange} />
													</div>
												</div>
												<div className="row">
													<div className="form-group col-md-12">
														<label htmlFor="image">Image URL</label>
														<input name="image" id="image" type="text" value={this.state.image} className="form-control" placeholder="Enter Image URL" onChange={this.handleInputChange} maxLength="500" />
													</div>
												</div>
												<div className="row">
													<div className="form-group col-md-12">
														<label htmlFor="description">Description</label>
														<textarea name="description" required rows="3" id="description" value={this.state.description} className="form-control" placeholder="Enter A Description" onChange={this.handleInputChange} />
													</div>
												</div>
												<div className="row">
													<div className="form-group col-md-6">
														<label htmlFor="runFrom">Start Date</label>
														<input name="runFrom" id="runFrom" value={this.state.runFrom} type="date" className="form-control" placeholder="Enter value" onChange={this.handleInputChange} />
													</div>
													<div className="form-group col-md-6">
														<label htmlFor="runUntil">End Date</label>
														<input name="runUntil" id="runUntil" value={this.state.runUntil} type="date" className="form-control" placeholder="Enter value" onChange={this.handleInputChange} />
													</div>
												</div>
											</div>
											<div className="col-md-5">
												{this.state.image && (
													<div>
														<img width="300" alt="logo" src={this.state.image} />
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="row">
								<div className="col-md-6">
									<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
										{this.state.submitted && (
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
										)}
										{this.state.submitted ? 'Loading...' : 'Submit'}
									</button>
									<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
										Cancel
									</button>
								</div>
								<div className="col-md-6 text-right">
									{this.state.id && (
										<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
											Delete
										</button>
									)}
								</div>
							</div>
						</form>
					);
				}}
			</Query>
		);
	}
}

export default compose(
	withRouter,
	withApollo,
)(ResourceForm);

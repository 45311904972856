import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { withApollo, compose } from 'react-apollo';
import gql from 'graphql-tag';

import * as mutations from '../graphql-custom/mutations';

class EditGoals extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		location: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			checkInsGoal,
			touchbasesGoal,
			surveysGoal,
		} = this.props.location.state.user;

		this.state = {
			id,
			checkInsGoal,
			touchbasesGoal,
			surveysGoal,
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				value,
			},
		} = event;

		this.setState({
			[name]: value,
		});
	}

	validate(e) {
		e.preventDefault();

		const { client } = this.props;
		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				checkInsGoal,
				touchbasesGoal,
				surveysGoal,
			} = this.state;

			const user = {
				id,
				checkInsGoal,
				touchbasesGoal,
				surveysGoal,
			};

			this.setState({
				submitted: true,
			});

			client.mutate({
				mutation: gql(mutations.updateUser),
				variables: {
					input: user,
				},
			}).then(() => {
				this.props.history.push(`/users/${id}/incentives`);
			});
		}
	}

	render() {
		return (
			<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				<div className="card mb-3">
					<div className="card-body">
						<div className="row">
							<div className="form-group col-md-4">
								<label htmlFor="checkInsGoal">Check Ins Goal</label>
								<input name="checkInsGoal" id="checkInsGoal" type="number" min="0" value={this.state.checkInsGoal} className="form-control" placeholder="Check Ins Goal" onChange={this.handleInputChange} />
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-4">
								<label htmlFor="touchbasesGoal">Touch Bases Goal</label>
								<input name="touchbasesGoal" id="touchbasesGoal" type="number" min="0" value={this.state.touchbasesGoal} className="form-control" placeholder="Touch Bases Goal" onChange={this.handleInputChange} />
							</div>
						</div>
						<div className="row">
							<div className="form-group col-md-4">
								<label htmlFor="surveysGoal">Surveys Goal</label>
								<input name="surveysGoal" id="surveysGoal" type="number" min="0" value={this.state.surveysGoal} className="form-control" placeholder="Surveys Goal" onChange={this.handleInputChange} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
							{this.state.submitted && (
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
							)}
							{this.state.submitted ? 'Loading...' : 'Submit'}
						</button>
						<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
									Cancel
						</button>
					</div>
				</div>
			</form>
		);
	}
}


export default compose(
	withRouter,
	withApollo,
)(EditGoals);

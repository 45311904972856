import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import queryString from 'query-string';

import ResourceForm from './forms/ResourceForm';
import * as mutations from '../graphql/mutations';
import Layout from './layout/Layout';

class NewResource extends Component {
	static propTypes = {
		location: PropTypes.shape().isRequired,
	};

	render() {
		const ADD_RESOURCE = gql(mutations.createResource);
		const qs = queryString.parse(this.props.location.search);

		return (
			<Layout title="New Resource">
				<Mutation mutation={ADD_RESOURCE}>
					{createResource => (
						<ResourceForm submit={createResource} resource={{ resourceResourceTypeId: qs.type }} />
					)}
				</Mutation>
			</Layout>
		);
	}
}

export default NewResource;

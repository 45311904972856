import React, { Fragment } from 'react';
import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import {
	withAuthenticator,
	AmplifyTheme,
	SignIn,
	ConfirmSignIn,
	RequireNewPassword,
	VerifyContact,
	ForgotPassword,
	Loading,
} from 'aws-amplify-react';
import posed, { PoseGroup } from 'react-pose';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Dashboard from './components/dashboard/Dashboard';
import NewUser from './components/NewUser';
import Users from './components/Users';
import User from './components/User';
import awsconfig from './aws-exports';
import ListMetadata from './components/ListMetadata';
import EditMetadata from './components/EditMetadata';
import NewMetadata from './components/NewMetadata';
import ListResources from './components/ListResources';
import NewResource from './components/NewResource';
import EditResource from './components/EditResource';
import EditHelpRequest from './components/EditHelpRequest';
import ListEmployees from './components/ListEmployees';
import EditEmployee from './components/EditEmployee';
import NewEmployee from './components/NewEmployee';
import ListCheckInQuestions from './components/ListCheckInQuestions';
import NewCheckInQuestion from './components/NewCheckInQuestion';
import EditCheckInQuestion from './components/EditCheckInQuestion';
import ListSurveys from './components/ListSurveys';
import ListAdminPages from './components/ListAdminPages';
import ListReports from './components/ListReports';

Amplify.configure(awsconfig);
require('./styles/app.scss');

// Theming the amplify login components.
const theme = Object.assign({}, AmplifyTheme);
theme.button.backgroundColor = 'white';
theme.sectionHeader.backgroundColor = 'white';
theme.sectionHeader.color = 'black';
theme.container.fontFamily = 'Muli, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			group: null,
		};
	}

	signOut = async () => {
		await Auth.signOut();
	};

	componentDidMount = async () => {
		const groups = (await Auth.currentSession()).idToken.payload['cognito:groups'] || [];
		const group = groups.length > 0 ? groups[0] : 'administrators';
		this.setState({
			group,
		});
	};

	render() {
		const ADMIN_GROUP = 'administrators';

		return (
			<BrowserRouter>
				<Route render={({ location }) => (
					<div className="grid-container">
						{this.state.group && (
							<Fragment>
								<Header signOut={this.signOut} group={this.state.group} />
								<PoseGroup>
									<RoutesContainer style={{ gridArea: 'main' }} key={location.pathname}>
										<Switch location={location}>
											<Route exact path="/" component={Dashboard} />
											<Route path="/users" exact component={Users} />
											<Route path="/resources/new" component={NewResource} />
											<Route path="/resources/:id" component={EditResource} />
											<Route path="/resources" exact component={ListResources} />
											<Route path="/reports" exact component={ListReports} />
											<Route path="/helpRequests/:id" component={EditHelpRequest} />
											<Route exact path="/admin" component={ListAdminPages} />
											<Route path="/admin/metadata/new" component={NewMetadata} />
											<Route path="/admin/metadata/:id" component={EditMetadata} />
											<ProtectedRoute path="/admin/metadata" group={this.state.group} required={ADMIN_GROUP} component={ListMetadata} />
											<Route path="/admin/employees/new" component={NewEmployee} />
											<Route path="/admin/employees/:id" component={EditEmployee} />
											<Route path="/admin/employees" component={ListEmployees} />
											<Route path="/admin/surveys" component={ListSurveys} />
											<Route path="/admin/checkInQuestions/new" component={NewCheckInQuestion} />
											<Route path="/admin/checkInQuestions/:id" component={EditCheckInQuestion} />
											<Route path="/admin/checkInQuestions" component={ListCheckInQuestions} />
										</Switch>
									</RoutesContainer>
								</PoseGroup>
								<Switch>
									<Route path="/users/new" component={NewUser} />
									<Route path="/users/archived" exact component={Users} />
									<Route path="/users/:id" component={User} />
								</Switch>
							</Fragment>
						)}
						<Footer />
					</div>
				)}
				/>
			</BrowserRouter>
		);
	}
}

class ProtectedRoute extends React.Component {
	render() {
		const { component: Component, ...rest } = this.props;
		return (
			<Route {...rest}
				render={props => (
					rest.group === rest.required ? <Component {...props} /> : <Redirect to="/" />
				)}
			/>
		);
	}
}

ProtectedRoute.propTypes = {
	component: PropTypes.func.isRequired,
	group: PropTypes.string.isRequired,
};

const RoutesContainer = posed.div({
	enter: { opacity: 1, delay: 300 },
	exit: { opacity: 0 },
});

export default withAuthenticator(App, {
	includeGreetings: false,
	authenticatorComponents: [
		<SignIn key="signIn" />,
		<ConfirmSignIn key="confirmSignIn" />,
		<RequireNewPassword key="requireNewPassword" />,
		<VerifyContact key="verifyContact" />,
		<ForgotPassword key="forgotPassword" />,
		<Loading key="loading" />,
	],
	theme,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Add, Edit, SaveAlt } from '@material-ui/icons';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import queryString from 'query-string';
import { CSVLink } from 'react-csv';

import * as queries from '../graphql/queries';
import Layout from './layout/Layout';
import metadataTypes from '../constants/metadataTypes';
import Loading from './Loading';

class ListMetadata extends React.Component {
	static propTypes = {
		location: PropTypes.shape().isRequired,
	};

	render() {
		const GET_METADATA = gql(queries.metadataByType);
		const qs = queryString.parse(this.props.location.search);
		const { type } = qs;
		const title = metadataTypes[type.toUpperCase()].plural;

		const typesWithData = [
			'system_setting',
			'touch_frequency',
		];

		const typesWithManualEntry = [
			'sexual_orientation',
			'gender',
			'referral_type',
		];

		const hasManualEntry = typesWithManualEntry.indexOf(type.toLowerCase()) >= 0;
		const hasData = typesWithData.indexOf(type.toLowerCase()) >= 0;

		return (
			<Layout title={title}>
				<Query fetchPolicy="no-cache" query={GET_METADATA} variables={{ limit: 100, type: type.toUpperCase() }}>
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);


						return (
							<div className="card mb-3">
								<div className="card-body">
									<div className="mb-4 row">
										<div className="col-md-3">
											<div className="btn-group" role="group" aria-label="Basic example">
												<Link className="btn btn-primary mr-1" to={`/admin/metadata/new?type=${qs.type}`}>
													<Add />
												</Link>
												{data.metadataByType.items.length > 0 && (
													<CSVLink data={data.metadataByType.items} filename={`${title}.csv`} className="btn btn-info" target="_blank">
														<SaveAlt />
													</CSVLink>
												)}
											</div>
										</div>
									</div>
									{data.metadataByType.items.length === 0 && (
										<p>There are no items to display.</p>
									)}
									{data.metadataByType.items.length > 0 && (
										<table className="table table-sm">
											<thead>
												<tr>
													<th>Description</th>
													<th>Active</th>
													{hasData && <th>Data</th>}
													{hasManualEntry && <th>Trigger Manual Entry</th>}
													<th />
												</tr>
											</thead>
											<tbody>
												{data.metadataByType.items.map(metadata => (
													<tr key={metadata.id}>
														<td>
															<Link to={`/admin/metadata/${metadata.id}`} className="d-block mb-1 font-weight-bold">{metadata.description}</Link>
														</td>
														<td>
															{metadata.active ? 'Yes' : 'No'}
														</td>
														{hasData && (
															<td>
																{metadata.data}
															</td>
														)}
														{hasManualEntry && (
															<td>
																{metadata.manualEntry ? 'Yes' : 'No'}
															</td>
														)}
														<td className="text-right">
															<div className="btn-group" role="group">
																<Link to={`/admin/metadata/${metadata.id}`} className="btn btn-sm btn-light mr-1">
																	<Edit />
																</Link>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									)}
								</div>
							</div>
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default ListMetadata;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';

import ResourceForm from './forms/ResourceForm';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import Layout from './layout/Layout';
import Loading from './Loading';

class EditResource extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const { match } = this.props;

		const GET_RESOURCE = gql(queries.getResource);
		const EDIT_RESOURCE = gql(mutations.updateResource);

		return (
			<Query query={GET_RESOURCE} fetchPolicy="no-cache" variables={{ id: match.params.id }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<Layout title="Edit Resource">
							<Mutation mutation={EDIT_RESOURCE}>
								{updateResource => (
									<ResourceForm submit={updateResource} resource={data.getResource} />
								)}
							</Mutation>
						</Layout>
					);
				}}
			</Query>
		);
	}
}

export default EditResource;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import CheckInQuestionForm from './forms/CheckInQuestionForm';
import Layout from './layout/Layout';
import Loading from './Loading';

class EditCheckInQuestion extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
	}

	submit = (checkInQuestion) => {
		const { client } = this.props;

		return client.mutate({
			mutation: gql(mutations.updateCheckInQuestion),
			variables: {
				input: checkInQuestion,
			},
		});
	};

	render() {
		const { match } = this.props;
		const GET_CHECK_IN_QUESTION = gql(queries.getCheckInQuestion);

		return (
			<Layout title="Edit Check-In Question">
				<Query query={GET_CHECK_IN_QUESTION} fetchPolicy="no-cache" variables={{ id: match.params.id }}>
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);

						return (
							<CheckInQuestionForm submit={this.submit} checkInQuestion={data.getCheckInQuestion} />
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default withApollo(EditCheckInQuestion);

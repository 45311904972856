import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { Typeahead } from 'react-bootstrap-typeahead';
import { compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { PersonOutline } from '@material-ui/icons';

import Navigation from './Navigation';

class Header extends React.Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
		signOut: PropTypes.func.isRequired,
		group: PropTypes.string.isRequired,
		history: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			users: [],
		};
	}

	async search(term) {
		const { client } = this.props;
		if (term.length >= 3) {
			const result = await client.query({
				query: gql(`query searchUsers($term: String!) {
					listUsers(limit: 1000, filter: {searchName: { contains: $term}}) {
					  items {
						 id
						 name
					  }
					}
				 }
				 `),
				fetchPolicy: 'no-cache',
				variables: {
					term: term.toLowerCase(),
				},
			});

			this.setState({
				users: result.data.listUsers.items,
			});
		}
	}

	render() {
		return (
			<Container>
				<Greeting>
					<div className="container-fluid">
						<strong>iCAN App Management!</strong>
						<div className="right-controls">
							<Typeahead
								labelKey="name"
								ref={(ref) => { this.typeahead = ref }}
								options={this.state.users}
								placeholder="Search for a participant..."
								highlightOnlyResult={true}
								minLength={3}
								selectHintOnEnter={true}
								onInputChange={term => this.search(term)}
								onChange={(selected) => {
									if (selected) {
										this.props.history.push(`/users/${selected[0].id}/details`);
										this.typeahead.getInstance().clear();
									}
								}}
							/>
							<Dropdown>
								<Dropdown.Toggle variant="success" id="dropdown-basic">
									<PersonOutline />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item as="button" onClick={this.props.signOut}>Sign Out</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</Greeting>
				<NavBar>
					<div className="container-fluid">
						<Navigation group={this.props.group} />
					</div>
				</NavBar>
			</Container>
		);
	}
}

const Container = styled.div`
	grid-area: header;
	background-color: #32c787;
	box-shadow: 0 5px 5px -3px rgba(0,0,0,.15);
	height: 90px;
	position: fixed;
	width: 100%;
	z-index: 5;
`;

const Greeting = styled.div`
	> div {
		height: 50px;
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}

	.dropdown > button {
				height: 40px;
			width: 40px;
			border-radius: 50%;

	::after {
				display: none;
		}
	}

	.right-controls {
		display: flex;
		align-items: center;
		input {
			width: 250px;
			border: 1px solid transparent;
			background-color: #2bac75;
			color: white !important;
			margin-right: 7px;

			&::-webkit-input-placeholder  {
				color: white;
			}

			:focus {
				outline: none !important;
			}
		}
	}
`;

const NavBar = styled.div`
	background-color: white;
`;


export default compose(
	withApollo,
	withRouter,
)(Header);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API, Auth } from 'aws-amplify';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import EmployeeForm from './forms/EmployeeForm';
import Layout from './layout/Layout';
import Loading from './Loading';

class EditEmployee extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
		this.state = {
			user: null,
		};
	}

	submit = employee => (
		this.props.client.mutate({
			mutation: gql(mutations.updateEmployee),
			variables: {
				input: employee,
			},
		}).then(async ({ data: { updateEmployee: result } }) => {
			const promises = [];
			const { active, role, phoneNumber } = this.state.user;

			if (active !== result.active) {
				const param = result.active ? 'enable' : 'disable';
				promises.push(API.post('icanrest', `/users/${result.email}/${param}`, {
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				}));
			}

			if (role !== result.role) {
				promises.push(API.put('icanrest', `/users/${result.email}/groups`, {
					body: {
						removeGroupName: role === 'ADMINISTRATOR' ? 'administrators' : 'employees',
						groupName: result.role === 'ADMINISTRATOR' ? 'administrators' : 'employees',
					},
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				}));
			}

			if (phoneNumber !== result.phoneNumber) {
				promises.push(API.put('icanrest', `/users/${result.email}/attributes`, {
					body: {
						userAttributes: [
							{ Name: 'phone_number_verified', Value: 'true' },
							{ Name: 'email_verified', Value: 'true' },
							{ Name: 'phone_number', Value: `+1${result.phoneNumber.replace(/-/g, '')}` },
						],
					},
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				}));
			}

			// Enable MFA on new Cognito user
			promises.push(API.put('icanrest', `/users/${result.email}/mfa`, {
				body: {
					enabled: true,
				},
				headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
			}));

			return Promise.all(promises);
		})
	);

	render() {
		const { match } = this.props;
		const GET_EMPLOYEE = gql(queries.getEmployee);

		return (
			<Layout title="Edit Employee">
				<Query
					query={GET_EMPLOYEE}
					variables={{ id: match.params.id }}
					fetchPolicy="no-cache"
					onCompleted={res => this.setState({ user: res.getEmployee })}
				>
					{({ loading, error, data }) => {
						if (error) return (<h3>Error!</h3>);
						if (loading) return (<Loading />);

						return (
							<EmployeeForm submit={this.submit} employee={data.getEmployee} />
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default withApollo(EditEmployee);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import * as uuid from 'uuid/v1';

import TestResultForm from './forms/TestResultForm';
import * as mutations from '../graphql/mutations';
import { metadataValues } from '../constants/metadataTypes';

class NewUserTestResult extends Component {
	static propTypes = {
		owner: PropTypes.string.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
	}

	submit(testResult) {
		const { documents, ...input } = testResult;

		return this.props.client.mutate({
			mutation: gql(mutations.createTestResult),
			variables: {
				input,
			},
		}).then(({ data: { createTestResult: result } }) => {
			const promises = [];

			documents.forEach((f) => {
				const { id, ...file } = f;
				const document = {
					name: file.fileName,
					documentDocumentTypeId: metadataValues.testResultDocumentTypeId,
					file,
					documentUserId: testResult.testResuåltUserId,
					testResultDocumentsId: result.id,
				};

				promises.push(this.props.client.mutate({
					mutation: gql(mutations.createDocument),
					variables: {
						input: document,
					},
				}));

				return Promise.all(promises);
			});
		});
	}

	render() {
		const { owner } = this.props;

		const testResult = {
			owner,
			documents: {
				items: [
					{
						id: uuid.default(),
					},
				],
			},
		};

		return (
			<TestResultForm submit={this.submit} testResult={testResult} />
		);
	}
}

export default withApollo(NewUserTestResult);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageHeader = props => (
	<Header>
		<h3 data-testid="page-title">{props.title}</h3>
	</Header>
);

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
};

const Header = styled.div`
	height: 75px;
	color: #676767;
	display: flex;
	justify-content: space-between;
	align-items: middle;
`;

export default PageHeader;

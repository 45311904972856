import React from 'react';

const Footer = () => (
	<footer className="footer">
		<div className="container-fluid">
			<div>
				<small>
					&copy; {new Date().getFullYear()} CANAPI : Community AIDS Network/Akron Pride Initiative
				</small>
			</div>
		</div>
	</footer>
);

export default Footer;

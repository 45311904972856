import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { Query, withApollo, compose } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

import Errors from './Errors';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import Loading from '../Loading';

class TouchForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		match: PropTypes.shape().isRequired,
		touch: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			touchType,
			touchDate,
			owner,
			incentivized,
			comments,
		} = this.props.touch;

		this.state = {
			id: id || '',
			touchTouchTypeId: touchType ? touchType.id : '',
			touchDate: touchDate ? moment.utc(touchDate).local().format('YYYY-MM-DDTHH:mm') : '',
			comments: comments || '',
			incentivized: incentivized || false,
			submitted: false,
			owner,
			wasIncentivized: incentivized,
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteTouch),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push(`/users/${this.props.match.params.id}/touches`);
		});
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				type,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate(e) {
		e.preventDefault();
		const { match } = this.props;
		const userId = match.params.id;

		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				touchTouchTypeId,
				touchDate,
				comments,
				owner,
				incentivized,
				wasIncentivized,
			} = this.state;

			const touch = {
				id,
				touchUserId: userId,
				touchTouchTypeId,
				touchDate: moment(touchDate).toISOString(),
				comments: comments || null,
				incentivized,
			};

			if (!id) {
				touch.owner = owner;
			}

			this.setState({
				submitted: true,
			});

			let adjustment = null;

			if (touch.incentivized && !touch.wasIncentivized) {
				adjustment = 1;
			} else if (!touch.incentivized && wasIncentivized) {
				adjustment = -1;
			}

			this.props.submit({
				touch,
				adjustment,
			}).then(() => {
				this.props.history.push(`/users/${userId}/touches`);
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		const GET_TOUCH_TYPES = gql(queries.metadataByType);

		return (
			<Query query={GET_TOUCH_TYPES} fetchPolicy="no-cache" variables={{ type: 'TOUCH_TYPE', filter: { active: { eq: true } } }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
							<Errors errors={this.state.errors} />
							<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
							{this.props.touch && (
								<Fragment>
									<div className="card mb-3">
										<div className="card-body">
											<div className="row">
												<div className="form-group col-md-4">
													<label htmlFor="touchType">Touch Type</label>
													<select required name="touchTouchTypeId" id="touchType" type="text" value={this.state.touchTouchTypeId} className="form-control" placeholder="Select an option" onChange={this.handleInputChange}>
														<option value="">Select an option</option>
														{data.metadataByType.items.map(option => (
															<option key={option.id} value={option.id}>
																{option.description}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-4">
													<label htmlFor="touchDate">Touchbase Date</label>
													<input name="touchDate" required id="touchDate" type="datetime-local" value={this.state.touchDate} className="form-control" placeholder="MM/DD/YYYY" onChange={this.handleInputChange} />
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-4">
													<div className="form-check">
														<input type="checkbox" name="incentivized" checked={this.state.incentivized} className="form-check-input" id="incentivized" onChange={this.handleInputChange} />
														<label className="form-check-label" htmlFor="incentivized">Count Towards Incentives</label>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-8">
													<label htmlFor="comments">Notes/Comments</label>
													<textarea name="comments" id="comments" value={this.state.comments} className="form-control" rows="3" placeholder="Enter comments" onChange={this.handleInputChange} />
												</div>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
												{this.state.submitted && (
													<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
												)}
												{this.state.submitted ? 'Loading...' : 'Submit'}
											</button>
											<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
														Cancel
											</button>
										</div>
										<div className="col-md-6 text-right">
											{this.state.id && (
												<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
														Delete
												</button>
											)}
										</div>
									</div>
								</Fragment>
							)}
						</form>
					);
				}}
			</Query>
		);
	}
}


export default compose(
	withRouter,
	withApollo,
)(TouchForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as uuid from 'uuid/v1';
import { Delete } from '@material-ui/icons';

import awsConfig from '../../aws-exports';


class FileInput extends Component {
	static propTypes = {
		onFileChange: PropTypes.func.isRequired,
		onRemove: PropTypes.func,
		label: PropTypes.string,
		showRemove: PropTypes.bool,
		document: PropTypes.shape(),
		uid: PropTypes.string,
	};

	static defaultProps = {
		label: 'File Upload',
		showRemove: false,
		document: null,
		uid: null,
		onRemove: Function.prototype,
	};

	constructor(props) {
		super(props);
		this.handleFileChange = this.handleFileChange.bind(this);
	}

	handleFileChange(event) {
		let file;
		const id = event.target.dataset.docid;
		const element = document.querySelector(`.custom-file-label[for='file-${id}`);

		if (event.target.files.length && this.props.onFileChange) {
			const selectedFile = event.target.files[0];
			const { name, type: mimeType } = selectedFile;
			const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name);
			const bucket = awsConfig.aws_user_files_s3_bucket;
			const region = awsConfig.aws_user_files_s3_bucket_region;
			const visibility = 'public';
			const key = `${visibility}/${uuid.default()}${extension && '.'}${extension}`;
			file = {
				id,
				bucket,
				key,
				region,
				mimeType,
				fileName: name,
				localUri: selectedFile,
			};

			element.textContent = name;
			this.props.onFileChange(file);
		} else {
			element.textContent = 'Choose file';
		}
	}

	render() {
		const uid = this.props.uid || this.props.document.id;

		return (
			<div className="form-group">
				{this.props.label && (
					<label htmlFor="file">{this.props.label}</label>
				)}
				<div className="input-group">
					<div className="custom-file">
						<input type="file" data-docid={uid} onChange={this.handleFileChange} required className="custom-file-input" id={`file-${this.props.document.id}`} />
						<label className="custom-file-label" htmlFor={`file-${uid}`}>Choose file</label>
					</div>
					{this.props.showRemove && (
						<div className="input-group-append">
							<button className="btn btn-outline-secondary" type="button" onClick={() => { this.props.onRemove(this.props.document.id) }}>
								<Delete />
							</button>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default FileInput;

import React, { Fragment } from 'react';
import {
	NavLink, Switch, Route,
} from 'react-router-dom';

import ListUsers from './ListUsers';
import Layout from './layout/Layout';

class Users extends React.Component {
	render() {
		return (
			<Layout title="Participants" subtitle="Active and archived iCAN participants">
				<Fragment>
					<ul className="nav nav-pills">
						<li className="nav-item">
							<NavLink data-testid="active-tab" exact activeClassName="active" className="nav-link" to="/users">Active Participants</NavLink>
						</li>
						<li className="nav-item">
							<NavLink data-testid="archived-tab" activeClassName="active" className="nav-link" to="/users/archived">Archived Participants</NavLink>
						</li>
					</ul>
					<div>
						<Switch>
							<Route path="/users" exact render={props => <ListUsers {...props} archived={false} />} />
							<Route path="/users/archived" render={props => <ListUsers {...props} archived={true} />} />
						</Switch>
					</div>
				</Fragment>
			</Layout>
		);
	}
}

export default Users;

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const HelpRequestLink = (cell, row) => (
	<NavLink to={`/helpRequests/${row.id}`}>
		{row.user.name}
	</NavLink>
);

const columns = [
	{
		dataField: 'user.name',
		text: 'Name',
		formatter: HelpRequestLink,
	},
	{
		dataField: 'requestedAt',
		text: 'Request Date',
		formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY'),
	},
	{
		dataField: 'type.description',
		text: 'Request Type',
	},
];

const HelpQueue = ({ helpRequests }) => (
	<BootstrapTable
		keyField="id"
		bordered={false}
		data={helpRequests.sort((h1, h2) => (h1.createdAt < h2.createdAt ? -1 : 1))}
		noDataIndication="There are no help requests to display."
		columns={columns}
		pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
	/>
);

HelpQueue.propTypes = {
	helpRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default HelpQueue;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import moment from 'moment';
import { Query, withApollo } from 'react-apollo';
import { Add, SaveAlt, Edit } from '@material-ui/icons';
import { Storage, Auth } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as queries from '../graphql-custom/queries';

import Loading from './Loading';
import { metadataValues } from '../constants/metadataTypes';

class ListDocuments extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.resetParticipantAgreement = this.resetParticipantAgreement.bind(this);
	}

	getDocument = key => (
		Storage.get(key.replace('public/', ''), {
			level: 'public',
		}).then((data) => {
			window.open(data, '_blank');
		})
	);

	resetParticipantAgreement() {
		const update = gql(`mutation ResetAgreement($input: UpdateUserInput!) {
			updateUser(input: $input) {
				id
				agreementSigned
			}
		}`);

		const userId = this.props.match.params.id;
		this.props.client.mutate({
			mutation: update,
			variables: {
				input: {
					id: userId,
					agreementSigned: false,
				},
			},
		}).then((res) => {
			alert('Agreement flag reset!');
		});
	}

	render() {
		const GET_DOCUMENTS = gql(queries.listUserDocuments);
		const userId = this.props.match.params.id;
		const { otherDocumentType, participantAgreementDocumentType } = metadataValues;
		const columns = [
			{
				dataField: 'name',
				text: 'Document Name',
			},
			{
				dataField: 'createdAt',
				text: 'Upload Date',
				formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY hh:mm A'),
			},
			{
				dataField: 'documentType.description',
				text: 'Document Type',
			},
			{
				dataField: 'df1',
				dummyField: true,
				text: '',
				formatter: (cell, row) => (
					<Fragment>
						<button type="button" onClick={() => this.getDocument(row.file.key)} className="btn btn-light mr-1">
							<SaveAlt />
						</button>
						{[otherDocumentType, participantAgreementDocumentType].indexOf(row.documentType.id) >= 0 && (
							<Link to={`${this.props.match.url}/${row.id}`}>
								<button type="button" className="btn btn-light">
									<Edit />
								</button>
							</Link>
						)}
					</Fragment>
				),
			},
		];

		return (
			<Query query={GET_DOCUMENTS} fetchPolicy="no-cache" variables={{ id: userId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<div className="card mb-3">
							<div className="card-body">
								<Link to={`/users/${userId}/documents/new`} className="btn btn-circle btn-primary mr-1">
									<Add />
								</Link>
								<button type="button" onClick={this.resetParticipantAgreement} className="btn btn-light">
									Reset Participant Agreement Flag
								</button>
								<BootstrapTable
									keyField="id"
									bordered={false}
									data={data.user.documents.items}
									noDataIndication="There are no documents to display."
									columns={columns}
									pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
								/>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default withApollo(ListDocuments);

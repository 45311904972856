import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Query, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import gql from 'graphql-tag';
import { Edit, Add } from '@material-ui/icons';

import Layout from './layout/Layout';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import Loading from './Loading';

class ListCheckInQuestions extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			questions: [],
		};

		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination || result.destination.index === result.source.index) {
			return;
		}

		// no movement
		if (result.destination.index === result.source.index) {
			return;
		}

		this.setState(prevState => ({
			questions: this.reorder(prevState.questions, result.source.index, result.destination.index),
		}), () => {
			const { questions } = this.state;
			const promises = [];

			questions.forEach((question, index) => {
				const input = {
					id: question.id,
					order: index,
				};

				promises.push(this.props.client.mutate({
					mutation: gql(mutations.updateCheckInQuestion),
					variables: {
						input,
					},
				}));
			});

			Promise.all(promises);
		});
	};

	onQuestionsLoaded = (res) => {
		const questions = res.listCheckInQuestions.items.sort((a, b) => (a.order < b.order ? -1 : 1));
		this.setState({ questions });
	};

	reorder = (questions, startIndex, endIndex) => {
		const result = Array.from(questions);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	render() {
		const GET_CHECK_IN_QUESTIONS = gql(queries.listCheckInQuestions);

		return (
			<Layout title="Check In Questions">
				<Card>
					<Card.Body>
						<div className="btn-group mb-4" role="group" aria-label="Basic example">
							<Link to="/admin/checkInQuestions/new" className="btn btn-primary mr-1">
								<Add />
							</Link>
						</div>
						<Query fetchPolicy="no-cache" query={GET_CHECK_IN_QUESTIONS} onCompleted={res => this.onQuestionsLoaded(res)}>
							{({ loading, error }) => {
								if (error) return (<h3>Error!</h3>);
								if (loading) return (<Loading />);

								const { questions } = this.state;

								return (
									<DragDropContext onDragEnd={this.onDragEnd}>
										<Fragment>
											<table className="table align-top" style={{ tableLayout: 'fixed' }}>
												<thead>
													<tr>
														<th>Question</th>
														<th># of Options</th>
														<th>Active</th>
														<th />
													</tr>
												</thead>
												<Droppable droppableId="table">
													{droppableProvided => (
														<tbody
															ref={(ref) => {
																this.tableRef = ref;
																droppableProvided.innerRef(ref);
															}}
															{...droppableProvided.droppableProps}
														>
															{questions.map((question, index) => (
																<Draggable
																	draggableId={question.id}
																	index={index}
																	key={question.id}
																>
																	{(
																		provided,
																		snapshot,
																	) => (
																		<TableRow
																			provided={provided}
																			snapshot={snapshot}
																			question={question}
																		/>
																	)}
																</Draggable>
															))}
															{droppableProvided.placeholder}
														</tbody>
													)}
												</Droppable>
											</table>
										</Fragment>
									</DragDropContext>
								);
							}}
						</Query>
					</Card.Body>
				</Card>
			</Layout>
		);
	}
}

class TableRow extends Component {
	static propTypes = {
		question: PropTypes.shape().isRequired,
		provided: PropTypes.shape().isRequired,
	};

	render() {
		const { question, provided } = this.props;
		const {
			id,
			active,
			text,
			options,
		} = question;

		return (
			<tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
				<td>{text}</td>
				<td>{options.length}</td>
				<td>{active ? 'Yes' : 'No'}</td>
				<td className="text-right">
					<Link to={`checkInQuestions/${id}`} className="btn btn-light">
						<Edit />
					</Link>
				</td>
			</tr>
		);
	}
}

export default withApollo(ListCheckInQuestions);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import TouchForm from './forms/TouchForm';
import * as mutations from '../graphql/mutations';
import { adjustTotalTouchbases } from '../graphql-custom/mutations';

class NewUserTouch extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		owner: PropTypes.string.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
	}

	submit = ({ touch }) => {
		const { client } = this.props;

		return client.mutate({
			mutation: gql(mutations.createTouch),
			variables: {
				input: touch,
			},
		}).then(() => {
			if (touch.incentivized) {
				return client.mutate({
					mutation: gql(adjustTotalTouchbases),
					variables: {
						userId: touch.touchUserId,
						amount: 1,
					},
				});
			}
			return Promise.resolve();
		});
	};

	render() {
		const { owner } = this.props;

		const touch = {
			touchUserId: this.props.match.params.id,
			owner,
		};

		return (
			<TouchForm submit={this.submit} touch={touch} />
		);
	}
}

export default withApollo(NewUserTouch);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { API, Auth } from 'aws-amplify';
import { Query, withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import Layout from './layout/Layout';
import * as queries from '../graphql-custom/queries';
import { updateMetadata } from '../graphql/mutations';
import Loading from './Loading';

class ListSurveys extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			surveys: null,
			selectedSurveyId: null,
		};
	}

	componentDidMount() {
		API.get('icanrest', '/surveys')
			.then((surveys) => {
				this.setState({
					surveys,
				});
			}).catch((err) => {
				console.error(err);
			});
	}

	async handleSurveyPress(metadataId, survey) {
		const { client } = this.props;

		this.setState({
			selectedSurveyId: survey.id,
		});

		// First make sure the survey has a weblink collector.
		let collector = null;
		try {
			collector = await API.get('icanrest', `/surveys/${survey.id}/collectors`);
		} catch (err) {
			console.log(err);
		}

		if (!collector) {
			alert('Missing Collector - Does this survey have a weblink collector?'); // eslint-disable-line no-alert
			return;
		}

		// Patch the web hook
		try {
			await API.post('icanrest', '/surveys/webhook', {
				headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				body: {
					object_ids: [survey.id],
				},
			});
		} catch (err) {
			alert(err); // eslint-disable-line no-alert
		}

		// Survey monkey is all set - update DynamoDB
		try {
			await client.mutate({
				mutation: gql(updateMetadata),
				variables: {
					input: {
						id: metadataId,
						data: collector.url,
						subData: survey.id,
					},
				},
			});
		} catch (err) {
			alert(err); // eslint-disable-line no-alert
		}
	}

	render() {
		if (!this.state.surveys) { return (null) }

		const SURVEY_METADATA = gql(queries.survey);

		return (
			<Layout title="Surveys">
				<Query
					query={SURVEY_METADATA}
					onCompleted={res => this.setState({ selectedSurveyId: res.survey.items[0].subData })}
				>
					{({ loading, error, data }) => {
						if (error) return (<h3 data-testid="error">{JSON.stringify(error)}</h3>);
						if (loading) return (<Loading />);

						const {
							id: metadataId,
						} = data.survey.items[0];

						return (
							<div className="card mb-3">
								<div className="card-body">
									<table className="table table-sm">
										<thead>
											<tr>
												<th>Active</th>
												<th>Survey Name</th>
											</tr>
										</thead>
										<tbody>
											{this.state.surveys.map(survey => (
												<tr key={survey.id}>
													<td>
														<input
															type="radio"
															name="survey"
															checked={this.state.selectedSurveyId === survey.id}
															onChange={() => this.handleSurveyPress(metadataId, survey)}
														/>
													</td>
													<td>{survey.title}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						);
					}}
				</Query>
			</Layout>
		);
	}
}

export default withApollo(ListSurveys);

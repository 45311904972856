import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { CardDeck, Card, ListGroup } from 'react-bootstrap';

import metadataTypes from '../constants/metadataTypes';
import Layout from './layout/Layout';


class ListAdminPages extends Component {
	render() {
		const sortedTypes = Object.keys(metadataTypes).sort((a, b) => (a > b ? 1 : -1));

		return (
			<Layout title="Admin Pages">
				<CardDeck>
					<Card>
						<Card.Header>Metadata</Card.Header>
						<ListGroup variant="flush">
							{sortedTypes.map(type => (
								<ListGroup.Item key={type}>
									<NavLink to={`/admin/metadata?type=${type.toLowerCase()}`}>
										{metadataTypes[type].plural}
									</NavLink>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Card>
					<Card>
						<Card.Header>Other Admin Pages</Card.Header>
						<ListGroup.Item>
							<NavLink to="/admin/checkInQuestions">
								Check-In Questions
							</NavLink>
						</ListGroup.Item>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<NavLink to="/admin/employees">
									Employees
								</NavLink>
							</ListGroup.Item>
							<ListGroup.Item>
								<NavLink to="/admin/surveys">
									Surveys
								</NavLink>
							</ListGroup.Item>
							<ListGroup.Item>
								<NavLink to="/admin/metadata?type=system_setting">
									System Settings
								</NavLink>
							</ListGroup.Item>
						</ListGroup>
					</Card>
				</CardDeck>
			</Layout>
		);
	}
}

export default ListAdminPages;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCheckIn = /* GraphQL */ `
  query GetCheckIn($owner: String!, $checkInDate: AWSDate!) {
    getCheckIn(owner: $owner, checkInDate: $checkInDate) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      answers {
        items {
          id
          answer
        }
        nextToken
      }
      comments
      shared
      moodScale
      owner
      checkInDate
      createdAt
      updatedAt
    }
  }
`;
export const listCheckIns = /* GraphQL */ `
  query ListCheckIns(
    $owner: String
    $checkInDate: ModelStringKeyConditionInput
    $filter: ModelCheckInFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCheckIns(
      owner: $owner
      checkInDate: $checkInDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        answers {
          nextToken
        }
        comments
        shared
        moodScale
        owner
        checkInDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckInQuestion = /* GraphQL */ `
  query GetCheckInQuestion($id: ID!) {
    getCheckInQuestion(id: $id) {
      id
      text
      options
      active
      order
      checkInAnswers {
        items {
          id
          answer
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCheckInQuestions = /* GraphQL */ `
  query ListCheckInQuestions(
    $filter: ModelCheckInQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckInQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        options
        active
        order
        checkInAnswers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmployee = /* GraphQL */ `
  query GetEmployee($id: ID!) {
    getEmployee(id: $id) {
      id
      cognitoUserId
      email
      role
      firstName
      phoneNumber
      lastName
      active
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $filter: ModelEmployeeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        email
        role
        firstName
        phoneNumber
        lastName
        active
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUserId
      legalFirstName
      legalLastName
      legalMiddleInitial
      name
      searchName
      dateOfBirth
      email
      ryanWhiteNumber
      censusTractNumber
      householdPoverty
      householdPovertyPercentage
      address
      phoneNumber
      alternatePhoneNumber
      race {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      disability {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      birthGender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      gender {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchFrequency {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      preferredContactMethod {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      archived
      agreementSigned
      testResults {
        items {
          id
          range
          viralLoad
          testDate
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      touches {
        items {
          id
          touchTouchTypeId
          touchDate
          comments
          owner
          incentivized
          createdAt
          updatedAt
        }
        nextToken
      }
      incentives {
        items {
          id
          givenDate
          value
          comments
          totalCheckIns
          totalTouchbases
          totalSurveys
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      medications {
        items {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      reminders {
        items {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      appointments {
        items {
          id
          date
          name
          owner
          reminderBuffer
          createdAt
          updatedAt
        }
        nextToken
      }
      checkIns {
        items {
          id
          comments
          shared
          moodScale
          owner
          checkInDate
          createdAt
          updatedAt
        }
        nextToken
      }
      helpRequests {
        items {
          id
          closed
          requestComments
          internalComments
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      lastActiveAt
      virallySuppressed
      county {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      checkInsGoal
      touchbasesGoal
      surveysGoal
      totalCheckIns
      totalTouchbases
      totalSurveys
      otherBirthGender
      otherGender
      transgender
      mentalIllness
      archivedReason {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      partners {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      condomUsage
      prep
      sexualOrientation {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      otherSexualOrientation
      referredBy {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      referredByOther
      enrollmentDate
      lastSurveyId
      snoozeBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      appointmentReminderBuffer {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      termsAcceptedOn
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      nextToken
    }
  }
`;
export const getMetadata = /* GraphQL */ `
  query GetMetadata($id: ID!) {
    getMetadata(id: $id) {
      id
      type
      description
      data
      subData
      markdown
      active
      required
      manualEntry
    }
  }
`;
export const listMetadatas = /* GraphQL */ `
  query ListMetadatas(
    $filter: ModelMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMetadatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      nextToken
    }
  }
`;
export const getTestResult = /* GraphQL */ `
  query GetTestResult($id: ID!) {
    getTestResult(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      range
      viralLoad
      testDate
      comments
      documents {
        items {
          id
          name
          comments
          createdAt
          updatedAt
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listTestResults = /* GraphQL */ `
  query ListTestResults(
    $filter: ModelTestResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        range
        viralLoad
        testDate
        comments
        documents {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTouch = /* GraphQL */ `
  query GetTouch($id: ID!) {
    getTouch(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      touchType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      touchTouchTypeId
      touchDate
      comments
      owner
      incentivized
      createdAt
      updatedAt
    }
  }
`;
export const listTouchs = /* GraphQL */ `
  query ListTouchs(
    $filter: ModelTouchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTouchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        touchType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchTouchTypeId
        touchDate
        comments
        owner
        incentivized
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIncentive = /* GraphQL */ `
  query GetIncentive($id: ID!) {
    getIncentive(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      givenDate
      value
      comments
      document {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        name
        documentType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        comments
        file {
          bucket
          region
          key
          fileName
        }
        createdAt
        updatedAt
      }
      totalCheckIns
      totalTouchbases
      totalSurveys
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listIncentives = /* GraphQL */ `
  query ListIncentives(
    $filter: ModelIncentiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIncentives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        type {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        givenDate
        value
        comments
        document {
          id
          name
          comments
          createdAt
          updatedAt
        }
        totalCheckIns
        totalTouchbases
        totalSurveys
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      resourceType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      resourceResourceTypeId
      title
      url
      image
      description
      order
      runFrom
      runUntil
      isEvent
      eventStartDate
      eventEndDate
      createdAt
      updatedAt
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resourceType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        resourceResourceTypeId
        title
        url
        image
        description
        order
        runFrom
        runUntil
        isEvent
        eventStartDate
        eventEndDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHelpRequest = /* GraphQL */ `
  query GetHelpRequest($id: ID!) {
    getHelpRequest(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      closed
      type {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      requestComments
      internalComments
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listHelpRequests = /* GraphQL */ `
  query ListHelpRequests(
    $filter: ModelHelpRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHelpRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        closed
        type {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        requestComments
        internalComments
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      name
      documentType {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      comments
      file {
        bucket
        region
        key
        fileName
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        name
        documentType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        comments
        file {
          bucket
          region
          key
          fileName
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedication = /* GraphQL */ `
  query GetMedication($id: ID!) {
    getMedication(id: $id) {
      id
      name
      dosage
      shared
      active
      comments
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      reminders {
        items {
          id
        }
        nextToken
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMedications = /* GraphQL */ `
  query ListMedications(
    $filter: ModelMedicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicationReminder = /* GraphQL */ `
  query GetMedicationReminder($id: ID!) {
    getMedicationReminder(id: $id) {
      id
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
    }
  }
`;
export const listMedicationReminders = /* GraphQL */ `
  query ListMedicationReminders(
    $filter: ModelMedicationReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicationReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medication {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        reminder {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      date
      name
      owner
      reminderBuffer
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        date
        name
        owner
        reminderBuffer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query GetReminder($id: ID!) {
    getReminder(id: $id) {
      id
      active
      time
      message
      notificationSound
      medications {
        items {
          id
        }
        nextToken
      }
      instances {
        items {
          id
          medicationId
          reminderId
          userId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listReminders = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMedicationInstance = /* GraphQL */ `
  query GetMedicationInstance($id: ID!) {
    getMedicationInstance(id: $id) {
      id
      medicationId
      reminderId
      userId
      medication {
        id
        name
        dosage
        shared
        active
        comments
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        reminders {
          nextToken
        }
        owner
        createdAt
        updatedAt
      }
      reminder {
        id
        active
        time
        message
        notificationSound
        medications {
          nextToken
        }
        instances {
          nextToken
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      user {
        id
        cognitoUserId
        legalFirstName
        legalLastName
        legalMiddleInitial
        name
        searchName
        dateOfBirth
        email
        ryanWhiteNumber
        censusTractNumber
        householdPoverty
        householdPovertyPercentage
        address
        phoneNumber
        alternatePhoneNumber
        race {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        disability {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        birthGender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        gender {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchFrequency {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        preferredContactMethod {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        archived
        agreementSigned
        testResults {
          nextToken
        }
        touches {
          nextToken
        }
        incentives {
          nextToken
        }
        documents {
          nextToken
        }
        medications {
          nextToken
        }
        reminders {
          nextToken
        }
        appointments {
          nextToken
        }
        checkIns {
          nextToken
        }
        helpRequests {
          nextToken
        }
        instances {
          nextToken
        }
        createdAt
        updatedAt
        lastActiveAt
        virallySuppressed
        county {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        checkInsGoal
        touchbasesGoal
        surveysGoal
        totalCheckIns
        totalTouchbases
        totalSurveys
        otherBirthGender
        otherGender
        transgender
        mentalIllness
        archivedReason {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        partners {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        condomUsage
        prep
        sexualOrientation {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        otherSexualOrientation
        referredBy {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        referredByOther
        enrollmentDate
        lastSurveyId
        snoozeBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        appointmentReminderBuffer {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        termsAcceptedOn
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMedicationInstances = /* GraphQL */ `
  query ListMedicationInstances(
    $filter: ModelMedicationInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMedicationInstances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medicationId
        reminderId
        userId
        medication {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        reminder {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const metadataByType = /* GraphQL */ `
  query MetadataByType(
    $type: MetadataType
    $description: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    metadataByType(
      type: $type
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        data
        subData
        markdown
        active
        required
        manualEntry
      }
      nextToken
    }
  }
`;
export const touchesByType = /* GraphQL */ `
  query TouchesByType(
    $touchTouchTypeId: ID
    $touchDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTouchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    touchesByType(
      touchTouchTypeId: $touchTouchTypeId
      touchDate: $touchDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        touchType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        touchTouchTypeId
        touchDate
        comments
        owner
        incentivized
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resourcesByType = /* GraphQL */ `
  query ResourcesByType(
    $resourceResourceTypeId: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesByType(
      resourceResourceTypeId: $resourceResourceTypeId
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resourceType {
          id
          type
          description
          data
          subData
          markdown
          active
          required
          manualEntry
        }
        resourceResourceTypeId
        title
        url
        image
        description
        order
        runFrom
        runUntil
        isEvent
        eventStartDate
        eventEndDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const instancesByMedication = /* GraphQL */ `
  query InstancesByMedication(
    $medicationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicationInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instancesByMedication(
      medicationId: $medicationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medicationId
        reminderId
        userId
        medication {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        reminder {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const instancesByReminder = /* GraphQL */ `
  query InstancesByReminder(
    $reminderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMedicationInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    instancesByReminder(
      reminderId: $reminderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        medicationId
        reminderId
        userId
        medication {
          id
          name
          dosage
          shared
          active
          comments
          owner
          createdAt
          updatedAt
        }
        reminder {
          id
          active
          time
          message
          notificationSound
          owner
          createdAt
          updatedAt
        }
        user {
          id
          cognitoUserId
          legalFirstName
          legalLastName
          legalMiddleInitial
          name
          searchName
          dateOfBirth
          email
          ryanWhiteNumber
          censusTractNumber
          householdPoverty
          householdPovertyPercentage
          address
          phoneNumber
          alternatePhoneNumber
          archived
          agreementSigned
          createdAt
          updatedAt
          lastActiveAt
          virallySuppressed
          checkInsGoal
          touchbasesGoal
          surveysGoal
          totalCheckIns
          totalTouchbases
          totalSurveys
          otherBirthGender
          otherGender
          transgender
          mentalIllness
          condomUsage
          prep
          otherSexualOrientation
          referredByOther
          enrollmentDate
          lastSurveyId
          termsAcceptedOn
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

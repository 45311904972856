import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Error } from '@material-ui/icons';

import Subheader from './Subheader';


const Layout = props => (
	<Main className="container-fluid">
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
			<Subheader title={props.title} />
			{props.showTouchbaseWarning && (
				<div className="text-danger" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
					<Error className="ml-1" style={{ fontSize: '35px', position: 'relative', top: '-7px' }} />
					<h4>
						This participant is past due for touchbase!
					</h4>
				</div>
			)}
		</div>
		<section>
			{props.children}
		</section>
	</Main>
);

Layout.propTypes = {
	title: PropTypes.string.isRequired,
	showTouchbaseWarning: PropTypes.bool,
	children: PropTypes.shape().isRequired,
};

Layout.defaultProps = {
	showTouchbaseWarning: false,
};

const Main = styled.main`
	grid-area: main;
	padding: 30px 15px;
`;

export default Layout;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';

import * as queries from '../graphql-custom/queries';
import * as mutations from '../graphql/mutations';
import UserTestResultForm from './forms/TestResultForm';
import { metadataValues } from '../constants/metadataTypes';
import Loading from './Loading';

class EditUserTestResult extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);
	}

	submit(testResult) {
		// First save the test result information (date, viral load, comments)
		const { documents, ...input } = testResult;

		return this.props.client.mutate({
			mutation: gql(mutations.updateTestResult),
			variables: {
				input,
			},
		}).then(() => {
			let deleteGql = '';
			let insertGql = '';

			const insertDocuments = {};
			const deleteDocuments = {};

			documents.forEach((f, index) => {
				const { id, deleted, ...document } = f;
				const param = `document${index}`;

				if (deleted) {
					deleteDocuments[param] = {
						id,
					};
					deleteGql += `${param}: deleteDocument(input: $${param}) { id }
					`;
				} else if (!document.file) {
					insertDocuments[param] = {
						name: document.fileName,
						documentDocumentTypeId: metadataValues.testResultDocumentTypeId,
						file: document,
						documentUserId: testResult.testResultUserId,
						testResultDocumentsId: testResult.id,
					};
					insertGql += `${param}: createDocument(input: $${param}) { id }
					`;
				}
			});

			if (deleteGql.length || insertGql.length) {
				const params = Object.keys(insertDocuments).map(k => `$${k}: CreateDocumentInput!`)
					.concat(Object.keys(deleteDocuments).map(k => `$${k}: DeleteDocumentInput!`));


				const mutation = `mutation TestResultDocuments(${params.join(', ')}) {
					${deleteGql}${insertGql}
				}`;

				return this.props.client.mutate({
					mutation: gql(mutation),
					variables: { ...insertDocuments, ...deleteDocuments },
				});
			}

			return true;
		});
	}

	render() {
		const { match } = this.props;
		const GET_TEST_RESULT = gql(queries.getTestResult);

		return (
			<Query query={GET_TEST_RESULT} fetchPolicy="no-cache" variables={{ id: match.params.testResultId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<UserTestResultForm submit={this.submit} testResult={data.getTestResult} />
					);
				}}
			</Query>
		);
	}
}

export default withApollo(EditUserTestResult);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import * as generator from 'generate-password-browser';

import Errors from './Errors';

class EmployeeForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		employee: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			active,
			firstName,
			lastName,
			phoneNumber,
			role,
		} = this.props.employee;

		this.state = {
			id: id || '',
			email: '',
			password: '',
			firstName: firstName || '',
			lastName: lastName || '',
			phoneNumber: phoneNumber || '',
			active: active === undefined ? true : active,
			role: role || '',
			submitted: false,
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.generatePassword = this.generatePassword.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleInputChange(event) {
		const {
			target: {
				checked,
				value,
				name,
				type,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate(e) {
		e.preventDefault();

		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				email,
				firstName,
				lastName,
				role,
				phoneNumber,
				password,
				active,
			} = this.state;

			const employee = {
				id,
				role,
				phoneNumber,
				firstName,
				lastName,
				active,
			};

			if (!id) {
				employee.email = email;
				employee.password = password;
			}

			this.setState({
				submitted: true,
			});

			this.props.submit(employee).then((res) => {
				if (res) {
					this.props.history.push('/admin/employees');
				}
				this.setState({
					submitted: false,
				});
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	generatePassword() {
		const password = `${generator.generate({
			length: 10,
			numbers: true,
			uppercase: true,
			symbols: false,
			strict: true,
		})}!`;

		this.setState({
			password,
		});
	}

	render() {
		return (
			<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
				<Errors errors={this.state.errors} />
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				{this.props.employee && (
					<div className="card mb-3">
						<div className="card-body">
							{!this.state.id && (
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="email">Email Address</label>
										<input name="email" id="email" type="email" value={this.state.email} className="form-control" placeholder="Email Address" onChange={this.handleInputChange} required />
									</div>
									<div className="form-group col-md-4">
										<label htmlFor="password">Temporary Password</label>
										<div className="input-group">
											<input name="password" id="password" type="text" autoComplete="new-password" value={this.state.password} className="form-control" placeholder="Enter temporary password" onChange={this.handleInputChange} maxLength="50" required />
											<div className="input-group-append">
												<button data-testid="generate-password-btn" className="btn btn-outline-secondary" onClick={this.generatePassword} type="button">Generate</button>
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="row">
								<div className="form-group col-md-3">
									<label htmlFor="firstName">First Name</label>
									<input name="firstName" required id="firstName" type="text" value={this.state.firstName} className="form-control" placeholder="First Name" onChange={this.handleInputChange} />
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="lastName">Last Name</label>
									<input name="lastName" required id="lastName" type="text" value={this.state.lastName} className="form-control" placeholder="Last Name" onChange={this.handleInputChange} />
								</div>
								<div className="form-group col-md-3">
									<label htmlFor="phone">Phone Number</label>
									<input pattern="\d{3}[\-]\d{3}[\-]\d{4}" name="phoneNumber" id="phone" type="tel" value={this.state.phoneNumber} className="form-control" placeholder="Phone Number" onChange={this.handleInputChange} required />
									<small className="form-text text-muted">
										xxx-xxx-xxxx
									</small>
								</div>
							</div>
							<div className="row">
								<div id="roles" className="form-group col-md-8">
									<label htmlFor="roles">Role</label>
									<div className="custom-control custom-radio">
										<input type="radio" className="custom-control-input" id="role-general" checked={this.state.role === 'GENERAL_USER'} name="role" value="GENERAL_USER" onChange={this.handleInputChange} required />
										<label className="custom-control-label" htmlFor="role-general">General User</label>
									</div>
									<div className="custom-control custom-radio">
										<input type="radio" className="custom-control-input" id="role-admin" checked={this.state.role === 'ADMINISTRATOR'} name="role" value="ADMINISTRATOR" onChange={this.handleInputChange} required />
										<label className="custom-control-label" htmlFor="role-admin">Administrator</label>
									</div>
								</div>
							</div>
							{this.state.id.length > 0 && (
								<div className="row">
									<div className="form-group col-md-4">
										<div className="custom-control custom-checkbox">
											<input className="custom-control-input" type="checkbox" name="active" checked={this.state.active} id="active" onChange={this.handleInputChange} />
											<label className="custom-control-label" htmlFor="active">Active</label>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
				<div className="row">
					<div className="col-md-6">
						<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
							{this.state.submitted && (
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
							)}
							{this.state.submitted ? 'Loading...' : 'Submit'}
						</button>
						<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
									Cancel
						</button>
					</div>
				</div>
			</form>
		);
	}
}

export default withRouter(EmployeeForm);

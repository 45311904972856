import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { SaveAlt } from '@material-ui/icons';
import { CSVLink } from 'react-csv';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as queries from '../graphql-custom/queries';
import Loading from './Loading';

const headers = [
	{ label: 'Medication', key: 'name' },
	{ label: 'Added On', key: 'createdAt' },
	{ label: 'Removed On', key: 'removedOn' },
];

class ListMedications extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const GET_MEDICATIONS = gql(queries.listUserMedications);

		const columns = [
			{
				dataField: 'name',
				text: 'Name',
			},
			{
				dataField: 'active',
				text: 'Active',
				formatter: (cell, row) => (row.active === false ? 'No' : 'Yes'),
			},
			{
				dataField: 'createdAt',
				text: 'Added On',
				formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY hh:mm A'),
			},
			{
				dataField: 'updatedAt',
				text: 'Removed On',
				formatter: (cell, row) => (row.active === false ? moment(row.updatedAt).format('MM/DD/YYYY h:mm A') : ''),
			},
			{
				dataField: 'df1',
				dummyField: true,
				text: '',
				align: 'right',
				formatter: (cell, row) => (
					<Link to={`${this.props.match.url}/${row.id}`}>
						<button type="button" className="btn btn-light">
							History
						</button>
					</Link>
				),
			},
		];


		return (
			<Query fetchPolicy="no-cache" query={GET_MEDICATIONS} variables={{ id: this.props.match.params.id }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					const medications = data.user.medications.items
						.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

					const csvData = medications.map(m => ({
						name: m.name,
						createdAt: moment(m.createdAt).format('MM/DD/YYYY'),
						removedOn: m.active === false ? moment(m.updatedAt).format('MM/DD/YYYY hh:mm A') : '',
					}));

					return (
						<div className="card mb-3">
							<div className="card-body">
								<div className="btn-group mb-3" role="group" aria-label="Basic example">
									<CSVLink headers={headers} data={csvData} filename="medications.csv" className="btn btn-info" target="_blank">
										<SaveAlt />
									</CSVLink>
								</div>
								<BootstrapTable
									keyField="id"
									bordered={false}
									data={medications}
									noDataIndication="There are no medications to display."
									columns={columns}
									pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
								/>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default ListMedications;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import gql from 'graphql-tag';
import { Add, SaveAlt } from '@material-ui/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';

import * as queries from '../graphql-custom/queries';
import * as mutations from '../graphql-custom/mutations';
import Loading from './Loading';

const headers = [
	{ label: 'Entry Date', key: 'entryDate' },
	{ label: 'Touch Date', key: 'touchDate' },
	{ label: 'Type', key: 'type' },
	{ label: 'Incentivized', key: 'incentivized' },
	{ label: 'Is Help Request', key: 'isHelpRequest' },
	{ label: 'Resolved', key: 'resolved' },
];

class ListTouches extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			touchFrequencyId: '',
		};
	}

	render() {
		const GET_TOUCHES = gql(queries.listUserTouches);
		const UPDATE_TOUCH_FREQUENCY = gql(mutations.updateTouchFrequency);
		const userId = this.props.match.params.id;

		const columns = [
			{
				dataField: 'createdAt',
				text: 'Entry Date',
				formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY hh:mm A'),
			},
			{
				dataField: 'touchDate',
				text: 'Touchbase Date',
				formatter: (cell, row) => moment(row.touchDate).format('MM/DD/YYYY hh:mm A'),
			},
			{
				dataField: 'description',
				text: 'Type',
				formatter: (cell, row) => (
					<span>{`${row.helpRequestId ? 'Help Request - ' : ''}${row.description}`}</span>
				),
			},
			{
				dataField: 'incentivized',
				text: 'Count Towards Incentive',
				formatter: (cell, row) => (row.incentivized ? 'Yes' : 'No'),
			},
			{
				dataField: 'df2',
				dummyField: true,
				text: 'Resolved',
				formatter: (cell, row) => (
					<Fragment>
						{row.helpRequestId && (
							<span>
								{row.closed ? 'Yes' : 'No'}
							</span>
						)}
						{!row.helpRequestId && (
							<span>n/a</span>
						)}
					</Fragment>
				),
			},
			{
				dataField: 'df1',
				dummyField: true,
				text: '',
				align: 'right',
				formatter: (cell, row) => (
					<Fragment>
						{row.helpRequestId && (
							<Link to={`/helpRequests/${row.helpRequestId}`}>
								<button type="button" className="btn btn-light">
									Edit
								</button>
							</Link>
						)}
						{!row.helpRequestId && (
							<Link to={`${this.props.match.url}/${row.id}`}>
								<button type="button" className="btn btn-light">
									Edit
								</button>
							</Link>
						)}
					</Fragment>
				),
			},
		];

		return (
			<Query fetchPolicy="no-cache" query={GET_TOUCHES} variables={{ id: userId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					const touches = [
						...data.user.touches.items.map(t => ({
							id: t.id,
							incentivized: t.incentivized,
							createdAt: t.createdAt,
							helpRequestId: null,
							touchDate: t.touchDate,
							description: t.touchType ? t.touchType.description : '',
							comments: t.comments,
						})),
						...data.user.helpRequests.items.map(h => ({
							createdAt: h.createdAt,
							helpRequestId: h.id,
							closed: h.closed,
							touchDate: h.createdAt,
							description: h.type ? h.type.description : '',
							comments: h.requestComments,
						})),
					].sort((a, b) => {
						const aDate = a.touchDate || a.createdAt;
						const bDate = b.touchDate || b.createdAt;

						return moment(aDate).isAfter(moment(bDate)) ? -1 : 1;
					});

					const csvData = touches.map((t) => {
						const record = {
							entryDate: moment(t.createdAt).format('MM/DD/YYYY'),
							touchDate: moment(t.touchDate).format('MM/DD/YYYY'),
							type: t.description,
							incentivized: t.incentivized ? 'Yes' : 'No',
							isHelpRequest: t.helpRequestId ? 'Yes' : 'No',
						};

						let resolved = 'n/a';
						if (record.isHelpRequest) {
							resolved = t.closed ? 'Yes' : 'No';
						}

						record.resolved = resolved;
						return record;
					});

					return (
						<Fragment>
							<div className="card mb-3">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<div className="btn-group mb-3">
												<Link to={`/users/${userId}/touches/new`} className="btn btn-primary mr-1">
													<Add />
												</Link>
												<CSVLink headers={headers} data={csvData} filename="touches.csv" className="btn btn-info" target="_blank">
													<SaveAlt />
												</CSVLink>
											</div>
										</div>
										<div className="col-md-6 text-right">
											<Mutation mutation={UPDATE_TOUCH_FREQUENCY}>
												{updateTouchFrequency => (
													<form className="form-inline float-right" onSubmit={e => e.preventDefault()}>
														<label htmlFor="touchFrequencyId" className="mr-2">Touchbase Frequency:</label>
														<select name="touchFrequencyId" id="touchFrequencyId" style={{ width: '250px' }} value={this.state.touchFrequencyId || (data.user.touchFrequency ? data.user.touchFrequency.id : '')} onChange={(e) => { updateTouchFrequency({ variables: { input: { id: this.props.match.params.id, userTouchFrequencyId: e.target.value } } }); this.setState({ touchFrequencyId: e.target.value }) }} className="form-control">
															{data.touchFrequencies.items.map(option => (
																<option key={option.id} value={option.id}>
																	Touchbase {option.description}
																</option>
															))}
														</select>
													</form>
												)}
											</Mutation>
										</div>
									</div>
									<BootstrapTable
										keyField="id"
										bordered={false}
										data={touches}
										noDataIndication="There are no touches to display."
										columns={columns}
										pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
									/>
								</div>
							</div>
						</Fragment>
					);
				}}
			</Query>
		);
	}
}

export default ListTouches;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PersonPin, TrendingDown, TouchApp } from '@material-ui/icons';
import CountUp from 'react-countup';
import moment from 'moment';

const GrantSuccesses = ({ users }) => {
	if (users.length === 0) return (<h3 data-testid="no-data">No Data</h3>);

	const suppressed = users.filter(u => u.testResults.items.length > 0
		&& u.testResults.items[0].viralLoad < 200).length;

	const active = users.filter(u => u.lastActiveAt
		&& moment(u.lastActiveAt).isAfter(moment().add(-7, 'days'))).length;

	let summitCount = 0;
	let portageCount = 0;

	users.forEach((u) => {
		const userCounty = u.county ? u.county.description : '';
		if (userCounty) {
			if (userCounty === 'Summit County') summitCount += 1;
			else if (userCounty === 'Portage County') portageCount += 1;
		}
	});

	return (
		<Fragment>
			<div className="card-deck">
				<div className="card text-white mb-3" style={{ backgroundColor: '#2196F3' }}>
					<div className="card-body">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<div>
								<h2>
									<CountUp end={suppressed} /> / {users.length}
								</h2>
								<p className="card-text">Virally Suppressed Participants</p>
							</div>
							<TrendingDown style={{ fontSize: '4rem' }} />
						</div>
					</div>
				</div>
				<div className="card text-white mb-3" style={{ backgroundColor: '#ffc721' }}>
					<div className="card-body">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<div>
								<h2>
									<CountUp end={active} />
								</h2>
								<p className="card-text">Active Participants (This Week)</p>
							</div>
							<TouchApp style={{ fontSize: '4rem' }} />
						</div>
					</div>
				</div>
				<div className="card text-white mb-3" style={{ backgroundColor: '#d066e2' }}>
					<div className="card-body">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<div>
								<h2>
									<CountUp end={summitCount} />
								</h2>
								<p className="card-text">Participants in Summit County</p>
							</div>
							<PersonPin style={{ fontSize: '4rem' }} />
						</div>
					</div>
				</div>
				<div className="card text-white mb-3" style={{ backgroundColor: '#ff6b68' }}>
					<div className="card-body">
						<div className="d-flex flex-row justify-content-between align-items-center">
							<div>
								<h2>
									<CountUp end={portageCount} />
								</h2>
								<p className="card-text">Participants in Portage County</p>
							</div>
							<PersonPin style={{ fontSize: '4rem' }} />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

GrantSuccesses.propTypes = {
	users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GrantSuccesses;

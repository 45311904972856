import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';

import CheckInQuestionForm from './forms/CheckInQuestionForm';
import * as mutations from '../graphql/mutations';
import Layout from './layout/Layout';

class NewCheckInQuestion extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
	}

	submit = (checkInQuestion) => {
		const { client } = this.props;

		return client.mutate({
			mutation: gql(mutations.createCheckInQuestion),
			variables: {
				input: checkInQuestion,
			},
		});
	};

	render() {
		return (
			<Layout title="New Check-In Question">
				<CheckInQuestionForm submit={this.submit} checkInQuestion={{ }} />
			</Layout>
		);
	}
}

export default withApollo(NewCheckInQuestion);

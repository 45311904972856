import React, { Component } from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';

import Layout from './layout/Layout';
import { participantExport } from '../graphql-custom/queries';

class ListReports extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
	};

	handleExportParticipants() {
		const { client } = this.props;
		client.query({
			query: gql(participantExport),
			fetchPolicy: 'no-cache',
		}).then((res) => {
			const users = res.data.users.items;
			const csvData = users.map((u) => {
				const rtn = {};
				const { testResults: { items: testResults } } = u;

				if (testResults.length) {
					const initialTest = testResults[testResults.length - 1];
					const currentTest = testResults[0];
					const suppressionTest = testResults.reverse().find(t => t.viralLoad <= 200);

					rtn.initialTestDate = initialTest.testDate;
					rtn.initialViralLoad = initialTest.viralLoad;
					rtn.currentTestDate = currentTest.testDate;
					rtn.currentViralLoad = currentTest.viralLoad;
					rtn.suppressionTestDate = suppressionTest ? suppressionTest.testDate : null;
					rtn.suppressionTestViralLoad = suppressionTest ? suppressionTest.viralLoad : null;
				}

				return rtn;
			});

			console.log(csvData);
		});
	}

	render() {
		return (
			<Layout title="Reports">
				<Card>
					<ListGroup>
						<ListGroup.Item>
							<Button onClick={() => this.handleExportParticipants()} title="Export">Participant Export</Button>
						</ListGroup.Item>
					</ListGroup>
				</Card>
			</Layout>
		);
	}
}

export default withApollo(ListReports);

import * as queries from '../graphql/queries';

export default queries;

export const employees = `query employees {
	employees: listEmployees {
		items {
			id
			email
			firstName
			lastName
			active
			role
		}
	}
}`;

export const participantExport = `query participantExport {
	users: listUsers(limit: 1000) {
		items {
			id
			name
			testResults(sortDirection:DESC) {
				items {
					id
					viralLoad
					testDate
					createdAt
				}
			}
		}
	}
}`;

export const dashboard = `query dashboard {
	# Pulls user data to group by county and show number of virally suppressed participants.
	users: listUsers(limit: 1000, filter: {
		archived: {
			eq: false
		}
	}) {
		items {
			id
			name
			lastActiveAt
			checkInsGoal
			surveysGoal
			touchbasesGoal
			totalCheckIns
			totalSurveys
			totalTouchbases
			checkIns {
				items {
					id
				}
			}
			touches {
				items {
					id
					incentivized
				}
			}
			county {
				id
				description
			}
			testResults (sortDirection:DESC, limit: 1) {
				items {
					id
					viralLoad
				}
			}
		}
	}
	helpRequests: listHelpRequests(filter:{
    closed: {
      eq:false
    }
	})
	{
		items {
			id
			user {
				id
				name
			}
			createdAt
			type {
				id
				description
			}
		}
	}
	settings: metadataByType(type:SYSTEM_SETTING, description: {
    eq: "PARTICIPANT_INACTIVITY_DAYS_LIMIT"
  }) {
    items {
			id
			data
      description
    }
  }
}`;

export const participantsFull = `query participants($archived: Boolean!) {
	listUsers(limit: 300, filter: {
		archived: {
			eq: $archived
		}
	}) {
		items {
			id
	legalFirstName
	legalLastName
	legalMiddleInitial
	name
	dateOfBirth
	email
	ryanWhiteNumber
	censusTractNumber
	householdPoverty
	householdPovertyPercentage
	address
	phoneNumber
	alternatePhoneNumber
	race {
		id
		description
	}
	disability {
		id
		description
	}
	birthGender {
		id
		description
	}
	gender {
		id
		description
	}
	touchFrequency {
		id
		description
	}
	preferredContactMethod {
		id
		description
	}
	archived
	createdAt
	county {
		id
		description
	}
	totalCheckIns
	totalTouchbases
	totalSurveys
	otherBirthGender
	otherGender
	transgender
	mentalIllness
	archivedReason{
		id
		description
	}
	partners {
		id
		description
	}
	condomUsage
	prep
	sexualOrientation {
		id
		description
	}
	otherSexualOrientation
	referredBy {
		id
		description
	}
	referredByOther
	enrollmentDate
			touchFrequency {
				id
				data
			}
			county {
				id
				description
			}
			testResults (sortDirection:DESC, limit: 50) {
				items {
					id
					testDate
					range
					viralLoad
				}
			}
			touches (sortDirection:DESC, limit: 1) {
				items {
					id
					createdAt
					touchDate
				}
			}
		}
	}
	settings: metadataByType(type:SYSTEM_SETTING, description: {
    eq: "PARTICIPANT_INACTIVITY_DAYS_LIMIT"
  }) {
    items {
			id
			data
      description
    }
  }
}`;

export const participants = `query participants($archived: Boolean!) {
	listUsers(limit: 100, filter: {
		archived: {
			eq: $archived
		}
	}) {
		items {
			id
			legalLastName
			legalFirstName
			legalMiddleInitial
			name
			ryanWhiteNumber
			name
			lastActiveAt
			dateOfBirth
			touchFrequency {
				id
				data
			}
			county {
				id
				description
			}
			testResults (sortDirection:DESC, limit: 1) {
				items {
					id
					viralLoad
					range
				}
			}
			touches (sortDirection:DESC, limit: 1) {
				items {
					id
					createdAt
					touchDate
				}
			}
		}
	}
	settings: metadataByType(type:SYSTEM_SETTING, description: {
    eq: "PARTICIPANT_INACTIVITY_DAYS_LIMIT"
  }) {
    items {
			id
			data
      description
    }
  }
}`;

export const listUserMedications = `query listUserMedications($id: ID!) {
	user: getUser(id: $id) {
		id
		medications(limit: 100, filter: { shared: { eq: true }}) {
			items {
				id
				name
				active
				createdAt
				updatedAt
			}
		}
	}
}`;


export const listUserTestResults = `query listUserTestResults($id: ID!) {
	user: getUser(id: $id) {
	  id,
	  testResults {
		 items {
			id
			createdAt
			testDate
			range
			viralLoad
			documents {
				items {
					id
					file {
						bucket
						fileName
						key
					}
				}
			}
		 }
	  }
	}
}`;

export const listUserIncentives = `query listUserIncentives($id: ID!) {
	user: getUser(id: $id) {
		id
		totalCheckIns
		totalTouchbases
		totalSurveys
		checkInsGoal
		touchbasesGoal
		surveysGoal
		checkIns(limit: 1000) {
			items {
				id
			}
		}
		touches(limit: 1000) {
			items {
				id
				incentivized
			}
		}
		incentives(limit: 1000) {
			items {
				id
				type {
					description
				}
				totalCheckIns
				totalTouchbases
				totalSurveys
				givenDate
				value
				document {
					id
					file {
						bucket
						fileName
						key
					}
				}
				createdAt
			}
		}
	}
}`;

export const listUserTouches = `query listUserTouches($id: ID!) {
	user: getUser(id: $id) {
		id,
		touchFrequency {
			id
		}
		helpRequests
		{
			items {
				id
				user {
					id
					name
				}
				closed
				createdAt
				type {
					id
					description
				}
			}
		}
	  touches {
		 items {
			id,
			comments,
			touchDate,
			incentivized,
			createdAt,
			touchType {
				id,
				description
			}
		 }
	  }
	}
	touchFrequencies: metadataByType(type: TOUCH_FREQUENCY) {
		items {
			id
			description
		}
	}
}`;

export const listUserDocuments = `query listUserDocuments($id: ID!) {
	user: getUser(id: $id) {
	  id,
	  documents(limit: 1000) {
		 items {
			id,
			name,
			createdAt,
			file {
				key
			},
			documentType {
				id,
				description
			}
		 }
	  }
	}
}`;

// Query used to pull metadata for dropdowns on user form
export const metadata = `query metadata{
	genders: metadataByType(type:GENDER, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
			manualEntry
		}
	}
	archivedReasons: metadataByType(type:ARCHIVE_REASON, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	}
	races: metadataByType(type:RACE, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	}
	disabilities: metadataByType(type:DISABILITY, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	}
	contactMethods: metadataByType(type:CONTACT_METHOD, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	}
	counties: metadataByType(type:COUNTY, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	},
	referralTypes: metadataByType(type:REFERRAL_TYPE, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
			manualEntry
		}
	},
	partnerTypes: metadataByType(type:PARTNER_TYPE, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	},
	sexualOrientations: metadataByType(type:SEXUAL_ORIENTATION, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
			manualEntry
		}
	},
	touchFrequencies: metadataByType(type:TOUCH_FREQUENCY, filter:{
		active:{
		  eq: true
		}
	 }) {
		items{
			id
			description
		}
	},
}`;

export const initialGoals = `query metadata {
	initialCheckInsGoal: metadataByType(type: SYSTEM_SETTING, description: {eq: "INITIAL_CHECK_INS_GOAL"}) {
		items {
			id
			data
		}
	}
	initialTouchbasesGoal: metadataByType(type: SYSTEM_SETTING, description: {eq: "INITIAL_TOUCH_BASES_GOAL"}) {
		items {
			id
			data
		}
	}
	initialSurveysGoal: metadataByType(type: SYSTEM_SETTING, description: {eq: "INITIAL_SURVEYS_GOAL"}) {
		items {
			id
			data
		}
	}
}`;

export const survey = `query metadata {
	survey: metadataByType(type: SYSTEM_SETTING, description: {eq: "SURVEY"}) {
		items {
			id
			data
			subData
		}
	}
}`;

// Query used to pull user AND metadata for populating data and dropdowns on edit user form
export const getUser = `query user($id: ID!){
	user: getUser(id: $id) {
		id
		archived
		archivedReason {
			id
			description
		}
		legalFirstName
		legalLastName
		legalMiddleInitial
		name
		email
		dateOfBirth
		ryanWhiteNumber
		householdPoverty
		householdPovertyPercentage
		touchbasesGoal
		checkInsGoal
		surveysGoal
		mentalIllness
		enrollmentDate
		race {
			id
			description
		}
		gender {
			id
			description
			manualEntry
		}
		disability {
			id
			description
		}
		birthGender {
			id
			description
			manualEntry
		}
		sexualOrientation {
			id
			description
			manualEntry
		}
		partners {
			id
			description
		}
		referredBy {
			id
			description
			manualEntry
		}
		touchFrequency {
			id
			description
		 }
		referredByOther
		condomUsage
		transgender
		prep
		otherSexualOrientation
		otherGender
		otherBirthGender
		address
		censusTractNumber
		county {
			id
			description
		}
		phoneNumber
		alternatePhoneNumber
		preferredContactMethod {
			id
			description
		}
		documents {
      items {
        id
        documentType {
          id
          description
				}
				file {
					fileName
					key
				}
        name
      }
    }
	}
}`;

export const listResources = `query resources {
  listResources {
    items {
      id
      title
      description
      image
      url
      createdAt
      startDate
      endDate
      date
      resourceType {
        id
        description
      }
    }
  }
  resourceTypes: metadataByType(type:RESOURCE_TYPE, filder: {
	  active: {
		  eq: true
	  }
  }) {
    items {
      id
      description
    }
  }
}`;

export const listMedicationInstances = `query medicationInstances($medicationId: ID!, $start: String, $end: String) {
	instances: instancesByMedication(medicationId: $medicationId, limit: 1000, createdAt: {
		between: [$start, $end]
	}) {
		items {
			id
			createdAt
		}
	}
}`;

export const listCheckIns = `query checkIns($id: ID!) {
  user: getUser(id: $id) {
	id
    checkIns(limit: 1000) {
			items {
				id
				createdAt
			}
		}
  }
}`;

export const listCheckInsWithDetails = `query checkIns($id: ID!, $start: String, $end: String) {
  getUser(id: $id) {
		id
    checkIns(limit: 1000, createdAt:{
			between: [$start, $end]
		}) {
			items {
				id
				comments
				moodScale
				createdAt
				answers {
					items {
						id
						answer
						checkInQuestion {
							id
							text
						}
					}
				}
			}
		}
  }
}`;

export const getMedication = `query GetMedication($id: ID!) {
	medication: getMedication(id: $id) {
		id
		name
		dosage
	}
}`;

export const getTestResult = `query GetTestResult($id: ID!) {
  getTestResult(id: $id) {
    id
    user {
      id
	 }
	 range
    viralLoad
    testDate
    comments
    documents {
      items {
        id
        name
        comments
        createdAt
				updatedAt
				file {
					key
					fileName
					bucket
				}
      }
      nextToken
    }
    createdAt
    updatedAt
  }
}
`;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withApollo, compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { API, Auth } from 'aws-amplify';
import { withRouter } from 'react-router';
import * as uuid from 'uuid/v1';

import * as queries from '../graphql-custom/queries';
import * as mutations from '../graphql/mutations';
import Layout from './layout/Layout';
import UserForm from './forms/UserForm';
import Errors from './forms/Errors';
import { metadataValues } from '../constants/metadataTypes';


class NewUser extends React.Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);

		this.state = {
			errors: [],
		};

		this.errors = React.createRef();
	}

	async submit(user) {
		const {
			testResult,
			testResultDocumentation,
			povertyDocumentation,
			mentalDocumentation,
			proofOfResidencyDocumentation,
			...input
		} = user;

		const userId = uuid.default();

		// Post to lambda API to create Cognito user
		return API.post('icanrest', '/users', {
			body: {
				username: input.username,
				temporaryPassword: input.password,
				groupName: 'participants',
				userAttributes: [
					{ Name: 'custom:userId', Value: userId },
					{ Name: 'email_verified', Value: 'true' },
					{ Name: 'email', Value: input.username },
					{ Name: 'phone_number_verified', Value: 'true' },
					{ Name: 'phone_number', Value: `+1${input.phoneNumber.replace(/-/g, '')}` },
				],
			},
			headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
		}).then((res) => {
			// Remove non-dynamo fields from object and attach new cognito user ID
			const { username, password, ...dbUser } = input;
			dbUser.cognitoUserId = res.User.Username;
			dbUser.id = userId;
			dbUser.email = username;
			dbUser.archived = false;

			// Push user to DynamoDB
			return this.props.client.mutate({
				mutation: gql(mutations.createUser),
				variables: {
					input: dbUser,
				},
			}).then(({ data: { createUser: userResult } }) => (

				// Next save the test result.
				this.props.client.mutate({
					mutation: gql(mutations.createTestResult),
					variables: {
						input: {
							...testResult,
							testResultUserId: userResult.id,
							owner: res.User.Username,
						},
					},
				}).then(({ data: { createTestResult: result } }) => {
					// Next save any documents.
					const promises = [];

					// Save any poverty documentation
					povertyDocumentation.forEach((f) => {
						const { id, ...file } = f;
						const document = {
							name: file.fileName,
							documentDocumentTypeId: metadataValues.povertyDocumentTypeId,
							file,
							documentUserId: userResult.id,
						};

						promises.push(this.props.client.mutate({
							mutation: gql(mutations.createDocument),
							variables: {
								input: document,
							},
						}));
					});

					// Save any mental illness documentation
					mentalDocumentation.forEach((f) => {
						const { id, ...file } = f;
						const document = {
							name: file.fileName,
							documentDocumentTypeId: metadataValues.mentalIllnessDocumentTypeId,
							file,
							documentUserId: userResult.id,
						};

						promises.push(this.props.client.mutate({
							mutation: gql(mutations.createDocument),
							variables: {
								input: document,
							},
						}));
					});

					// Save proof of residency
					proofOfResidencyDocumentation.forEach((f) => {
						const { id, ...file } = f;
						const document = {
							name: file.fileName,
							documentDocumentTypeId: metadataValues.proofOfResidencyDocumentTypeId,
							file,
							documentUserId: userResult.id,
						};

						promises.push(this.props.client.mutate({
							mutation: gql(mutations.createDocument),
							variables: {
								input: document,
							},
						}));
					});

					// Save any test documentation
					testResultDocumentation.forEach((f) => {
						const { id, ...file } = f;
						const document = {
							name: file.fileName,
							documentDocumentTypeId: metadataValues.testResultDocumentTypeId,
							file,
							documentUserId: userResult.id,
							testResultDocumentsId: result.id,
						};

						promises.push(this.props.client.mutate({
							mutation: gql(mutations.createDocument),
							variables: {
								input: document,
							},
						}));
					});

					return Promise.all(promises).then(() => {
						this.setState({
							errors: [],
						});
						this.props.history.push('/users');
					});
				})
					.then(async () => API.put('icanrest', `/users/${user.username}/mfa`, {
						headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
					}))
					.catch((errRes) => {
						const errors = [];

						if (errRes.graphQLErrors && errRes.graphQLErrors.length) {
							errRes.graphQLErrors.forEach((err) => {
								errors.push(err.message);
							});
						}
					})
			));
		}).catch((res) => {
			const errors = [];

			if (res.graphQLErrors && res.graphQLErrors.length) {
				res.graphQLErrors.forEach((err) => {
					errors.push(err.message);
				});

				API.del('icanrest', `/users/${input.username}`);
			}

			if (res.response) {
				errors.push(res.response.data.message);
			}

			this.setState({
				errors,
			});
			document.getElementsByTagName('body')[0].scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});
	}

	render() {
		const INITIAL_GOALS = gql(queries.initialGoals);
		return (
			<Layout title="New User" subtitle="Enroll a new iCAN mHealth user">
				<Query query={INITIAL_GOALS}>
					{({ loading, error, data }) => {
						if (error) return (<h3 data-testid="error">{JSON.stringify(error)}</h3>);
						if (loading) return (null);

						const {
							initialCheckInsGoal,
							initialTouchbasesGoal,
							initialSurveysGoal,
						} = data;

						const user = {
							attributes: {},
							touchbasesGoal: initialTouchbasesGoal.items.length
								? initialTouchbasesGoal.items[0].data
								: 0,
							checkInsGoal: initialCheckInsGoal.items.length
								? initialCheckInsGoal.items[0].data
								: 0,
							surveysGoal: initialSurveysGoal.items.length
								? initialSurveysGoal.items[0].data
								: 0,
						};

						return (
							<Fragment>
								<Errors errors={this.state.errors} />
								<UserForm user={user} submit={this.submit} />
							</Fragment>
						);
					}}
				</Query>

			</Layout>
		);
	}
}

export default compose(
	withApollo,
	withRouter,
)(NewUser);

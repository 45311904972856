import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { API, Auth } from 'aws-amplify';
import gql from 'graphql-tag';

import EmployeeForm from './forms/EmployeeForm';
import * as mutations from '../graphql/mutations';
import Errors from './forms/Errors';
import Layout from './layout/Layout';

class NewEmployee extends Component {
	constructor(props) {
		super(props);
		this.submit = this.submit.bind(this);

		this.state = {
			errors: [],
		};

		this.errors = React.createRef();
	}

	submit = async (createEmployee, user) => (
		// Post to lambda API to create Cognito user
		API.post('icanrest', '/users', {
			body: {
				username: user.email,
				temporaryPassword: user.password,
				userAttributes: [
					{ Name: 'phone_number', Value: `+1${user.phoneNumber.replace(/-/g, '')}` },
				],
				groupName: user.role === 'ADMINISTRATOR' ? 'administrators' : 'employees',
			},
			headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
		}).then(async (res) => {
			// Remove non-dynamo password field from object and attach new cognito user ID
			const { password, ...employee } = user;
			employee.cognitoUserId = res.User.Username;

			const promises = [];

			// Create DB user
			promises.push(createEmployee({
				variables: {
					input: employee,
				},
			}));

			// Enable MFA on new Cognito user
			promises.push(API.put('icanrest', `/users/${user.email}/mfa`, {
				body: {
					enabled: true,
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				},
			}));

			return Promise.all(promises);
		}).catch((res) => {
			const errors = [];

			if (res.graphQLErrors && res.graphQLErrors.length) {
				res.graphQLErrors.forEach((err) => {
					errors.push(err.message);
				});
			}

			if (res.response) {
				errors.push(res.response.data.message);
			}

			this.setState({
				errors,
			});
			document.getElementsByTagName('body')[0].scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});

			return null;
		})
	);

	render() {
		const ADD_EMPLOYEE = gql(mutations.createEmployee);

		return (
			<Layout title="New Employee">
				<Mutation mutation={ADD_EMPLOYEE}>
					{createEmployee => (
						<Fragment>
							<Errors errors={this.state.errors} />
							<EmployeeForm submit={vars => this.submit(createEmployee, vars)} employee={{ }} />
						</Fragment>
					)}
				</Mutation>
			</Layout>
		);
	}
}

export default NewEmployee;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { compose } from 'react-apollo';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';

import metadataTypes, { systemSettingTypes } from '../../constants/metadataTypes';
import Errors from './Errors';

class MetadataForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		metadata: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			description,
			data,
			subData,
			active,
			markdown,
			type,
			manualEntry,
		} = this.props.metadata;

		let typeData;
		if (type === 'SYSTEM_SETTING') {
			typeData = systemSettingTypes[description];
		} else {
			typeData = metadataTypes[type];
		}

		let isDate = false;
		let date = '';

		if (typeData) {
			isDate = typeData.settingValue && typeData.settingValue.type === 'date';

			date = '';
			if (isDate && data) {
				date = moment(data).format('YYYY-MM-DD');
			}
		} else {
			typeData = {};
		}

		this.state = {
			id: id || '',
			description: description || '',
			type: type || '',
			data: isDate ? date : (data || ''),
			subData: subData || '',
			active: active === undefined ? true : active,
			markdown: markdown || false,
			manualEntry: manualEntry || false,
			errors: [],
			submitted: false,
			typeData,
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleInputChange = this.handleInputChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleInputChange(event) {
		const {
			target: {
				type,
				name,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate() {
		const valid = this.form.current.reportValidity();
		if (valid) {
			const {
				id,
				description,
				type,
				active,
				markdown,
				data,
				subData,
				manualEntry,
			} = this.state;

			const metadata = {
				id,
				description,
				type: type.toUpperCase(),
				active,
				markdown,
				manualEntry,
			};

			if (data) {
				metadata.data = data;
			}

			if (subData) {
				metadata.subData = subData;
			}

			this.setState({
				submitted: true,
			});

			this.props.submit({ variables: { input: metadata } }).then(() => {
				this.props.history.push(`/admin/metadata?type=${type.toLowerCase()}`);
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		const typesWithData = [
			'system_setting',
			'touch_frequency',
			'help_quick_link',
			'enrollment_message',
			'appointment_reminder_buffer',
			'snooze_buffer',
		];

		const typesWithManualEntry = [
			'sexual_orientation',
			'gender',
			'referral_type',
		];

		const {
			label,
			settingValue,
			subValue,
			markdownCapable,
		} = this.state.typeData;

		return (
			<form className="my-4 mx-2" ref={this.form} onSubmit={e => e.preventDefault()}>
				<Errors errors={this.state.errors} />
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				{this.props.metadata && (
					<div className="card mb-3">
						<div className="card-body">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group col-md-12">
										<label htmlFor="description">{label || 'Description'}</label>
										<input disabled={this.state.type === 'SYSTEM_SETTING'} required name="description" id="description" type="text" value={this.state.description} className="form-control" placeholder="Enter value" onChange={this.handleInputChange} />
									</div>
									{typesWithData.indexOf(this.state.type.toLowerCase()) >= 0 && (
										<Fragment>
											{markdownCapable && (
												<div className="form-group col-md-12">
													<div className="form-check">
														<input type="checkbox" name="markdown" checked={this.state.markdown} className="form-check-input" id="markdown" onChange={this.handleInputChange} />
														<label className="form-check-label" htmlFor="markdown">Enable Markdown</label>
													</div>
												</div>
											)}
											{this.state.type === 'HELP_QUICK_LINK' && (
												<div className="form-group col-md-12">
													<label htmlFor="prep">Link Type</label>
													<div id="linkType">
														<div className="custom-control custom-radio">
															<input required type="radio" id="link-type-website" name="linkType" checked={this.state.subData === 'website'} value="website" onChange={() => this.setState({ subData: 'website' })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="link-type-website">Website</label>
														</div>
														<div className="custom-control custom-radio">
															<input required type="radio" id="link-type-phone-number" name="linkType" checked={this.state.subData === 'phoneNumber'} value="phoneNumber" onChange={() => this.setState({ subData: 'phoneNumber' })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="link-type-phone-number">Phone Number</label>
														</div>
													</div>
												</div>
											)}
											{settingValue && (
												<div className="form-group col-md-12">
													<label htmlFor="data">{settingValue.label}</label>
													{!this.state.markdown && (
														<input name="data" id="data" type={settingValue.type} value={this.state.data} className="form-control" placeholder="Enter value" onChange={this.handleInputChange} />
													)}
													{this.state.markdown && (
														<textarea className="form-control" value={this.state.data} id="data" name="data" rows="20" placeholder="Enter Markdown" onChange={this.handleInputChange} />
													)}
												</div>
											)}
											{subValue && (
												<div className="form-group col-md-12">
													<label htmlFor="data">{subValue.label}</label>
													<input name="subData" id="subData" type={subValue.type} value={this.state.subData} className="form-control" placeholder="Enter value" onChange={this.handleInputChange} />
												</div>
											)}
											{typesWithManualEntry.indexOf(this.state.type.toLowerCase()) >= 0 && (
												<div className="form-group col-md-4">
													<div className="form-check">
														<input type="checkbox" name="manualEntry" checked={this.state.manualEntry} className="form-check-input" id="manualEntry" onChange={this.handleInputChange} />
														<label className="form-check-label" htmlFor="manualEntry">Trigger Manual Entry</label>
													</div>
												</div>
											)}
										</Fragment>
									)}
									<div className="form-group col-md-4">
										<div className="form-check">
											<input type="checkbox" name="active" checked={this.state.active} className="form-check-input" id="active" onChange={this.handleInputChange} />
											<label className="form-check-label" htmlFor="active">Active</label>
										</div>
									</div>
								</div>
								{this.state.markdown && (
									<div className="col-md-6">
										<ReactMarkdown source={this.state.data} />
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<button className="btn btn-primary" disabled={this.state.submitted} type="submit" onClick={this.validate}>
					{this.state.submitted && (
						<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
					)}
					{this.state.submitted ? 'Loading...' : 'Submit'}
				</button>
			</form>
		);
	}
}

export default compose(
	withRouter,
)(MetadataForm);

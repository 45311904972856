import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';
import Toast from 'react-bootstrap/Toast';
import { API, Auth } from 'aws-amplify';
import { Check } from '@material-ui/icons';

import UserForm from './forms/UserForm';
import Errors from './forms/Errors';
import * as userQueries from '../graphql-custom/queries';
import * as mutations from '../graphql-custom/mutations';
import { metadataValues } from '../constants/metadataTypes';
import Loading from './Loading';

class EditUser extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.handleUpdateUser = this.handleUpdateUser.bind(this);
		this.state = {
			show: false,
			errors: [],
		};

		this.errors = React.createRef();
	}

	handleUpdateUser(user, updateAttributes, email, archivedUpdated) {
		const {
			povertyDocumentation,
			mentalDocumentation,
			proofOfResidencyDocumentation,
			testResult,
			testResultDocumentation,
			...input
		} = user;

		return this.props.client.mutate({
			mutation: gql(mutations.updateUser),
			variables: {
				input,
			},
		}).then(() => {
			let deleteGql = '';
			let insertGql = '';
			let iterator = 0;

			const insertDocuments = {};
			const deleteDocuments = {};

			povertyDocumentation.forEach((f) => {
				const { id, deleted, ...document } = f;
				const param = `document${iterator}`;
				iterator += 1;

				if (deleted) {
					deleteDocuments[param] = {
						id,
					};
					deleteGql += `${param}: deleteDocument(input: $${param}) { id }
					`;
				} else if (!document.file) {
					insertDocuments[param] = {
						name: document.fileName,
						documentDocumentTypeId: metadataValues.povertyDocumentTypeId,
						file: document,
						documentUserId: user.id,
					};
					insertGql += `${param}: createDocument(input: $${param}) { id }
					`;
				}
			});

			mentalDocumentation.forEach((f) => {
				const { id, deleted, ...document } = f;
				const param = `document${iterator}`;
				iterator += 1;

				if (deleted) {
					deleteDocuments[param] = {
						id,
					};
					deleteGql += `${param}: deleteDocument(input: $${param}) { id }
					`;
				} else if (!document.file) {
					insertDocuments[param] = {
						name: document.fileName,
						documentDocumentTypeId: metadataValues.mentalIllnessDocumentTypeId,
						file: document,
						documentUserId: user.id,
					};
					insertGql += `${param}: createDocument(input: $${param}) { id }
					`;
				}
			});

			proofOfResidencyDocumentation.forEach((f) => {
				const { id, deleted, ...document } = f;
				const param = `document${iterator}`;
				iterator += 1;

				if (deleted) {
					deleteDocuments[param] = {
						id,
					};
					deleteGql += `${param}: deleteDocument(input: $${param}) { id }
					`;
				} else if (!document.file) {
					insertDocuments[param] = {
						name: document.fileName,
						documentDocumentTypeId: metadataValues.proofOfResidencyDocumentTypeId,
						file: document,
						documentUserId: user.id,
					};
					insertGql += `${param}: createDocument(input: $${param}) { id }
					`;
				}
			});

			if (deleteGql.length || insertGql.length) {
				const params = Object.keys(insertDocuments).map(k => `$${k}: CreateDocumentInput!`)
					.concat(Object.keys(deleteDocuments).map(k => `$${k}: DeleteDocumentInput!`));


				const mutation = `mutation TestResultDocuments(${params.join(', ')}) {
					${deleteGql}${insertGql}
				}`;

				console.info(`MUTATION =
					${mutation}
				`);

				return this.props.client.mutate({
					mutation: gql(mutation),
					variables: { ...insertDocuments, ...deleteDocuments },
				});
			}

			return true;
		}).then(async () => {
			this.setState({ show: true });
			this.refetch();
			this.setState({
				errors: [],
			});

			if (archivedUpdated) {
				API.post('icanrest', `/users/${email}/${user.archived ? 'disable' : 'enable'}`, {
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				});
			}

			if (updateAttributes) {
				return API.put('icanrest', `/users/${email}/attributes`, {
					body: {
						userAttributes: [
							{ Name: 'email_verified', Value: 'true' },
							{ Name: 'email', Value: user.email },
							{ Name: 'phone_number_verified', Value: 'true' },
							{ Name: 'phone_number', Value: `+1${user.phoneNumber.replace(/-/g, '')}` },
						],
					},
					headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
				}).catch((res) => {
					if (res.response) {
						if (res.response.data.message.indexOf('already exists')) {
							// Rollback email change
							this.props.client.mutate({
								mutation: gql(mutations.updateUser),
								variables: {
									input: {
										id: input.id,
										email,
									},
								},
							});
							throw res;
						}
					}

					// ROllback email change.
				});
			}

			return null;
		}).catch((res) => {
			const errors = [];

			if (res.graphQLErrors && res.graphQLErrors.length) {
				res.graphQLErrors.forEach((err) => {
					errors.push(err.message);
				});
			}

			if (res.response) {
				errors.push(res.response.data.message);
			}

			this.setState({
				errors,
			});
			document.getElementsByTagName('body')[0].scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		});
	}

	render() {
		const GET_USER = gql(userQueries.getUser);

		return (
			<Query notifyOnNetworkStatusChange query={GET_USER} fetchPolicy="no-cache" variables={{ id: this.props.match.params.id }}>
				{({
					loading, error, data, refetch, networkStatus,
				}) => {
					this.refetch = refetch;
					if (networkStatus === 4) return 'Refreshing...';
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<Fragment>
							<Toast style={{ position: 'absolute', top: 75, right: 25 }} onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide>
								<Toast.Body><Check style={{ fontSize: '20px' }} className="mr-1" /> User details have been successfully saved.</Toast.Body>
							</Toast>
							<Errors errors={this.state.errors} />
							<UserForm submit={this.handleUpdateUser} user={data.user} />
						</Fragment>
					);
				}}
			</Query>
		);
	}
}

export default withApollo(EditUser);

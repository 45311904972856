import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Switch, Route } from 'react-router-dom';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';
import moment from 'moment';

import ListDocuments from './ListDocuments';
import ListTestResults from './ListTestResults';
import NewUserTestResult from './NewTestResult';
import EditUserTestResult from './EditTestResult';
import UserDetailsTab from './EditUser';
import ListTouches from './ListTouches';
import NewUserTouch from './NewTouch';
import EditUserTouch from './EditTouch';
import NewUserDocument from './NewDocument';
import EditUserDocument from './EditDocument';
import NewIncentive from './NewIncentive';
import EditIncentive from './EditIncentive';
import ListCheckIns from './ListCheckins';
import ListIncentives from './ListIncentives';
import EditGoals from './EditGoals';
import ListMedications from './ListMedications';
import ListMedicationInstances from './ListMedicationInstances';
import Layout from './layout/Layout';
import Loading from './Loading';

class User extends React.Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	static defaultProps = {};

	render() {
		const { path, params: { id } } = this.props.match;
		const GET_USER_NAME = gql(`query username($id: ID!) {
			user: getUser(id: $id) {
				id
				cognitoUserId
				name
				archived
				touchFrequency {
					id
					description
					data
				}
				touches(sortDirection:DESC, limit: 1) {
					items {
						id
						touchDate
					}
				}
			}
		}`);

		return (
			<Query query={GET_USER_NAME} variables={{ id }} fetchPolicy="no-cache">
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					const {
						name,
						touchFrequency,
						touches,
						cognitoUserId,
					} = data.user;

					const touchFrequencyDays = touchFrequency
						? touchFrequency.data : null;
					const lastTouchDate = touches.items.length
						? touches.items[0].touchDate : null;
					const touchbaseOverdue = touchFrequencyDays
						&& (!lastTouchDate || Math.abs(moment(lastTouchDate).diff(moment(), 'days')) >= touchFrequencyDays);


					return (
						<Layout
							title={name}
							subtitle="Manage mobile app participant"
							showTouchbaseWarning={touchbaseOverdue}
						>
							<div style={{ position: 'relative' }}>
								<ul className="nav nav-pills">
									<li className="nav-item">
										<NavLink data-testid="details-tab" activeClassName="active" className="nav-link" to={`/users/${id}/details`}>User Details</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/testResults`}>Viral Load</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/medications`}>Medications</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/touches`}>Touches</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/checkIns`}>Check-Ins</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/incentives`}>Incentives</NavLink>
									</li>
									<li className="nav-item">
										<NavLink activeClassName="active" className="nav-link" to={`/users/${id}/documents`}>Documents</NavLink>
									</li>
								</ul>
								<div className="my-3">
									<Switch>
										<Route path={`${path}/details`} exact component={UserDetailsTab} />
										<Route
											path={`${path}/testResults/new`}
											component={props => <NewUserTestResult {...props} owner={cognitoUserId} />}
										/>
										<Route
											path={`${path}/testResults/:testResultId`}
											component={EditUserTestResult}
										/>
										<Route
											path={`${path}/medications/:medicationId`}
											component={ListMedicationInstances}
										/>
										<Route
											path={`${path}/medications`}
											component={ListMedications}
										/>
										<Route
											path={`${path}/testResults`}
											component={ListTestResults}
										/>
										<Route
											path={`${path}/touches/new`}
											component={props => <NewUserTouch {...props} owner={cognitoUserId} />}
										/>
										<Route
											path={`${path}/touches/:touchId`}
											component={EditUserTouch}
										/>
										<Route
											path={`${path}/touches`}
											component={ListTouches}
										/>
										<Route
											path={`${path}/documents/new`}
											component={NewUserDocument}
										/>
										<Route
											path={`${path}/documents/:documentId`}
											component={EditUserDocument}
										/>
										<Route
											path={`${path}/documents`}
											component={ListDocuments}
										/>
										<Route
											path={`${path}/checkIns`}
											component={ListCheckIns}
										/>
										<Route
											path={`${path}/incentives/new`}
											component={props => <NewIncentive {...props} owner={cognitoUserId} />}
										/>
										<Route
											path={`${path}/incentives/goals`}
											component={EditGoals}
										/>
										<Route
											path={`${path}/incentives/:incentiveId`}
											component={EditIncentive}
										/>
										<Route
											path={`${path}/incentives`}
											component={ListIncentives}
										/>
									</Switch>
								</div>
							</div>
						</Layout>
					);
				}}
			</Query>
		);
	}
}

export default withApollo(User);

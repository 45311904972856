const metadataTypes = {
	ARCHIVE_REASON: {
		singular: 'Archive Reason',
		plural: 'Archive Reasons',
		label: 'Reason',
	},
	GENDER: {
		singular: 'Gender',
		plural: 'Genders',
	},
	RACE: {
		singular: 'Race',
		plural: 'Races',
	},
	DISABILITY: {
		singular: 'Disability',
		plural: 'Disabilities',
	},
	CONTACT_METHOD: {
		singular: 'Contact Method',
		plural: 'Contact Methods',
	},
	HELP_QUICK_LINK: {
		singular: 'Help Request Quick Link',
		plural: 'Help Request Quick Links',
		label: 'Link Title',
		settingValue: {
			label: 'URL or Phone Number',
			type: 'text',
		},
	},
	TOUCH_TYPE: {
		singular: 'Touch Type',
		plural: 'Touch Types',
	},
	TOUCH_FREQUENCY: {
		singular: 'Touch Frequency',
		plural: 'Touch Frequencies',
		settingValue: {
			label: 'Days',
			type: 'number',
		},
	},
	RESOURCE_TYPE: {
		singular: 'Resource Type',
		plural: 'Resource Types',
	},
	DOCUMENT_TYPE: {
		singular: 'Document Type',
		plural: 'Document Types',
	},
	INCENTIVE_TYPE: {
		singular: 'Incentive Type',
		plural: 'Incentive Types',
	},
	HELP_REQUEST_TYPE: {
		singular: 'Help Request Type',
		plural: 'Help Request Types',
	},
	COUNTY: {
		singular: 'County',
		plural: 'Counties',
	},
	ENROLLMENT_MESSAGE: {
		singular: 'Enrollment Message',
		plural: 'Enrollment Messages',
		label: 'Enrollment Message',
		settingValue: {
			label: 'Enrollment Start Date',
			type: 'date',
		},
	},
	SYSTEM_SETTING: {
		singular: 'System Setting',
		plural: 'System Settings',
	},
	SEXUAL_ORIENTATION: {
		singular: 'Sexual Orientation',
		plural: 'Sexual Orientations',
	},
	PARTNER_TYPE: {
		singular: 'Partner Type',
		plural: 'Partner Types',
	},
	REFERRAL_TYPE: {
		singular: 'Referral Type',
		plural: 'Referral Types',
	},
	APPOINTMENT_REMINDER_BUFFER: {
		singular: 'Appointment Reminder Time',
		plural: 'Appointment Reminder Times',
		settingValue: {
			label: 'Minutes',
			type: 'number',
		},
	},
	SNOOZE_BUFFER: {
		singular: 'Snooze Time',
		plural: 'Snooze Times',
		settingValue: {
			label: 'Minutes',
			type: 'number',
		},
	},
};

export const systemSettingTypes = {
	INITIAL_CHECK_INS_GOAL: {
		settingValue: {
			label: 'Number of Check-Ins',
			type: 'number',
		},
	},
	ABOUT_CANAPI: {
		markdownCapable: true,
		settingValue: {
			label: 'About CANAPI',
			type: 'text',
		},
	},
	CANAPI_EMAIL: {
		settingValue: {
			label: 'CANAPI Email Address',
			type: 'email',
		},
	},
	CANAPI_HOURS: {
		settingValue: {
			label: 'CANAPI Hours',
			type: 'text',
		},
	},
	CANAPI_PHONE: {
		settingValue: {
			label: 'CANAPI Phone Number',
			type: 'text',
		},
	},
	CANAPI_SLA: {
		settingValue: {
			label: 'Service-Level Agreement',
			type: 'text',
		},
	},
	INITIAL_SURVEYS_GOAL: {
		settingValue: {
			label: 'Number of Surveys',
			type: 'number',
		},
	},
	INITIAL_TOUCH_BASES_GOAL: {
		settingValue: {
			label: 'Number of Touches',
			type: 'number',
		},
	},
	PARTICIPANT_INACTIVITY_DAYS_LIMIT: {
		settingValue: {
			label: 'Number of Days',
			type: 'number',
		},
	},
	PRIVACY_POLICY: {
		markdownCapable: true,
		settingValue: {
			label: 'Privacy Policy',
			type: 'text',
		},
	},
	LEGAL_AGREEMENT: {
		markdownCapable: true,
		settingValue: {
			label: 'Legal Agreement',
			type: 'text',
		},
	},
};

export default metadataTypes;

export const metadataValues = {
	archiveTouchTypeId: 'a5baf595-74ff-41d8-98b7-6de492bcadba',
	activateTouchTypeId: '44e8b99a-6ff0-4edb-bc4c-58cce56a4e70',
	mentalIllnessDocumentTypeId: '723f985e-982e-4a5d-816f-c0d1e0adf41e',
	testResultDocumentTypeId: '389a5fc3-b818-497d-bbfb-07c27c87db1a',
	povertyDocumentTypeId: 'a4f144cd-eae6-4608-a1c3-b6b6ca43d654',
	proofOfResidencyDocumentTypeId: '0295dc7a-7323-4983-ae66-2af3b4e80142',
	receiptDocumentTypeId: 'ee901b75-7f50-411f-8f12-7afcad095c3b',
	otherDocumentType: '7524936d-c904-4715-927a-fa92ad78c608',
	participantAgreementDocumentType: '93ba1a46-1abe-4762-9a44-bca5dd431578',
};

import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const columns = [
	{
		dataField: 'name',
		text: 'Name',
	},
	{
		dataField: 'totalCheckIns',
		text: 'Check-Ins',
		formatter: (cell, row) => (`${row.checkIns.items.length} / ${row.checkInsGoal}`),
	},
	{
		dataField: 'totalTouchbases',
		text: 'Touchbases',
		formatter: (cell, row) => (`${row.touches.items.filter(t => t.incentivized).length} / ${row.touchbasesGoal}`),
	},
	{
		dataField: 'totalSurveys',
		text: 'Surveys',
		formatter: (cell, row) => (`${row.totalSurveys} / ${row.surveysGoal}`),
	},
];

const Incentives = ({ users }) => {
	if (users.length === 0) return (<h3 data-testid="no-data">No Data</h3>);

	const incentiveUsers = users.filter((u) => {
		console.log(`${u.checkIns.items.length} | ${u.touches.items.filter(t => t.incentivized).length} | ${u.totalSurveys}`);

		if (!u.checkInsGoal || !u.surveysGoal || !u.touchbasesGoal) { return false }

		return (u.checkIns.items.length >= u.checkInsGoal)
			&& (u.totalSurveys >= u.surveysGoal)
			&& (u.touches.items.filter(t => t.incentivized).length >= u.touchbasesGoal);
	});

	return (
		<BootstrapTable
			keyField="id"
			bordered={false}
			data={incentiveUsers}
			noDataIndication="There are no participants due for incentive."
			columns={columns}
			pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
		/>
	);
};

Incentives.propTypes = {
	users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Incentives;

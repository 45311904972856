import React, { Component, Fragment } from 'react';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import 'rc-calendar/assets/index.css';
import { withApollo, Query } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import { Add, Remove } from '@material-ui/icons';

import * as queries from '../graphql-custom/queries';
import Loading from './Loading';

const emojiScaleItems = {
	1: '😭',
	2: '😩',
	3: '🙁',
	4: '😐',
	5: '🙂',
	6: '😀',
};

const defaultRange = [
	moment().add(-1, 'months'),
	moment(),
];

const CheckInDetails = checkIn => (
	<Fragment>
		<div className="row">
			<div className="col-md-4">
				{checkIn.answers.items.map(a => (
					<Fragment key={a.id}>
						<strong>{a.checkInQuestion.text}</strong>
						<div>{a.answer}</div>
					</Fragment>
				))}
			</div>
			<div className="col-md-8">
				<strong>Comments:</strong>
				<div>
					{checkIn.comments}
				</div>
			</div>
		</div>
	</Fragment>
);

class ListCheckIns extends Component {
	static propTypes = {
		client: PropTypes.shape().isRequired,
		match: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);
		this.handleRangeSelect = this.handleRangeSelect.bind(this);

		this.state = {
			checkIns: [],
			range: [],
		};
	}

	componentDidMount() {
		this.handleRangeSelect(defaultRange);
	}

	handleRangeSelect = (dates) => {
		const start = dates[0].startOf('day').toISOString();
		const end = dates[1].endOf('day').toISOString();

		this.props.client.query({
			query: gql(queries.listCheckInsWithDetails),
			variables: {
				id: this.props.match.params.id,
				start,
				end,
			},
			fetchPolicy: 'no-cache',
		}).then(({ data: { getUser } }) => {
			this.setState({
				checkIns: getUser.checkIns.items,
			});
		});
	}

	render() {
		const GET_CHECKINS = gql(queries.listCheckIns);

		const columns = [
			{
				dataField: 'createdAt',
				text: 'Date/Time',
				formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY hh:mm A'),
			},
			{
				dataField: 'moodScale',
				text: 'Mood',
				align: 'center',
				headerAlign: 'center',
				formatter: (cell, row) => (<Emoji>{row.moodScale ? emojiScaleItems[row.moodScale] : ''}</Emoji>),
			},
		];

		return (
			<Query query={GET_CHECKINS} fetchPolicy="no-cache" variables={{ id: this.props.match.params.id }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					const checkInDates = data.user.checkIns.items.map(checkIn => moment(checkIn.createdAt).startOf('day').toDate().getTime());

					return (
						<div className="card mb-3">
							<div className="card-body">
								<div className="d-flex flex-row">
									<div>
										<h1>{this.state.range}</h1>
										<RangeCalendar
											onSelect={this.handleRangeSelect}
											dateRender={(date) => {
												const cls = 'rc-calendar-date';
												const num = date.date();
												const ms = date.startOf('day').toDate().getTime();

												return (
													<div
														style={checkInDates.indexOf(ms) >= 0 ? { fontSize: '15px', color: 'green', fontWeight: 'bold' } : {}}
														key={`rc-calendar-${date.year()}-${date.month()}-${date.date()}`}
														className={cls}
													>
														{num}
													</div>
												);
											}}
											defaultSelectedValue={defaultRange}
											showToday={false}
										/>
									</div>
									<div className="px-4 flex-fill">
										<BootstrapTable
											keyField="id"
											bordered={false}
											data={this.state.checkIns}
											noDataIndication="There are no check-ins to display."
											columns={columns}
											expandRow={{
												renderer: CheckInDetails,
												showExpandColumn: true,
												expandColumnPosition: 'right',
												expandColumnAlign: 'right',
												expandHeaderColumnRenderer: () => (null),
												expandColumnRenderer: ({ expanded }) => (
													<DetailsToggle>
														{expanded && (
															<button type="button" className="mr-1 btn btn-light">
																Details <Remove />
															</button>
														)}
														{!expanded && (
															<button type="button" className="mr-1 btn btn-light">
																Details <Add />
															</button>
														)}
													</DetailsToggle>
												),
											}}
											pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

const Emoji = styled.span`
	font-size: 2em;
`;

const DetailsToggle = styled.div`
	text-align: right;
`;


export default withApollo(ListCheckIns);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import moment from 'moment';
import { CardDeck, Card, ListGroup } from 'react-bootstrap';
import {
	PhoneOutlined, EmailOutlined,
} from '@material-ui/icons';

import Errors from './Errors';

class HelpRequestForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		helpRequest: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			closed,
			internalComments,
		} = this.props.helpRequest;

		this.state = {
			id,
			closed,
			internalComments,
			submitted: false,
			errors: [],
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.form = React.createRef();
		this.validate = this.validate.bind(this);
	}

	handleInputChange(event) {
		const {
			target: {
				type,
				name,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate() {
		const valid = this.form.current.reportValidity();

		if (valid) {
			const { id, closed, internalComments } = this.state;
			const helpRequest = {
				id,
				closed,
				internalComments,
			};

			this.setState({
				submitted: true,
			});

			this.props.submit({ variables: { input: helpRequest } }).then(() => {
				this.props.history.push('/');
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		const {
			user,
			type,
			createdAt,
			requestComments,
		} = this.props.helpRequest;

		return (
			<form data-testid="help-request-form" className="my-4 mx-2" ref={this.form} onSubmit={e => e.preventDefault()}>
				<Errors errors={this.state.errors} />
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				{this.props.helpRequest && (
					<Fragment>
						<CardDeck>
							<Card>
								<Card.Header>Modify Request</Card.Header>
								<Card.Body>
									<fieldset>
										<div className="row">
											<div className="form-group col-md-4">
												<div className="custom-control custom-checkbox">
													<input className="custom-control-input" type="checkbox" name="closed" checked={this.state.closed} id="closed" onChange={this.handleInputChange} />
													<label className="custom-control-label" htmlFor="closed">Resolved</label>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-8">
												<label htmlFor="internalComments">Notes/Comments</label>
												<textarea name="internalComments" id="internalComments" value={this.state.internalComments} className="form-control" rows="3" placeholder="Enter comments" onChange={this.handleInputChange} required />
											</div>
										</div>
									</fieldset>
								</Card.Body>
							</Card>
							<Card>
								<Card.Header>Request Information</Card.Header>
								<ListGroup variant="flush">
									<ListGroup.Item>
										Submitted By <strong>{user.name}</strong>
									</ListGroup.Item>
									<ListGroup.Item>
										Contact Information
										<span className="pl-2">
											<PhoneOutlined /> {user.phoneNumber}
										</span>
										<span className="pl-2">
											<EmailOutlined /> {user.email}
										</span>
									</ListGroup.Item>
									<ListGroup.Item>
										Submitted On <strong>{moment(createdAt).format('MM/DD/YYYY hh:mm A')}</strong>
									</ListGroup.Item>
									<ListGroup.Item>
										Requesting <strong>{type.description}</strong>
									</ListGroup.Item>
									<ListGroup.Item>
										<div className="mb-2">Notes/Comments From Participant</div>
										<blockquote className="blockquqte">
											{requestComments}
										</blockquote>
									</ListGroup.Item>
								</ListGroup>
							</Card>
						</CardDeck>
					</Fragment>
				)}
				<button className="btn btn-primary mt-2" disabled={this.state.submitted} type="submit" onClick={this.validate}>
					{this.state.submitted && (
						<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
					)}
					{this.state.submitted ? 'Loading...' : 'Submit'}
				</button>
			</form>
		);
	}
}

export default withRouter(HelpRequestForm);

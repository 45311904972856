import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Storage } from 'aws-amplify';
import {
	Add,
	Edit,
	AttachFile,
} from '@material-ui/icons';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as queries from '../graphql-custom/queries';
import Loading from './Loading';

class ListIncentives extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	getReceipt = key => (
		Storage.get(key.replace('public/', ''), {
			level: 'public',
		}).then((data) => {
			window.open(data, '_blank');
		})
	);

	render() {
		const { match } = this.props;
		const GET_INCENTIVES = gql(queries.listUserIncentives);
		const userId = match.params.id;

		const columns = [
			{
				dataField: 'createdAt',
				text: 'Entry Date',
				formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY hh:mm A'),
				footer: '',
			},
			{
				dataField: 'givenDate',
				text: 'Given Date',
				formatter: (cell, row) => moment(row.givenDate).format('MM/DD/YYYY'),
				footer: '',
			},
			{
				dataField: 'type.description',
				text: 'Type',
				footer: '',
			},
			{
				dataField: 'value',
				text: 'Amount',
				formatter: (cell, row) => `$${row.value.toFixed(2)}`,
				footer: columnData => `Total = $${(columnData.reduce((acc, item) => acc + item, 0)).toFixed(2)}`,
			},
			{
				dataField: 'totalSurveys',
				text: 'Check-Ins / Touchbases / Surveys',
				formatter: (cell, row) => `${row.totalCheckIns || 0} / ${row.totalTouchbases || 0} / ${row.totalSurveys || 0}`,
			},
			{
				dataField: 'df1',
				text: '',
				dummyField: true,
				align: 'right',
				formatter: (cell, row) => (
					<Fragment>
						{row.document && (
							<button type="button" onClick={() => { this.getReceipt(row.document.file.key) }} className="mr-1 btn btn-light">
								<AttachFile />
							</button>
						)}
						<Link to={`${this.props.match.url}/${row.id}`}>
							<button type="button" className="btn btn-light">
								<Edit />
							</button>
						</Link>
					</Fragment>
				),
			},
		];

		return (
			<Query fetchPolicy="no-cache" query={GET_INCENTIVES} variables={{ id: userId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);


					const { user } = data;

					// Sort incentives by descending GivenDate then by EntryDate
					const incentives = user.incentives.items.sort((a, b) => {
						if (a.givenDate > b.givenDate) {
							return -1;
						}

						if (a.givenDate < b.givenDate) {
							return -1;
						}

						return a.createdAt < b.createdAt ? 1 : -1;
					});

					const editGoalsPath = {
						pathname: `/users/${userId}/incentives/goals`,
						state: {
							user: {
								id: userId,
								checkInsGoal: user.checkInsGoal,
								touchbasesGoal: user.touchbasesGoal,
								surveysGoal: user.surveysGoal,
							},
						},
					};

					const touchCount = user.touches.items.filter(t => t.incentivized).length;

					return (
						<div className="row">
							<div className="col-md-4">
								<div className="card">
									<div className="card-body">
										<h5>Milestones (Current / Goal)</h5>
										<table className="table table-sm">
											<thead>
												<tr>
													<th>Milestone</th>
													<th>Current Amount / Goal</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Check Ins</td>
													<td>{user.checkIns.items.length || 0} / {user.checkInsGoal || 0}</td>
												</tr>
												<tr>
													<td>Touch Bases</td>
													<td>{touchCount || 0} / {user.touchbasesGoal || 0}</td>
												</tr>
												<tr>
													<td>Surveys</td>
													<td>{user.totalSurveys || 0} / {user.surveysGoal || 0}</td>
												</tr>
											</tbody>
										</table>
										<Link to={editGoalsPath} className="btn btn-primary mr-1">
											Edit Goals
										</Link>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="card">
									<div className="card-body">
										<div className="btn-group mb-4" role="group" aria-label="Basic example">
											<Link to={`/users/${userId}/incentives/new`} className="btn btn-primary mr-1">
												<Add />
											</Link>
										</div>
										<BootstrapTable
											keyField="id"
											bordered={false}
											data={incentives}
											noDataIndication="There are no incentives to display."
											columns={columns}
											pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default ListIncentives;

import React, { Component, Fragment } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { Storage, API } from 'aws-amplify';
import { compose, Query, withApollo } from 'react-apollo';
import { Delete, SaveAlt, Add } from '@material-ui/icons';
import gql from 'graphql-tag';
import * as uuid from 'uuid/v1';
import cogoToast from 'cogo-toast';

import Loading from '../Loading';
import FileInput from './FileInput';
import * as userQueries from '../../graphql-custom/queries';

class UserForm extends Component {
	static propTypes = {
		user: PropTypes.shape().isRequired,
		history: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			legalFirstName,
			legalLastName,
			legalMiddleInitial,
			name,
			dateOfBirth,
			ryanWhiteNumber,
			race,
			gender,
			disability,
			preferredContactMethod,
			censusTractNumber,
			phoneNumber,
			householdPoverty,
			alternatePhoneNumber,
			address,
			county,
			birthGender,
			otherBirthGender,
			otherGender,
			transgender,
			mentalIllness,
			partners,
			prep,
			condomUsage,
			sexualOrientation,
			otherSexualOrientation,
			referredBy,
			referredByOther,
			touchFrequency,
			documents,
			householdPovertyPercentage,
			email,
			touchbasesGoal,
			surveysGoal,
			checkInsGoal,
			enrollmentDate,
			archived,
			archivedReason,
		} = this.props.user;

		let povertyDocumentation = [];
		let mentalDocumentation = [];
		let proofOfResidencyDocumentation = [];

		if (documents) {
			povertyDocumentation = documents.items.filter(d => d.documentType.description === 'Household Poverty Documentation');
			proofOfResidencyDocumentation = documents.items.filter(d => d.documentType.description === 'Proof of Residency');
			mentalDocumentation = documents.items.filter(d => d.documentType.description === 'Mental Illness Documentation');
		}

		if (povertyDocumentation.length === 0) {
			povertyDocumentation = [{ id: uuid.default() }];
		}

		this.state = {
			id: id || '',
			legalFirstName: legalFirstName || '',
			legalLastName: legalLastName || '',
			legalMiddleInitial: legalMiddleInitial || '',
			name: name || '',
			dateOfBirth: dateOfBirth || '',
			ryanWhiteNumber: ryanWhiteNumber || '',
			householdPoverty: householdPoverty || false,
			userRaceId: race ? race.id : '',
			userGenderId: gender ? gender.id : '',
			userGenderManualEntry: gender ? gender.manualEntry : '',
			userDisabilityId: disability ? disability.id : '',
			userPreferredContactMethodId: preferredContactMethod ? preferredContactMethod.id : '',
			censusTractNumber: censusTractNumber || '',
			phoneNumber: phoneNumber || '',
			alternatePhoneNumber: alternatePhoneNumber || '',
			address: address || '',
			userCountyId: county ? county.id : '',
			povertyDocumentation,
			mentalDocumentation,
			proofOfResidencyDocumentation,
			userBirthGenderId: birthGender ? birthGender.id : '',
			userBirthGenderManualEntry: birthGender ? birthGender.manualEntry : false,
			otherBirthGender: otherBirthGender || '',
			otherGender: otherGender || '',
			transgender: transgender === null ? null : transgender,
			mentalIllness: mentalIllness === null ? null : mentalIllness,
			userPartnersId: partners ? partners.id : '',
			prep: prep || null,
			condomUsage: condomUsage || '',
			userSexualOrientationId: sexualOrientation ? sexualOrientation.id : '',
			userSexualOrientationManualEntry: sexualOrientation ? sexualOrientation.manualEntry : false,
			otherSexualOrientation: otherSexualOrientation || '',
			userReferredById: referredBy ? referredBy.id : '',
			userReferredByManualEntry: referredBy ? referredBy.manualEntry : false,
			referredByOther: referredByOther || '',
			householdPovertyPercentage: householdPovertyPercentage || null,
			testResultDocumentation: [{ id: uuid.default() }],
			email: email || null,
			enrollmentDate: enrollmentDate || '',
			userTouchFrequencyId: touchFrequency ? touchFrequency.id : null,
			userArchivedReasonId: archivedReason ? archivedReason.id : null,
			touchbasesGoal,
			surveysGoal,
			checkInsGoal,
			submitted: false,
			archived: archived || false,
			addressSubmitted: false,
		};

		if (!id) {
			this.state.username = '';
			this.state.password = 'Password1!';
			this.state.testDate = '';
			this.state.viralLoad = '';
			this.state.testResultsComments = '';
			this.state.range = '';
		}

		this.form = React.createRef();
		this.startState = this.state;
		this.getCensusTract = this.getCensusTract.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.handleFileRemove = this.handleFileRemove.bind(this);
		this.handleAddDocument = this.handleAddDocument.bind(this);
		this.handleAddressChange = this.handleAddressChange.bind(this);
		this.handleManualFieldChange = this.handleManualFieldChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	getDocument = key => (
		Storage.get(key.replace('public/', ''), { level: 'public' }).then((data) => {
			window.open(data, '_blank');
		})
	);

	async getCensusTract(counties) {
		const { address } = this.state;

		this.setState({
			addressSubmitted: true,
		});

		return API.get('icanrest', '/users/censusTract', {
			queryStringParameters: {
				address,
				benchmark: '4',
				format: 'json',
				vintage: '4',
				key: 'ef2bb344d69bde8ef8be4fd0c6a0adbf10e4cb9c',
			},
		}).then((response) => {
			this.setState({
				addressSubmitted: false,
			});

			if (response.result.addressMatches.length > 0) {
				const censusTractNumber = response.result.addressMatches[0].geographies['Census Tracts'][0].BASENAME;
				const county = response.result.addressMatches[0].geographies.Counties[0].NAME;
				if (censusTractNumber) {
					const update = {
						censusTractNumber,
					};

					const countyOption = counties.find(m => m.description === county);
					if (countyOption) {
						update.userCountyId = countyOption.id;
					}

					cogoToast.success('Census Data Matched', { position: 'bottom-right' });
					this.setState(update);
				}
			} else {
				cogoToast.error('No Census Data Match Found', { position: 'bottom-right' });
			}
		});
	}

	handleAddressChange(address) {
		this.setState({ address });
	}

	handleFileRemove(id, property) {
		this.setState((prevState) => {
			const documents = prevState[property].slice(0).filter(d => d.id !== id);
			const patch = {};

			patch[property] = documents;
			return patch;
		});
	}

	handleFileDelete(id, property) {
		this.setState((prevState) => {
			const index = prevState[property].map(d => d.id).indexOf(id);
			const documents = prevState[property].slice(0);
			const patch = {};

			documents[index].deleted = true;
			const count = documents.filter(d => !d.deleted).length;

			if (count === 0) {
				documents.push({ id: uuid.default() });
			}

			patch[property] = documents;

			return patch;
		});
	}

	handleFileChange(file, property) {
		this.setState((prevState) => {
			const documents = prevState[property].slice(0);
			const index = documents.map(d => d.id).indexOf(file.id);
			const patch = {};

			documents[index] = file;
			patch[property] = documents;

			return patch;
		});
	}

	handleAddDocument(property) {
		this.setState((prevState) => {
			const documents = prevState[property];
			const patch = {};

			patch[property] = [
				...documents,
				{
					id: uuid.default(),
				},
			];

			return patch;
		});
	}

	handleManualFieldChange(event) {
		const {
			name,
			value,
			options,
			selectedIndex,
		} = event.target;

		const manualEntry = options[selectedIndex].dataset.manual;
		this.setState({
			[name]: value,
			[`${name.substring(0, name.length - 2)}ManualEntry`]: manualEntry,
		});
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				type,
				checked,
				value,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	validate(event) {
		event.preventDefault();

		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id, username, password, legalFirstName,
				legalLastName, legalMiddleInitial, name, dateOfBirth,
				ryanWhiteNumber, householdPoverty, userRaceId, userGenderId,
				userDisabilityId, userPreferredContactMethodId, censusTractNumber,
				address, phoneNumber, alternatePhoneNumber, testDate,
				viralLoad, testResultComments, range, povertyDocumentation, userCountyId, userBirthGenderId,
				otherGender, otherBirthGender, transgender, mentalIllness, userPartnersId,
				prep, condomUsage, userSexualOrientationId, otherSexualOrientation, archived,
				userReferredById, householdPovertyPercentage, email, mentalDocumentation,
				referredByOther, testResultDocumentation, proofOfResidencyDocumentation,
				userTouchFrequencyId, touchbasesGoal, checkInsGoal, surveysGoal, enrollmentDate,
				userArchivedReasonId,
			} = this.state;

			const user = {
				id,
				username,
				password,
				legalFirstName: legalFirstName || null,
				legalLastName: legalLastName || null,
				legalMiddleInitial: legalMiddleInitial || null,
				searchName: `${legalFirstName.toLowerCase()} ${legalLastName.toLowerCase()} ${name.toLowerCase()}`,
				name: name || null,
				dateOfBirth: dateOfBirth || null,
				ryanWhiteNumber: ryanWhiteNumber || null,
				householdPoverty: householdPoverty || null,
				householdPovertyPercentage: householdPovertyPercentage || null,
				userRaceId: userRaceId || null,
				userGenderId: userGenderId || null,
				userDisabilityId: userDisabilityId || null,
				userPreferredContactMethodId: userPreferredContactMethodId || null,
				censusTractNumber: censusTractNumber || null,
				address: address || null,
				phoneNumber: phoneNumber || null,
				alternatePhoneNumber: alternatePhoneNumber || null,
				userCountyId: userCountyId || null,
				archived,
				userArchivedReasonId,
				userBirthGenderId: userBirthGenderId || null,
				otherGender: otherGender || null,
				otherBirthGender: otherBirthGender || null,
				transgender: transgender === null ? null : transgender,
				mentalIllness: mentalIllness === null ? null : mentalIllness,
				userPartnersId: userPartnersId || null,
				prep: prep || null,
				condomUsage: condomUsage || null,
				userSexualOrientationId: userSexualOrientationId || null,
				otherSexualOrientation: otherSexualOrientation || null,
				userReferredById: userReferredById || null,
				referredByOther: referredByOther || null,
				email: email || null,
				userTouchFrequencyId: userTouchFrequencyId || null,
				povertyDocumentation,
				proofOfResidencyDocumentation,
				mentalDocumentation,
				enrollmentDate,
				touchbasesGoal,
				checkInsGoal,
				surveysGoal,
				totalCheckIns: 0,
				totalTouchbases: 0,
				totalSurveys: 0,
				testResultDocumentation,
				testResult: {
					testDate,
					viralLoad,
					comments: testResultComments,
					range,
				},
			};

			this.setState({
				submitted: true,
			});

			// Detect if cognito attribute fields changed.
			// If so, need to make additional call to REST API on save.
			const {
				phoneNumber: startPhoneNumber,
				email: startEmail,
				archived: startArchived,
			} = this.startState;
			const updateAttributes = (startPhoneNumber !== phoneNumber || startEmail !== email);
			const archivedUpdated = (startArchived !== archived);

			if (archivedUpdated) {
				if (archived) {
					const msg = 'Are you sure you want to archive this participant? They will no longer be able to use the mobile app but can be reactivated at a later time.';
					if (!window.confirm(msg)) { // eslint-disable-line no-alert
						this.setState({ submitted: false });
						return;
					}
				} else {
					const msg = 'Are you sure you want to activate this participant?';
					if (!window.confirm(msg)) { // eslint-disable-line no-alert
						this.setState({ submitted: false });
						return;
					}
				}
			}

			this.props.submit(user, updateAttributes, startEmail, archivedUpdated)
				.then(() => {
					this.setState({ submitted: false });
				});
		}
	}

	render() {
		const NEW_USER_METADATA = gql(userQueries.metadata);

		const newPovertyDocuments = this.state.povertyDocumentation.filter(d => !d.file);
		const currentPovertyDocuments = this.state.povertyDocumentation.filter(d => d.file);
		const newResidencyDocuments = this.state.proofOfResidencyDocumentation.filter(d => !d.file);
		const currentResidencyDocuments = this.state.proofOfResidencyDocumentation.filter(d => d.file);
		const newMentalDocuments = this.state.mentalDocumentation.filter(d => !d.file);
		const currentMentalDocuments = this.state.mentalDocumentation.filter(d => d.file);

		const ranges = [
			'Greater than 20 copies/mL',
			'Less than 20 copies/mL',
			'Undetectable',
		];

		return (
			<Query query={NEW_USER_METADATA} fetchPolicy="no-cache">
				{({ loading, error, data }) => {
					if (error) return (<h3 data-testid="error">{JSON.stringify(error)}</h3>);
					if (loading) return (<Loading />);

					const counties = data.counties.items;

					return (
						<form data-testid="user-form" ref={this.form} onSubmit={this.validate}>
							<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
							{this.props.user && (
								<div>
									{!this.state.id && (
										<div className="card mb-3">
											<div className="card-body">
												<h5 className="card-title">Login Credentials</h5>
												<Fragment>
													<div className="row">
														<div className="form-group col-md-4">
															<label htmlFor="username">Email Address (Username)</label>
															<input name="username" id="username" type="email" autoComplete="new-username" value={this.state.username} className="form-control" placeholder="Enter Email Address" onChange={this.handleInputChange} maxLength="320" required />
														</div>
														<div className="form-group col-md-4">
															<label htmlFor="password">Temporary Password</label>
															<input name="password" id="password" type="text" autoComplete="new-password" value={this.state.password} className="form-control" placeholder="Enter temporary password" disabled="disabled" onChange={this.handleInputChange} maxLength="50" required />
														</div>
													</div>
												</Fragment>
											</div>
										</div>
									)}
									<div className="card mb-3">
										<div className="card-body">
											<h5 className="card-title">Personal Information</h5>
											<div className="row">
												<div className="form-group col-md-3">
													<label htmlFor="legalFirstName">Legal First Name</label>
													<input required name="legalFirstName" id="legalFirstName" maxLength="60" type="text" value={this.state.legalFirstName} className="form-control" placeholder="Enter Legal First Name" onChange={this.handleInputChange} />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="legalLastName">Legal Last Name</label>
													<input required name="legalLastName" id="legalLastName" maxLength="60" type="text" value={this.state.legalLastName} className="form-control" placeholder="Enter Legal Last Name" onChange={this.handleInputChange} />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="legalMiddleInitial">Legal Middle Initial</label>
													<input name="legalMiddleInitial" id="legalMiddleInitial" maxLength="1" type="text" value={this.state.legalMiddleInitial} className="form-control" placeholder="Enter Legal M.I." onChange={this.handleInputChange} />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="enrollmentDate">Enrollment Date</label>
													<input required name="enrollmentDate" id="enrollmentDate" type="date" value={this.state.enrollmentDate} className="form-control" placeholder="MM/DD/YYYY" onChange={this.handleInputChange} min="1919-01-01" />
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-4">
													<label htmlFor="name">Name</label>
													<input name="name" id="name" type="text" maxLength="130" value={this.state.name} className="form-control" placeholder="Enter Name" onChange={this.handleInputChange} />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="dateOfBirth">Date of Birth</label>
													<input required name="dateOfBirth" id="dateOfBirth" type="date" value={this.state.dateOfBirth} className="form-control" placeholder="MM/DD/YYYY" onChange={this.handleInputChange} max="2018-01-01" min="1919-01-01" />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="ryanWhiteNumber">Ryan White Number</label>
													<input required name="ryanWhiteNumber" maxLength="11" id="ryanWhiteNumber" type="text" value={this.state.ryanWhiteNumber} className="form-control" placeholder="Enter Ryan White Number" onChange={this.handleInputChange} pattern="[A-Za-z]{4}[0-9]{6}[1-2]" />
												</div>
											</div>
										</div>
									</div>
									<div className="card mb-3">
										<div className="card-body">
											<h5 className="card-title">Demographics</h5>
											<div className="row">
												<div className="form-group col-md-3">
													<label htmlFor="gender">Sex Assigned at Birth</label>
													<select required name="userBirthGenderId" id="gender" type="text" value={this.state.userBirthGenderId} className="form-control" placeholder="Select Gender" onChange={this.handleManualFieldChange}>
														<option value="">Select an option</option>
														{data.genders.items.map(option => (
															<option
																data-manual={option.manualEntry}
																key={option.id}
																value={option.id}
															>
																{option.description}
															</option>
														))}
													</select>
												</div>
												{this.state.userBirthGenderManualEntry && (
													<div className="form-group col-md-3">
														<label htmlFor="otherBirthGender">Sex Assigned at Birth (Manual Entry)</label>
														<input required name="otherBirthGender" id="otherBirthGender" maxLength="60" type="text" value={this.state.otherBirthGender} className="form-control" placeholder="Enter Birth Gender" onChange={this.handleInputChange} />
													</div>
												)}
												<div className="form-group col-md-3">
													<label htmlFor="sexualOrientation">Sexual Orientation</label>
													<select required name="userSexualOrientationId" id="sexualOrientation" type="text" value={this.state.userSexualOrientationId} className="form-control" placeholder="Select Sexual Orientation" onChange={this.handleManualFieldChange}>
														<option value="">Select an option</option>
														{data.sexualOrientations.items.map(option => (
															<option
																data-manual={option.manualEntry}
																key={option.id}
																value={option.id}
															>
																{option.description}
															</option>
														))}
													</select>
												</div>
												{this.state.userSexualOrientationManualEntry && (
													<div className="form-group col-md-3">
														<label htmlFor="otherSexualOrientation">Sexual Orientation (Manual Entry)</label>
														<input required
															name="otherSexualOrientation"
															id="otherSexualOrientation"
															maxLength="60"
															type="text"
															value={this.state.otherSexualOrientation}
															className="form-control"
															placeholder="Enter Sexual Orientation"
															onChange={this.handleInputChange}
														/>
													</div>
												)}
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="row">
														<div className="form-group col-md-6">
															<label htmlFor="gender">Gender</label>
															<select required name="userGenderId" id="gender" type="text" value={this.state.userGenderId} className="form-control" placeholder="Select Gender" onChange={this.handleManualFieldChange}>
																<option value="">Select an option</option>
																{data.genders.items.map(option => (
																	<option
																		data-manual={option.manualEntry}
																		key={option.id}
																		value={option.id}
																	>
																		{option.description}
																	</option>
																))}
															</select>
														</div>
														{this.state.userGenderManualEntry && (
															<div className="form-group col-md-6">
																<label htmlFor="otherGender">Gender (Manual Entry)</label>
																<input required name="otherGender" id="otherGender" maxLength="60" type="text" value={this.state.otherGender} className="form-control" placeholder="Enter Gender" onChange={this.handleInputChange} />
															</div>
														)}
													</div>
													<div className="row">
														<div className="form-group col-md-6">
															<label htmlFor="partner">Sexual/Partner History</label>
															<select name="userPartnersId" id="partner" value={this.state.userPartnersId} className="form-control" onChange={this.handleInputChange}>
																<option value="">Select an option</option>
																{data.partnerTypes.items.map(option => (
																	<option key={option.id} value={option.id}>
																		{option.description}
																	</option>
																))}
															</select>
														</div>
														<div className="form-group col-md-6">
															<label htmlFor="prep">Identifies as Transgender?</label>
															<div id="transgender">
																<div className="custom-control custom-radio">
																	<input required type="radio" id="transgender-yes" name="transgender" checked={this.state.transgender === true} value="true" onChange={() => this.setState({ transgender: true })} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="transgender-yes">Yes</label>
																</div>
																<div className="custom-control custom-radio">
																	<input required type="radio" id="transgender-no" name="transgender" checked={this.state.transgender === false} value="false" onChange={() => this.setState({ transgender: false })} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="transgender-no">No</label>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="row">
														<div className="form-group col-md-6">
															<label htmlFor="prep">Current or Regular Partners on PrEP</label>
															<div id="prep">
																<div className="custom-control custom-radio">
																	<input type="radio" id="prep-yes" name="prep" checked={this.state.prep === 'yes'} value="yes" onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="prep-yes">Yes</label>
																</div>
																<div className="custom-control custom-radio">
																	<input type="radio" id="prep-no" name="prep" checked={this.state.prep === 'no'} value="no" onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="prep-no">No</label>
																</div>
																<div className="custom-control custom-radio">
																	<input type="radio" id="prep-unknown" name="prep" checked={this.state.prep === 'unknown'} value="unknown" onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="prep-unknown">Don&apos;t Know</label>
																</div>
																<div className="custom-control custom-radio">
																	<input type="radio" id="prep-na" name="prep" checked={this.state.prep === 'na'} value="na" onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="prep-na">n/a</label>
																</div>
															</div>
														</div>
														<div className="form-group col-md-6">
															<label htmlFor="condom">Condom Usage</label>
															<div id="condom">
																<div className="custom-control custom-radio">
																	<input type="radio" id="condom-always" name="condomUsage" value="always" checked={this.state.condomUsage === 'always'} onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="condom-always">Always</label>
																</div>
																<div className="custom-control custom-radio">
																	<input type="radio" id="condom-sometimes" name="condomUsage" value="sometimes" checked={this.state.condomUsage === 'sometimes'} onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="condom-sometimes">Sometimes</label>
																</div>
																<div className="custom-control custom-radio">
																	<input type="radio" id="condom-never" name="condomUsage" value="never" checked={this.state.condomUsage === 'never'} onChange={this.handleInputChange} className="custom-control-input" />
																	<label className="custom-control-label" htmlFor="condom-never">Never</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="card mb-3">
										<div className="card-body">
											<h5 className="card-title">Other Demographics</h5>
											<div className="row">
												<div className="form-group col-md-3">
													<label htmlFor="race">Race/Ethnicity</label>
													<select required name="userRaceId" id="race" value={this.state.userRaceId} className="form-control" onChange={this.handleInputChange}>
														<option value="">Select an option</option>
														{data.races.items.map(option => (
															<option key={option.id} value={option.id}>
																{option.description}
															</option>
														))}
													</select>
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="disability">Disability</label>
													<select required name="userDisabilityId" id="disability" value={this.state.userDisabilityId} className="form-control" onChange={this.handleInputChange}>
														<option value="">Select an option</option>
														{data.disabilities.items.map(option => (
															<option key={option.id} value={option.id}>
																{option.description}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-2">
													<label htmlFor="povertyPercentage">Household Federal Poverty Level</label>
													<div className="input-group">
														<input required name="householdPovertyPercentage" id="povertyPercentage" value={this.state.householdPovertyPercentage} onChange={this.handleInputChange} type="number" className="form-control" placeholder="Enter Percentage" />
														<div className="input-group-append">
															<span className="input-group-text">
																%
															</span>
														</div>
													</div>
												</div>
												<div className="col-md-4">
													<label htmlFor="povertyPercentage">Household Federal Poverty Level Documentation</label>
													{currentPovertyDocuments.filter(d => !d.deleted).length > 0 && (
														<table className="table table-sm">
															<tbody>
																{currentPovertyDocuments.filter(d => !d.deleted).map(document => (
																	<tr key={document.id}>
																		<td>
																			<span className="align-middle">{document.name}</span>
																		</td>
																		<td>
																			<button type="button" className="btn btn-sm btn-outline-primary mr-1" onClick={() => this.getDocument(document.file.key)}>
																				<SaveAlt className="align-middle" />
																			</button>
																			<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => this.handleFileDelete(document.id, 'povertyDocumentation')}>
																				<Delete />
																			</button>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													)}
													{newPovertyDocuments.map((document, index) => (
														<div key={document.id}>
															<FileInput document={document} showRemove={this.state.id || (index > 0)} onRemove={() => this.handleFileRemove(document.id, 'povertyDocumentation')} onFileChange={file => this.handleFileChange(file, 'povertyDocumentation')} label="" />
														</div>
													))}
													<div>
														<button type="button" onClick={() => this.handleAddDocument('povertyDocumentation')} className="btn btn-outline-secondary btn-sm">
															<Add /> HPL Documentation
														</button>
													</div>
												</div>
												<div className="col-md-2">
													<label htmlFor="mentalIllness">Mental health/substance use diagnosis? </label>
													<div id="mentalIllness">
														<div className="custom-control custom-radio">
															<input required type="radio" id="mentalIllness-yes" name="mentalIllness" checked={this.state.mentalIllness === true} value="true" onChange={() => this.setState({ mentalIllness: true })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="mentalIllness-yes">Yes</label>
														</div>
														<div className="custom-control custom-radio">
															<input required type="radio" id="mentalIllness-no" name="mentalIllness" checked={this.state.mentalIllness === false} value="false" onChange={() => this.setState({ mentalIllness: false })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="mentalIllness-no">No</label>
														</div>
													</div>
												</div>
												<div className="col-md-4">
													<label htmlFor="povertyPercentage">Mental Illness Documentation</label>
													{currentMentalDocuments.filter(d => !d.deleted).length > 0 && (
														<table className="table table-sm">
															<tbody>
																{currentMentalDocuments.filter(d => !d.deleted).map(document => (
																	<tr key={document.id}>
																		<td>
																			<span className="align-middle">{document.name}</span>
																		</td>
																		<td>
																			<button type="button" className="btn btn-sm btn-outline-primary mr-1" onClick={() => this.getDocument(document.file.key)}>
																				<SaveAlt className="align-middle" />
																			</button>
																			<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => this.handleFileDelete(document.id, 'mentalDocumentation')}>
																				<Delete />
																			</button>
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													)}
													{newMentalDocuments.map((document, index) => (
														<div key={document.id}>
															<FileInput document={document} onRemove={() => this.handleFileRemove(document.id, 'mentalDocumentation')} showRemove={this.state.id || (index > 0)} onFileChange={file => this.handleFileChange(file, 'mentalDocumentation')} label="" />
														</div>
													))}
													<div>
														<button type="button" onClick={() => this.handleAddDocument('mentalDocumentation')} className="btn btn-outline-secondary btn-sm">
															<Add /> Mental Illness Documentation
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="card mb-3">
										<div className="card-body">
											<h5 className="card-title">Contact Information</h5>
											<div className="row">
												<div className="form-group col-md-6">
													<label htmlFor="address">Address</label>
													<PlacesAutocomplete
														value={this.state.address}
														onSelect={address => this.setState({ address })}
														onChange={this.handleAddressChange}
													>
														{({
															getInputProps, suggestions, getSuggestionItemProps, placesLoading,
														}) => (
															<Fragment>
																<div className="input-group">
																	<input required id="address" {...getInputProps({ placeholder: 'Search for an address ...', className: 'form-control' })} />
																	<div className="input-group-append">
																		<button disabled={this.state.address.length === 0} type="button" className="btn btn-outline-secondary" onClick={() => this.getCensusTract(counties)}>
																			{this.state.addressSubmitted && (
																				<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
																			)}
																			{this.state.addressSubmitted ? '' : 'Fetch'}
																		</button>
																	</div>
																</div>
																<div className="autocomplete-dropdown-container">
																	{placesLoading && <div>Loading...</div>}
																	{suggestions.map(suggestion => (
																		<div key={suggestion.id}
																			{...getSuggestionItemProps(suggestion)}
																		>
																			<span>{suggestion.description}</span>
																		</div>
																	))}
																</div>
															</Fragment>
														)}
													</PlacesAutocomplete>
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="censusTractNumber">Census Tract Number</label>
													<input required name="censusTractNumber" id="censusTractNumber" type="text" value={this.state.censusTractNumber} className="form-control" placeholder="Enter Census Tract Number" onChange={this.handleInputChange} />
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="county">County</label>
													<select required name="userCountyId" id="county" value={this.state.userCountyId} className="form-control" placeholder="Select County" onChange={this.handleInputChange}>
														<option value="">Select an option</option>
														{counties.map(option => (
															<option key={option.id} value={option.id}>
																{option.description}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="row">
												<div className="form-group col-md-12">
													<table className="table table-sm">
														<tbody>
															{currentResidencyDocuments.filter(d => !d.deleted).map(document => (
																<tr key={document.id}>
																	<td>
																		<span className="align-middle">{document.name}</span>
																	</td>
																	<td>
																		<button type="button" className="btn btn-sm btn-outline-primary mr-1" onClick={() => this.getDocument(document.file.key)}>
																			<SaveAlt className="align-middle" />
																		</button>
																		<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => this.handleFileDelete(document.id, 'proofOfResidencyDocumentation')}>
																			<Delete />
																		</button>
																	</td>
																</tr>
															))}
														</tbody>
													</table>
													{newResidencyDocuments.map((document, index) => (
														<div key={document.id}>
															<FileInput document={document} onRemove={() => this.handleFileRemove(document.id, 'proofOfResidencyDocumentation')} showRemove={index > 0} onFileChange={file => this.handleFileChange(file, 'proofOfResidencyDocumentation')} label="Proof of Residency" />
														</div>
													))}
													<button type="button" onClick={() => this.handleAddDocument('proofOfResidencyDocumentation')} className="btn btn-outline-secondary btn-sm">
														<Add /> Proof of Residency
													</button>
												</div>
											</div>
											<div className="row">
												{this.state.id && (
													<div className="form-group col-md-3">
														<label htmlFor="email">Email</label>
														<input name="email" id="email" maxLength="255" type="email" value={this.state.email} onChange={this.handleInputChange} className="form-control" placeholder="Enter Email Address" />
													</div>
												)}
												<div className="form-group col-md-3">
													<label htmlFor="phone">Phone Number</label>
													<input required pattern="\d{3}[\-]\d{3}[\-]\d{4}" name="phoneNumber" id="phone" type="tel" value={this.state.phoneNumber} className="form-control" placeholder="Phone Number" onChange={this.handleInputChange} />
													<small className="form-text text-muted">
														xxx-xxx-xxxx
													</small>
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="alternatePhoneNumber">Alternate Phone Number</label>
													<input pattern="\d{3}[\-]\d{3}[\-]\d{4}" name="alternatePhoneNumber" id="alternatePhoneNumber" type="tel" value={this.state.alternatePhoneNumber} className="form-control" placeholder="Alternate Phone Number" onChange={this.handleInputChange} />
													<small className="form-text text-muted">
														xxx-xxx-xxxx
													</small>
												</div>
											</div>

											<div className="row">
												<div className="form-group col-md-3">
													<label htmlFor="archived">Status</label>
													<div id="archived">
														<div className="custom-control custom-radio">
															<input type="radio" id="archived-no" checked={!this.state.archived} name="archived" onChange={() => this.setState({ archived: false })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="archived-no">Active</label>
														</div>
														<div className="custom-control custom-radio">
															<input type="radio" id="archived-yes" checked={this.state.archived} name="archived" onChange={() => this.setState({ archived: true })} className="custom-control-input" />
															<label className="custom-control-label" htmlFor="archived-yes">Archived</label>
														</div>
													</div>
												</div>
												{this.state.archived && (
													<div className="form-group col-md-3">
														<label htmlFor="userArchivedReasonId">Archived Reason</label>
														<select required name="userArchivedReasonId" id="userArchivedReasonId" value={this.state.userArchivedReasonId} onChange={this.handleInputChange} className="form-control">
															<option value="">Select an option</option>
															{data.archivedReasons.items.map(option => (
																<option key={option.id} value={option.id}>
																	{option.description}
																</option>
															))}
														</select>
													</div>
												)}
											</div>
											<div className="row">
												<div className="form-group col-md-3">
													<label htmlFor="contactMethod">Preferred Contact Method</label>
													<select name="userPreferredContactMethodId" id="contactMethod" type="text" value={this.state.userPreferredContactMethodId} className="form-control" onChange={this.handleInputChange} required>
														<option value="">Select an option</option>
														{data.contactMethods.items.map(option => (
															<option key={option.id} value={option.id}>
																{option.description}
															</option>
														))}
													</select>
												</div>
												<div className="form-group col-md-3">
													<label htmlFor="referredBy">Referred By</label>
													<select required name="userReferredById" id="referredBy" value={this.state.userReferredById} onChange={this.handleManualFieldChange} className="form-control">
														<option value="">Select an option</option>
														{data.referralTypes.items.map(option => (
															<option
																data-manual={option.manualEntry}
																key={option.id}
																value={option.id}
															>
																{option.description}
															</option>
														))}
													</select>
												</div>
												{this.state.userReferredByManualEntry && (
													<div className="form-group col-md-3">
														<label htmlFor="referredByOther">Referred By (Manual Entry)</label>
														<input required name="referredByOther" id="referredByOther" type="text" maxLength="60" value={this.state.referredByOther} className="form-control" placeholder="Referred By" onChange={this.handleInputChange} />
													</div>
												)}
												{!this.state.id && (
													<div className="form-group col-md-3">
														<label htmlFor="touchFrequency">Touchbase Frequency</label>
														<select required name="userTouchFrequencyId" id="touchFrequency" value={this.state.userTouchFrequencyId} onChange={this.handleInputChange} className="form-control">
															<option value="">Select an option</option>
															{data.touchFrequencies.items.map(option => (
																<option key={option.id} value={option.id}>
																	{option.description}
																</option>
															))}
														</select>
													</div>
												)}
											</div>
										</div>
									</div>

									{!this.state.id && (
										<Fragment>
											<div className="card mb-3">
												<div className="card-body">
													<h5 className="card-title">Viral Load</h5>
													<div className="row">
														<div className="form-group col-md-3">
															<label htmlFor="testDate">Viral Load Test Date</label>
															<input required name="testDate" id="testDate" type="date" value={this.state.testDate} className="form-control" placeholder="Enter test date" onChange={this.handleInputChange} />
														</div>
														<div className="form-group col-md-4">
															<label htmlFor="range">Range</label>
															<div className="input-group">
																<select required name="range" id="range" value={this.state.range} className="form-control" onChange={this.handleInputChange}>
																	<option value="">Select an option</option>
																	{ranges.map(option => (
																		<option key={option} value={option}>
																			{option}
																		</option>
																	))}
																</select>
																<div className="input-group-append">
																	<button type="button" onClick={() => this.handleAddDocument('testResultDocumentation')} className="btn btn-outline-secondary btn-sm">
																		<Add /> Documentation
																	</button>
																</div>
															</div>
														</div>
														{this.state.range === 'Greater than 20 copies/mL' && (
															<div className="form-group col-md-3">
																<label htmlFor="viralLoad">Viral Load</label>
																<div className="input-group">
																	<input required name="viralLoad" id="viralLoad" type="number" value={this.state.viralLoad} className="form-control" placeholder="Enter viral load" onChange={this.handleInputChange} />
																</div>
															</div>
														)}
													</div>
													{this.state.testResultDocumentation.map((document, index) => (
														<div key={document.id}>
															<FileInput document={document} onRemove={() => this.handleFileRemove(document.id, 'testResultDocumentation')} showRemove={index > 0} onFileChange={file => this.handleFileChange(file, 'testResultDocumentation')} label="Test Result Documentation" />
														</div>
													))}
													<div className="row">
														<div className="form-group col-md-8">
															<label htmlFor="comments">Notes/Comments</label>
															<textarea className="form-control" id="testResultComments" name="testResultComments" rows="3" value={this.state.testResultComments} onChange={this.handleInputChange} />
														</div>
													</div>
												</div>
											</div>
										</Fragment>
									)}
								</div>
							)}
							<button className="btn btn-primary" disabled={this.state.submitted} type="submit" onClick={this.validate}>
								{this.state.submitted && (
									<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
								)}
								{this.state.submitted ? 'Loading...' : 'Submit'}
							</button>
						</form>
					);
				}}
			</Query>
		);
	}
}

export default compose(
	withRouter,
	withApollo,
)(UserForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import MetadataForm from './forms/MetadataForm';
import Layout from './layout/Layout';
import metadataTypes from '../constants/metadataTypes';
import Loading from './Loading';

class EditMetadata extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
	};

	render() {
		const { match } = this.props;
		const GET_METADATA = gql(queries.getMetadata);
		const UPDATE_METADATA = gql(mutations.updateMetadata);

		return (
			<Query query={GET_METADATA} fetchPolicy="no-cache" variables={{ id: match.params.id }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					const title = metadataTypes[data.getMetadata.type].singular;
					return (
						<Layout title={`Edit ${title}`}>
							<Mutation mutation={UPDATE_METADATA}>
								{updateMetadata => (
									<MetadataForm submit={updateMetadata} metadata={data.getMetadata} />
								)}
							</Mutation>
						</Layout>
					);
				}}
			</Query>
		);
	}
}

export default EditMetadata;

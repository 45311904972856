import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangeCalendar from 'rc-calendar/lib/RangeCalendar';
import 'rc-calendar/assets/index.css';
import moment from 'moment';
import { withApollo, Query } from 'react-apollo';
import gql from 'graphql-tag';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';
import { SaveAlt } from '@material-ui/icons';

import { listMedicationInstances, getMedication } from '../graphql-custom/queries';

const columns = [
	{
		dataField: 'createdAt',
		text: 'Date Taken',
		formatter: (cell, row) => moment(row.createdAt).format('MM/DD/YYYY'),
	},
	{
		dataField: 'createdAt',
		text: 'Time Taken',
		formatter: (cell, row) => moment(row.createdAt).format('hh:mm A'),
	},
];

const headers = [
	{ label: 'Medication', key: 'medication' },
	{ label: 'Date Taken', key: 'dateTaken' },
	{ label: 'Time Taken', key: 'timeTaken' },
];

const defaultRange = [
	moment().add(-1, 'months'),
	moment(),
];

class ListMedicationInstances extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			instances: [],
		};

		this.handleRangeSelect = this.handleRangeSelect.bind(this);
	}

	componentDidMount() {
		this.handleRangeSelect(defaultRange);
	}

	handleRangeSelect = (dates) => {
		const start = dates[0].startOf('day').toISOString();
		const end = dates[1].endOf('day').toISOString();

		this.props.client.query({
			query: gql(listMedicationInstances),
			variables: {
				medicationId: this.props.match.params.medicationId,
				start,
				end,
			},
			fetchPolicy: 'no-cache',
		}).then(({ data: { instances } }) => {
			this.setState({
				instances: instances.items,
			});
		});
	}

	render() {
		const { instances } = this.state;
		const GET_MEDICATION = gql(getMedication);

		return (
			<Query fetchPolicy="no-cache" query={GET_MEDICATION} variables={{ id: this.props.match.params.medicationId }}>
				{({ loading, error, data: { medication } }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading || !instances) { return (null) }

					const instanceDates = instances.map(instance => moment(instance.createdAt).startOf('day').toDate().getTime());
					const csvData = instances.sort((a, b) => (a.createdAt > b.createdAt ? a : b)).map(i => ({
						medication: medication.name,
						dateTaken: moment(i.createdAt).format('MM/DD/YYYY'),
						timeTaken: moment(i.createdAt).format('hh:mm A'),
					}));

					return (
						<div className="card mb-3">
							<div className="card-body">
								<div className="d-flex flex-row">
									<div>
										<div className="mb-4">
											<h3>{medication.name}</h3>
											{medication.dosage && (
												<h5>{medication.dosage}</h5>
											)}
										</div>
										<RangeCalendar
											onSelect={this.handleRangeSelect}
											dateRender={(date) => {
												const cls = 'rc-calendar-date';
												const num = date.date();
												const ms = date.startOf('day').toDate().getTime();

												return (
													<div
														style={instanceDates.indexOf(ms) >= 0 ? { fontSize: '15px', color: 'green', fontWeight: 'bold' } : {}}
														key={`rc-calendar-${date.year()}-${date.month()}-${date.date()}`}
														className={cls}
													>
														{num}
													</div>
												);
											}}
											defaultSelectedValue={defaultRange}
											showToday={false}
										/>
									</div>
									<div className="px-4 flex-fill">
										<div className="btn-group mb-3" role="group" aria-label="Basic example">
											<CSVLink headers={headers} data={csvData} filename={`${medication.name}.csv`} className="btn btn-info" target="_blank">
												<SaveAlt />
											</CSVLink>
										</div>
										<BootstrapTable
											keyField="id"
											bordered={false}
											data={instances}
											noDataIndication="There are no medication instances to display."
											columns={columns}
											pagination={paginationFactory({ hidePageListOnlyOnePage: true })}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

export default withApollo(ListMedicationInstances);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Prompt } from 'react-router';
import { compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import * as mutations from '../../graphql/mutations';

import Errors from './Errors';
import FileInput from './FileInput';
import { metadataValues } from '../../constants/metadataTypes';

class DocumentForm extends Component {
	static propTypes = {
		history: PropTypes.shape().isRequired,
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
		document: PropTypes.shape().isRequired,
		submit: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		const {
			id,
			name,
			comments,
		} = this.props.document;

		this.state = {
			id: id || null,
			name: name || '',
			documentTypeId: metadataValues.otherDocumentType,
			comments: comments || '',
			file: null,
			submitted: false,
			errors: [],
		};

		this.startState = this.state;
		this.form = React.createRef();
		this.handleDelete = this.handleDelete.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFileChange = this.handleFileChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleDelete() {
		this.props.client.mutate({
			mutation: gql(mutations.deleteDocument),
			variables: {
				input: {
					id: this.state.id,
				},
			},
		}).then(() => {
			this.props.history.push(`/users/${this.props.match.params.id}/documents`);
		});
	}

	handleInputChange(event) {
		const {
			target: {
				name,
				value,
				checked,
				type,
			},
		} = event;

		let val;

		switch (type) {
		case 'checkbox':
			val = checked;
			break;
		default:
			val = value;
			break;
		}

		this.setState({
			[name]: val,
		});
	}

	handleFileChange(f) {
		const { id, ...file } = f;

		this.setState({
			file,
		});
	}

	validate(e) {
		e.preventDefault();

		const { match } = this.props;
		const userId = match.params.id;

		const valid = this.form.current.reportValidity();

		if (valid) {
			const {
				id,
				name,
				file,
				documentTypeId,
				comments,
			} = this.state;

			const document = {
				id,
				documentUserId: userId,
				documentDocumentTypeId: documentTypeId,
				name,
				file,
				comments,
			};

			this.setState({
				submitted: true,
			});

			this.props.submit({
				variables: {
					input: document,
				},
			}).then(() => {
				this.props.history.push(`/users/${userId}/documents`);
			}).catch((errRes) => {
				// Show GQL errors
				this.setState({
					submitted: false,
					errors: errRes.graphQLErrors.map(err => err.message),
				});

				document.getElementsByTagName('body')[0].scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			});
		}
	}

	render() {
		return (
			<form className="my-4 mx-2" ref={this.form} onSubmit={this.validate}>
				<Errors errors={this.state.errors} />
				<Prompt when={!this.state.submitted && JSON.stringify(this.startState) !== JSON.stringify(this.state)} message="You have unsaved changes, are you sure you want to leave?" />
				{this.props.document && (
					<Fragment>
						<div className="card mb-3">
							<div className="card-body">
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="name">Document Name</label>
										<input name="name" id="name" type="text" value={this.state.name} className="form-control" placeholder="Enter document name" onChange={this.handleInputChange} />
									</div>
								</div>
								{!this.state.id && (<FileInput uid="document" document={this.state} onFileChange={this.handleFileChange} field={this.state.file} />)}
								<div className="row">
									<div className="form-group col-md-8">
										<label htmlFor="comments">Notes/Comments</label>
										<textarea rows="3" name="comments" id="comments" maxLength="300" type="text" value={this.state.comments} className="form-control" placeholder="Enter notes/comments" onChange={this.handleInputChange} />
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				)}
				<div className="row">
					<div className="col-md-6">
						<button className="btn btn-primary" disabled={this.state.submitted} type="submit">
							{this.state.submitted && (
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
							)}
							{this.state.submitted ? 'Loading...' : 'Submit'}
						</button>
						<button type="button" className="btn btn-outline-primary ml-1" onClick={() => this.props.history.goBack()}>
							Cancel
						</button>
					</div>
					<div className="col-md-6 text-right">
						{this.state.id && (
							<button type="button" onClick={this.handleDelete} className="btn btn-danger ml-1">
								Delete
							</button>
						)}
					</div>
				</div>
			</form>
		);
	}
}

export default compose(
	withApollo,
	withRouter,
)(DocumentForm);

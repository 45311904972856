import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Errors extends Component {
	static propTypes = {
		errors: PropTypes.arrayOf(PropTypes.string).isRequired,
	};

	render() {
		return (
			<div>
				{this.props.errors.length > 0 && (
					<div className="card text-white bg-danger mb-3 my-4 mx-2">
						<div className="card-body">
							<h5 className="card-title">Error(s)</h5>
							<div className="card-text">
								<ul>
									{this.props.errors.map(err => (
										<li key={err}>{err}</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default Errors;

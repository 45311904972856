import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import {
	TrendingUp, SupervisorAccount, Settings, NearMeOutlined,
} from '@material-ui/icons';
// import Button from 'react-bootstrap/Button';
// import Collapse from 'react-bootstrap/Collapse';
import { Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';


// import metadataTypes from '../../constants/metadataTypes';

const Navigation = (props) => {
	// const isAdminPage = props.location.pathname.indexOf('/admin/') >= 0;
	// const [open, setOpen] = useState(isAdminPage);
	// const sortedTypes = Object.keys(metadataTypes).sort((a, b) => (a > b ? 1 : -1));
	const ADMIN_GROUP = 'administrators';

	return (
		<Container>
			<Navbar expand="lg">
				<Navbar.Toggle aria-controls="ican-navbar" />
				<Navbar.Collapse id="ican-navbar">
					<Nav className="mr-auto">
						<NavLink exact={true} to="/">
							<TrendingUp /> Dashboard
						</NavLink>
						<NavLink to="/users">
							<SupervisorAccount /> Participants
						</NavLink>
						<NavLink to="/resources">
							<NearMeOutlined /> Resources
						</NavLink>
						{props.group === ADMIN_GROUP && (
							<NavLink to="/admin">
								<Settings /> Admin
							</NavLink>
						)}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</Container>
	);
};

Navigation.propTypes = {
	// location: PropTypes.shape().isRequired,
	group: PropTypes.string.isRequired,
};

const Container = styled.div`
	.navbar {
		padding: 0px;
		height: 40px;

		.navbar-nav a {
			margin: 10px 15px;
			text-decoration: none;

			&:first-child {
				margin-left: 0px;
			}

			&.active {
				color: #007bff;
				font-weight: bold;
			}
		}
	}
`;


export default withRouter(Navigation);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { adjustTotalTouchbases } from '../graphql-custom/mutations';
import TouchForm from './forms/TouchForm';
import Loading from './Loading';

class EditTouch extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
	}

	submit = ({ touch, adjustment }) => {
		const { client } = this.props;

		return client.mutate({
			mutation: gql(mutations.updateTouch),
			variables: {
				input: touch,
			},
		}).then(() => {
			if (adjustment) {
				return client.mutate({
					mutation: gql(adjustTotalTouchbases),
					variables: {
						userId: touch.touchUserId,
						amount: adjustment,
					},
				});
			}
			return Promise.resolve();
		});
	};

	render() {
		const { match } = this.props;
		const GET_TOUCH = gql(queries.getTouch);

		return (
			<Query query={GET_TOUCH} fetchPolicy="no-cache" variables={{ id: match.params.touchId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<TouchForm submit={this.submit} touch={data.getTouch} />
					);
				}}
			</Query>
		);
	}
}

export default withApollo(EditTouch);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, withApollo } from 'react-apollo';

import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import IncentiveForm from './forms/IncentiveForm';
import Loading from './Loading';

class EditIncentive extends Component {
	static propTypes = {
		match: PropTypes.shape().isRequired,
		client: PropTypes.shape().isRequired,
	};

	constructor(props) {
		super(props);

		this.submit = this.submit.bind(this);
	}

	submit = async ({ incentive, newReceipt, deleteReceipt }) => {
		const { client } = this.props;
		let receiptId = incentive.incentiveDocumentId;

		// First delete the receipt if needed.
		if (deleteReceipt) {
			await client.mutate({
				mutation: gql(mutations.deleteDocument),
				variables: {
					input: {
						id: deleteReceipt.id,
					},
				},
			});
		}

		if (newReceipt) {
			const res = await client.mutate({
				mutation: gql(mutations.createDocument),
				variables: {
					input: newReceipt,
				},
			});

			receiptId = res.data.createDocument.id;
		}

		return client.mutate({
			mutation: gql(mutations.updateIncentive),
			variables: {
				input: {
					incentiveDocumentId: receiptId,
					...incentive,
				},
			},
		});
	};

	render() {
		const { match } = this.props;
		const GET_INCENTIVE = gql(queries.getIncentive);

		return (
			<Query query={GET_INCENTIVE} fetchPolicy="no-cache" variables={{ id: match.params.incentiveId }}>
				{({ loading, error, data }) => {
					if (error) return (<h3>Error!</h3>);
					if (loading) return (<Loading />);

					return (
						<IncentiveForm submit={this.submit} incentive={data.getIncentive} />
					);
				}}
			</Query>
		);
	}
}

export default withApollo(EditIncentive);
